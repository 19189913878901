import { Fragment, useState, useEffect, forwardRef } from 'react';
import axiosInstance from '../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBar from '../../../components/SnackBar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateProductManagement = (props) => {
    const { open, handleClose, reRender, setReRender, productData, franchiseData } = props;
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [loading, setLoading] = useState(false);
    const [franchise,setFranchise] = useState(null);
    const [franchises,setFranchises] = useState(null);
    const [selectedFranchises,setSelectedFranchises] = useState([]);
    const [product,setProduct] = useState([]);

    useEffect(() => {
        if (productData?.length !== 0) {
            axiosInstance
                .get(`/product/${productData?.[0]?.id}`)
                .then((res) => {
                    const data = {
                        listPriceError: false, listPriceErrorMessage: '',
                        retailerPriceError: false, retailerPriceErrorMessage: '',
                        specialRetailerPriceError: false, specialRetailerPriceErrorMessage: '',
                        interiorPriceError: false, interiorPriceErrorMessage: '',
                        endUserPriceError: false, endUserPriceErrorMessage: '',
                        oemPriceError: false, oemPriceErrorMessage: '',
                        partnerPriceError: false, partnerPriceErrorMessage: '',
                        promoterPriceError: false, promoterPriceErrorMessage: ''
                    }
                    if (franchiseData) {
                        setFranchise(franchiseData?.label)
                    }
                    setSelectedFranchises(res?.data?.data?.franchiseIds || []);
                    setProduct([{...res.data?.data, ...data}]);
                });
        }
    }, [productData,franchiseData]);

    useEffect(() => {
        const fetchFranchises = async () => {
            try {
                const response = await axiosInstance(`/pos/fetchAllFranchises?withId=${true}`);
                const franchises = await response?.data?.data?.map((franchise) => franchise?.label);
                setFranchises(franchises);
            } catch (error) {
                console.error('Error fetching franchises:', error);
            }
        };
    
        fetchFranchises();
    }, []);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        let data ={ }
      
        const gst = parseFloat(product?.[0]?.gst);
        const mrp = parseFloat(product?.[0]?.mrp);
        if (event.target.listingPrice.value) {
            const listingPrice = parseFloat(event.target.listingPrice.value);
            const discount = (mrp -((listingPrice / (1+(gst/100))) * (1+(gst /100))))/mrp;
            data["listingPrice"] = (listingPrice / (1+(gst/100)))?.toFixed(2);
            data["listingDiscount"] = (((discount * 100) * 100)/100)?.toFixed(2);
        }
      
        let retailDiscount;
        if (event.target.retailerPrice.value) {
            const retailerPrice = parseFloat(event.target.retailerPrice.value)
            retailDiscount = (mrp -((retailerPrice / (1+(gst/100))) * (1+(gst /100))))/mrp;
        }
        data["retailPrice"] = event.target.retailerPrice.value ? (parseFloat(event.target.retailerPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["retailDiscount"] = event.target.retailerPrice.value ? (((retailDiscount * 100) * 100)/100)?.toFixed(2) : '';
      
        let specialRetailDiscount;
        if (event.target.specialRetailerPrice.value) {
            const specialRetailerPrice = parseFloat(event.target.specialRetailerPrice.value);
            specialRetailDiscount = (mrp -((specialRetailerPrice / (1+(gst/100))) * (1+(gst /100)))) / mrp;
        }
        data["specialRetailPrice"] = event.target.specialRetailerPrice.value ? (parseFloat(event.target.specialRetailerPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["specialRetailDiscount"] = event.target.specialRetailerPrice.value ? (((specialRetailDiscount * 100) * 100)/100)?.toFixed(2) : '';
      
        let interiorDiscount;
        if (event.target.interiorPrice.value) {
            const interiorPrice = parseFloat(event.target.interiorPrice.value);
            interiorDiscount = (mrp -((interiorPrice / (1+(gst/100))) * (1+(gst /100)))) / mrp;
        }
        data["interiorPrice"] = event.target.interiorPrice.value ? (parseFloat(event.target.interiorPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["interiorDiscount"] = event.target.interiorPrice.value ? (((interiorDiscount * 100) * 100)/100)?.toFixed(2) : '';
      
        let endUserDiscount;
        if (event.target.endUserPrice.value) {
            const endUserPrice = parseFloat(event.target.endUserPrice.value);
            endUserDiscount = (mrp -((endUserPrice / (1+(gst/100))) * (1+(gst /100)))) / mrp;
        }
        data["endUserPrice"] = event.target.endUserPrice.value ? (parseFloat(event.target.endUserPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["endUserDiscount"] = event.target.endUserPrice.value ? (((endUserDiscount * 100) * 100)/100)?.toFixed(2) : '';
      
        let oemDiscount;
        if (event.target.oemPrice.value) {
            const oemPrice = parseFloat(event.target.oemPrice.value)
            oemDiscount = (mrp -((oemPrice / (1+(gst/100))) * (1+(gst /100)))) / mrp;
        }
        data["oemPrice"] = event.target.oemPrice.value ? (parseFloat(event.target.oemPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["oemDiscount"] = event.target.oemPrice.value ? (((oemDiscount * 100) * 100)/100)?.toFixed(2) : '';

        let partnerDiscount;
        if (event.target.partnerPrice.value) {
            const partnerPrice = parseFloat(event.target.partnerPrice.value)
            partnerDiscount = (mrp -((partnerPrice / (1+(gst/100))) * (1+(gst /100)))) / mrp;
        }
        data["partnerPrice"] = event.target.partnerPrice.value ? (parseFloat(event.target.partnerPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["partnerDiscount"] = event.target.partnerPrice.value ? (((partnerDiscount * 100) * 100)/100)?.toFixed(2) : '';

        let promoterDiscount;
        if (event.target.promoterPrice.value) {
            const promoterPrice = parseFloat(event.target.promoterPrice.value)
            promoterDiscount = (mrp -((promoterPrice / (1+(gst/100))) * (1+(gst /100)))) / mrp;
        }
        data["promoterPrice"] = event.target.promoterPrice.value ? (parseFloat(event.target.promoterPrice.value) / (1+(gst/100)))?.toFixed(2) : '';
        data["promoterDiscount"] = event.target.promoterPrice.value ? (((promoterDiscount * 100) * 100)/100)?.toFixed(2) : '';
      
        const multiPriceData = [
            {
                "district": "",
                "listingPrice": data?.listingPrice,
                "retailerPrice": data?.retailPrice,
                "interiorPrice": data?.interiorPrice,
                "oemPrice": data?.oemPrice,
                "endUserPrice": data?.endUserPrice,
                "listingDiscount": data?.listingDiscount,
                "retailerDiscount": data?.retailDiscount,
                "interiorDiscount": data?.interiorDiscount,
                "oemDiscount": data?.oemDiscount,
                "endUserDiscount": data?.endUserDiscount,
                "specialRetailerPrice": data?.specialRetailPrice,
                "specialRetailerDiscount": data?.specialRetailDiscount,
                "partnerPrice": data?.partnerPrice,
                "partnerPriceDiscount": data?.partnerDiscount,
                "promoterPrice": data?.promoterPrice,
                "promoterPriceDiscount": data?.promoterDiscount,
                "franchise": event.target.franchise.value
            }
        ]
        let salesChannel = product?.[0]?.salesChannel
        if (event.target.isActiveB2C.value === 'True' && !product?.[0]?.salesChannel?.includes('POS')) {
            salesChannel.push('POS')
        } else {
            salesChannel = salesChannel.filter((item)  => item !== 'POS')
        }
        data ={
            productName: event.target.productName.value,
            sku: event.target.sku.value,
            mrp: parseFloat(event.target.mrp.value),
            isActiveB2C: event.target.isActiveB2C.value === 'True' ? true : false,
            B2C: event.target.B2C.value === 'True' ? true : false,
            B2B: event.target.B2B.value === 'True' ? true : false,
            multiPriceData: multiPriceData,
            franchises: selectedFranchises,
            salesChannel: salesChannel
        }
      
        await axiosInstance
            .post(`/product/update/${productData?.[0]?.id}`,{
              ...data
            })
            .then((res) => {
                if (res.data.status === 'success') {
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: "Product Successfully Updated", type: "success" }
                    });
                    // navigate(-1);
                    handleClose()
                    setReRender(!reRender)
                } else if (res.data.status === 'Server Busy') {
                    alert('Server Busy.. Try Again Later');
                }
                setLoading(false);
            })
    }

    const countPlaces = (num) => {
        const data = Math.round(num*100); 
        return Math.round(data/100)
    };

    const handleFranchise = (field) => {
        
        const price = product?.[0]?.multiPriceData?.length !== 0 ? 
        product?.[0]?.multiPriceData?.find((price) => price?.franchise === franchise)?.[field] : '';
        return price ? countPlaces(price * (1+(product?.[0]?.gst/100))) : ''

    }

    const handleSelectFranchises = (event, value) => {
        setSelectedFranchises(value);
    };

    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                // fullWidth={true}
                sx={{"& .MuiPaper-root": {maxWidth:'80%', width:'80%'}}}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <DialogTitle>{`Update SKU: ${product?.[0]?.sku}`}</DialogTitle>
                <form method="post" onSubmit={handleSubmit} id='form'>
                    <div style={{display:'flex',justifyContent:'space-evenly',flexWrap:'wrap',padding:'0px 10px', width:'100%',marginTop:10}}>
                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                sx={{ 
                                    width: "100%",fontSize:'14px',
                                    "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "#000000"}
                                }}
                                key={product?.[0]?.productName}
                                defaultValue={product?.[0]?.productName} 
                                size='small'
                                name='productName'
                                id="outlined-basic" 
                                label="Product Name" 
                                variant="outlined"
                                disabled
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='mrp'
                                type='number'
                                sx={{ 
                                    width: "100%",fontSize:'14px',
                                    "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "#000000"}
                                }}
                                autoComplete='off'
                                defaultValue={product?.[0]?.mrp}
                                key={product?.[0]?.mrp}
                                size='small' 
                                id="outlined-basic" 
                                label="MRP" 
                                variant="outlined" 
                                disabled={true}
                            />
                        </div>

                        <div style={{width: '33%',marginTop:10}}>
                            <TextField
                                name='sku' 
                                sx={{ 
                                    width: "100%",fontSize:'14px', 
                                    "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "#000000"}
                                }}
                                defaultValue={product?.[0]?.sku}
                                key={product?.[0]?.sku}
                                size='small' 
                                id="outlined-basic" 
                                label="SKU"
                                variant="outlined"
                                disabled
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='listingPrice'
                                error={product?.[0]?.listPriceError}
                                helperText={product?.[0]?.listPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], listPriceError: true, listPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], listPriceError: false, listPriceErrorMessage: '' }])
                                    }
                                }} 
                                defaultValue={handleFranchise('listingPrice')}
                                key={handleFranchise('listingPrice')}
                                required={true} 
                                size='small' 
                                id="outlined-basic" 
                                label="Listing Price (NP)" 
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='retailerPrice'
                                error={product?.[0]?.retailerPriceError}
                                helperText={product?.[0]?.retailerPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], retailerPriceError: true, retailerPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], retailerPriceError: false, retailerPriceErrorMessage: '' }])
                                    }
                                }} 
                                defaultValue={handleFranchise('retailerPrice')}
                                key={handleFranchise('retailerPrice')}
                                size='small' 
                                id="outlined-basic" 
                                label="Retail Price (NP)" 
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='specialRetailerPrice'
                                error={product?.[0]?.specialRetailerPriceError}
                                helperText={product?.[0]?.specialRetailerPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], specialRetailerPriceError: true, specialRetailerPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], specialRetailerPriceError: false, specialRetailerPriceErrorMessage: '' }])
                                    }
                                }}
                                defaultValue={handleFranchise('specialRetailerPrice')}
                                key={handleFranchise('specialRetailerPrice')}
                                size='small' 
                                id="outlined-basic" 
                                label="Special Retail Price (NP)" 
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='interiorPrice'
                                error={product?.[0]?.interiorPriceError}
                                helperText={product?.[0]?.interiorPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], interiorPriceError: true, interiorPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], interiorPriceError: false, interiorPriceErrorMessage: '' }])
                                    }
                                }}  
                                defaultValue={handleFranchise('interiorPrice')}
                                key={handleFranchise('interiorPrice')}
                                size='small' 
                                id="outlined-basic" 
                                label="Interior Price (NP)" 
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='endUserPrice'
                                error={product?.[0]?.endUserPriceError}
                                helperText={product?.[0]?.endUserPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], endUserPriceError: true, endUserPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], endUserPriceError: false, endUserPriceErrorMessage: '' }])
                                    }
                                }}
                                defaultValue={handleFranchise('endUserPrice')}
                                key={handleFranchise('endUserPrice')}   
                                size='small' 
                                id="outlined-basic" 
                                label="End User Price (NP)" 
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='oemPrice'
                                error={product?.[0]?.oemPriceError}
                                helperText={product?.[0]?.oemPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], oemPriceError: true, oemPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], oemPriceError: false, oemPriceErrorMessage: '' }])
                                    }
                                }} 
                                defaultValue={handleFranchise('oemPrice')}
                                key={handleFranchise('oemPrice')}  
                                size='small' 
                                id="outlined-basic" 
                                label="Oem Price (NP)"
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='partnerPrice'
                                error={product?.[0]?.partnerPriceError}
                                helperText={product?.[0]?.partnerPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], partnerPriceError: true, partnerPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], partnerPriceError: false, partnerPriceErrorMessage: '' }])
                                    }
                                }} 
                                defaultValue={handleFranchise('partnerPrice')}
                                key={handleFranchise('partnerPrice')}  
                                size='small' 
                                id="outlined-basic" 
                                label="Partner Price (NP)"
                                variant="outlined" 
                            />
                        </div>
                        
                        <div style={{width:'33%',marginTop:10}}>
                            <TextField
                                name='promoterPrice'
                                error={product?.[0]?.promoterPriceError}
                                helperText={product?.[0]?.promoterPriceErrorMessage}
                                type='number'
                                sx={{ width: "100%",fontSize:'14px' }}
                                step="0.01"
                                autoComplete='off'
                                onChange={(e) => {
                                    if (parseFloat(e.target.value) > product?.[0]?.mrp) {
                                        setProduct([{ ...product?.[0], promoterPriceError: true, promoterPriceErrorMessage: 'Price Cannot Be Greater Than MRP' }])
                                    } else {
                                        setProduct([{ ...product?.[0], promoterPriceError: false, promoterPriceErrorMessage: '' }])
                                    }
                                }} 
                                defaultValue={handleFranchise('promoterPrice')}
                                key={handleFranchise('promoterPrice')}  
                                size='small' 
                                id="outlined-basic" 
                                label="Promoter Price (NP)"
                                variant="outlined" 
                            />
                        </div>

                        <div style={{width: '33%',marginTop:10}}>
                            <Autocomplete
                                name='franchise'
                                id='franchise'
                                size="small"
                                disablePortal
                                defaultValue={franchise}
                                key={franchise}
                                options={selectedFranchises?.length !== 0 ? selectedFranchises : franchises}
                                onChange={(event, newValue) => { setFranchise(newValue) }}
                                getOptionLabel={(franchise) => franchise}
                                sx={{  width: "100%",fontSize:'14px' }}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                renderInput={(params) => (
                                    <TextField {...params} label="Franchise" variant="outlined" required/>
                                )}
                            />
                        </div>

                        <div style={{width:'33%',marginTop:10}}>
                            <Autocomplete
                                name='isActiveB2C'
                                id='isActiveB2C'
                                size="small"
                                disablePortal
                                options={["True", "False"]}
                                defaultValue={product?.[0]?.isActiveB2C ? "True" : "False"}
                                key={product?.[0]?.isActiveB2C ? "True" : "False"}
                                sx={{  width: "100%",fontSize:'14px' }}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                renderInput={(params) => (
                                    <TextField {...params} label="IsActiveB2C" variant="outlined" />
                                )}
                            />
                        </div>

                        <div style={{width: '33%',marginTop:10}}>
                            <Autocomplete
                                name='B2B'
                                id="B2B"
                                size="small"
                                disablePortal
                                options={["True", "False"]}
                                defaultValue={product?.[0]?.B2B ? "True" : "False"}
                                key={product?.[0]?.B2B ? "True" : "False"}
                                sx={{  width: "100%",fontSize:'14px' }}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                renderInput={(params) => (
                                    <TextField {...params} label="B2B" variant="outlined" />
                                )}
                            />
                        </div>

                        <div style={{width: '33%',marginTop:10}}>
                            <Autocomplete
                                name='B2C'
                                id="B2C"
                                size="small"
                                disablePortal
                                options={["True", "False"]}
                                defaultValue={product?.[0]?.B2C ? "True" : "False"}
                                key={product?.[0]?.B2C ? "True" : "False"}
                                sx={{  width: "100%",fontSize:'14px' }}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                renderInput={(params) => (
                                    <TextField {...params} label="B2C" variant="outlined" />
                                )}
                            />
                        </div>
                        
                        <div style={{ width: '33%',marginTop:10 }}>
                            <Autocomplete
                                name='franchises'
                                id='franchises'
                                multiple
                                limitTags={1}
                                size="small"
                                disablePortal
                                options={franchises || []}
                                value={selectedFranchises}
                                getOptionLabel={(franchise) => franchise}
                                onChange={handleSelectFranchises}
                                sx={{  width: "100%",fontSize:'14px' }}
                                ListboxProps={{sx: { fontSize: 14 }}}
                                renderInput={(params) => (
                                    <TextField {...params} label="Franchise" variant="outlined" />
                                )}
                            />
                        </div>
                    </div>

                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        <Button type="submit" id='moveInventory' disabled={
                            product?.[0]?.listPriceError ||
                            product?.[0]?.retailerPriceError ||
                            product?.[0]?.specialRetailerPriceError ||
                            product?.[0]?.interiorPriceError ||
                            product?.[0]?.endUserPriceError ||
                            product?.[0]?.oemPriceError ||
                            product?.[0]?.partnerPriceError ||
                            product?.[0]?.promoterPriceError
                        }>SUBMIT</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </Fragment>
    )
}

export default UpdateProductManagement;