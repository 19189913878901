"use client";

import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FolderUp } from "lucide-react";
import styles from "./folderUpload.module.css";

export function FolderUpload({ handleFileChange }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleFileChange(acceptedFiles);
    },
    [handleFileChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    directory: true,
    multiple: true,
  });

  return (
    <div {...getRootProps()} className={styles.dropzone}>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "40%",
        }}
      >
        <input webkitdirectory="true" {...getInputProps()} />
        <FolderUp size={48} />
        {isDragActive ? (
          <p>Drop the folder here ...</p>
        ) : (
          <p>Drag & drop a folder here, or click to select a folder</p>
        )}
        <button className={styles.browseButton}>Browse Folder</button>
      </div>
    </div>
  );
}
