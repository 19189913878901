import { Fragment, useState,useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';

const NewSalesUser = (props)=> {
    const [loading,setLoading] = useState(false);
    const [services,setServices] = useState([]);
    const [errorMsg,setErrorMsg] = useState('');

    const { 
        // fetchCustomers,
        userName,
        setUserName,
        name,
        setName,
        phoneNo,
        setPhoneNo,
        customerType,
        setCustomerType,
        password,
        setPassword,
        showModal,
        hideDialog,
        setReRender,
        reRender
    } = props;

    const handleUserNameChange = (event) => {
        setUserName(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const handleNumChange = event => {
        const limit = 10;
        setPhoneNo(event.target.value.slice(0, limit));
    };

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    useEffect(() => {
        axiosInstance
			.get(`/roles/fieldSales`)
			.then((res) => {
				setServices(res.data.data.permissions);
			})
			.catch((err) => {
				console.log(err.response.data.message);
			})
    },[])

    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn')
        button.innerHTML = 'Save User';

        const data = {
            phone:`+91${event.target.phone.value}`,
            name: event.target.Name.value,
            username: event.target.userName.value ? event.target.userName.value : undefined,
            password: event.target.password.value ? event.target.password.value : undefined,
            role: 'fieldSales',
			services: services,
            preSalesUser: true,
            fieldSalesUser: true
        }

        axiosInstance
            .post('/admin/new', {...data})
            .then((res) =>{
                const form = document.getElementById('form');
                form.reset();
                hideDialog();
                setUserName('');
                setName('');
                setPhoneNo('');
                setCustomerType('');
                setPassword('');
                // fetchCustomers();
                setLoading(false);
                button.innerHTML = 'User Created';
                if (setReRender && reRender) {
                    setReRender(!reRender)
                }
            }).catch((err) => {
                setErrorMsg(err?.response?.data?.message)
                button.innerHTML = 'Save User';
                setLoading(false);
                setTimeout(() => {
                    setErrorMsg('')
                },5000)
            })
    }

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    const handleCustomerType = (event) => {
        if (event.target.value) {
            setCustomerType(event.target.value)
        }
    }

    return (
        <Fragment>
            {showModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'15%',bottom:'30%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>Create Sales User</p>
                    </div>

                    {errorMsg && (
                        <p>{errorMsg}</p>
                    )}
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form' 
                        style={{
                            overflowY:'auto',
                            height:'72%'
                        }}
                    >
                        <div style={{padding:20}}>
                            <div style={{display:'flex'}}>
                                <div style={{ width:'49%',marginRight:24 }}>
                                    <TextField
                                        required
                                        id='userName'
                                        name="userName"
                                        label="User Name"
                                        placeholder='User Name'
                                        autoComplete='off'
                                        onBlur={handleUserNameChange}
                                        sx={{ width: '100%',"& label": {top:userName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'49%'}}>
                                    <TextField
                                        required
                                        id='Name'
                                        name="Name"
                                        label="Name"
                                        placeholder='Name'
                                        autoComplete='off'
                                        onBlur={handleNameChange}
                                        sx={{ width: '100%',"& label": {top: name ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{ width:'49%',marginRight:24 }}>
                                    <TextField 
                                        required
                                        id='phoneNumber'
                                        name="phone"
                                        type="number"
                                        label="Phone Number"
                                        maxLength={10}
                                        onChange={handleNumChange}
                                        onBlur={(e) => handleMinLength(e)}
                                        placeholder='Enter Your 10 Digit Phone Number'
                                        value={phoneNo}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: phoneNo ? "0%" : "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'49%',marginRight:24 }}>
                                    <TextField 
                                        required
                                        id="password"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        onBlur={handlePassword}
                                        placeholder='Enter Password'
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: password ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                                <div className={Classes.PhoneErrorMsg} id='phoneErrMsg'>
                                    <p>Please enter a valid 10 digit mobile number</p>
                                </div>
                                
                            </div>
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'29%',
                                position:'fixed',
                                boxShadow:'none',
                                border: 'none'
                            }}
                        >
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideDialog}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                CREATE
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default NewSalesUser;