import React, { Fragment, useState, useEffect } from 'react';
import Table from '../../../components/table/table';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';

const InventoriesWithAllLocations = () => {
    const [data, setData] = useState(null);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [locations, setLocations] = useState([]);
    const [reRender] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState({});

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations`)
            .then((res) => {
                const hiddenLocations = {};
                const regExpForDummyLocations = new RegExp('dummy', 'i')
                let locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                    if (regExpForDummyLocations.test(location?.locationCode)) {
                        hiddenLocations[location?.locationCode] = false;
                    }
                }
                setHiddenColumns(hiddenLocations);
                process.env.REACT_APP_STRICT_LOCATIONS?.length && (
                    locations = locations?.filter((location) => !process.env.REACT_APP_STRICT_LOCATIONS.includes(location))
                )
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchLocation();
    },[])

    const columns = [
        {
            field: 'sku_code',
            headerName: 'SKU Code',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            filterable : true,
            sortable: true
        },
        {
            field: 'product_name',
            headerName: 'Product Name',
            flex: 2,
            headerClassName: 'super-app-theme--header',
            filterable : true,
            sortable: true
        },
        ...locations.map(e => ({
            field: e,
            headerName: e,
            flex: 1.4,
            headerClassName: 'super-app-theme--header',
            filterable : false,
            sortable: true,
            renderCell: (params) => {
                return params.value ?? '--'
            }
        }))
    ];

    return (
        <Fragment>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />            

            <Table 
                columns={columns} 
                dataPosition={"inventories"} 
                endpoint={`/inventory/getInventoryWithAllLocations`} 
                rowId={'sku_code'}
                checkBoxSelection={false}
                tabName={'Inventory Analytics'}
                setData={setData}
                data={data}
                createdByFilter={false}
                isReactDispatch={true}
                reRender={reRender}
                hiddenColumns={hiddenColumns}
                columnVisibilityModel={hiddenColumns}
                mobileResponsive={true}
                exportEndpoint={'/exports/fetchInventoryWithAllLocations'}
                exportFileName={`Inventory With ALL Locations - ${new Date()}`}
            />
        </Fragment>
    )
}
export default InventoriesWithAllLocations;