import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Spinner from '../../../components/spinner';
import SnackBar from '../../../components/SnackBar';
import axiosInstance from '../../../utility/axios-instance';
import Classes from "../customers/css/Modal.module.css";
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SwitchComponent = (props) => {
    const {label='', checked, setChecked, width='100%'} = props
    return <>
        <div onClick={()=>setChecked(!checked)}
        style={{border:'1.5px solid lightgrey',borderRadius:'5px',display:'flex',justifyContent:'space-between', width: width,cursor:'pointer'}}>
            <label style={{alignSelf:'center',paddingLeft:'10px'}}>{label}</label>
            <Switch
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
            />
        </div>
    </>
}

export default function PincodeDialog(props) {
    const { open, setReRender, reRender, action, hideCreatePopup,
        pincode,
        setPincode,
        district,
        setDistrict,
        deliveryStatus,
        setDeliveryStatus,
        state,
        setState,
        city,
        setCity, available, setAvailable, oavcw, setOavcw, handleSubmit} = props;
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [stateNames, setStateNames] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [cities, setCities] = useState([]);

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    
    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStateNames(response?.data?.data);
            })

            axiosInstance
            .get('pincode/getAllDistricts')
            .then((response) => {
                setDistricts(response?.data?.data ?? [] );
            })

            axiosInstance
            .get('pincode/getAllCities')
            .then((response) => {
                setCities(response?.data?.data ?? []);
            })
    }, [])

    function handlePincodeChange(event) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        setPincode(enteredPincode);
    }

    const handleClose  = () => {
        hideCreatePopup();
    }
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                sx={{'& .MuiDialog-paper': {maxWidth:'500px'}}}
            >
                <DialogTitle>Pincode</DialogTitle>
                <DialogContent>
                    <form method="post" 
                        onSubmit={handleSubmit}
                        id='form' >
                        <div style={{ padding: "5px 20px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <TextField
                                    type="number"
                                    label={'Pincode'}
                                    placeholder='Pincode'
                                    key={'Pincode'}
                                    required={true}
                                    id='pincode'
                                    name={'pincode'}
                                    value={pincode}
                                    defaultValue=""
                                    onChange={handlePincodeChange}
                                    onKeyDown={preventNegativeValues}
                                    autoComplete='off'
                                    sx={{ width: '48%' }}
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />

                                <Autocomplete
                                    disablePortal
                                    id="delivery"
                                    name="delivery"
                                    options={["Delivery", "Non Delivery"]}
                                    sx={{ width: '48%' }}
                                    required
                                    defaultValue={"Delivery"}
                                    value={deliveryStatus}
                                    onChange={(e,newValue) => setDeliveryStatus(newValue)}
                                    renderInput={(params) =>
                                        <TextField {...params} label={"Delivery Status"} />
                                    }
                                    size="small"
                                />
                            </div>

                            <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                                <Autocomplete
                                    required={true}
                                    disablePortal
                                    id="state"
                                    name='state'
                                    options={stateNames}
                                    onChange={(e,newValue) => setState(newValue?.label)}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '48%' }}
                                    renderInput={(params) => <TextField {...params} label="States" required />}
                                    size="small"
                                    value={state}
                                />

                                <Autocomplete
                                    required={true}
                                    disablePortal
                                    id="district"
                                    name='district'
                                    options={districts}
                                    onChange={(e,newValue) => setDistrict(newValue)}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '48%' }}
                                    renderInput={(params) => <TextField {...params} label="Districts" required />}
                                    size="small"
                                    value={district}
                                />
                            </div>

                            <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                                <SwitchComponent
                                    label={"Availability"}
                                    checked={available}
                                    setChecked={setAvailable}
                                    width={"48%"}
                                />

                                <SwitchComponent
                                    label={"O.A.V.C.W."}
                                    checked={oavcw}
                                    setChecked={setOavcw}
                                    width={"48%"}
                                />
                            </div>

                            <div style={{ marginTop: 15, display: 'flex', justifyContent: 'space-between' }}>
                                <Autocomplete
                                    disablePortal
                                    id="city"
                                    name='city'
                                    options={cities}
                                    onChange={(e,newValue) => setCity(newValue)}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '48%' }}
                                    renderInput={(params) => <TextField {...params} label="Cities" />}
                                    size="small"
                                    value={city}
                                />
                            </div>
                        </div>
                        <DialogActions>
                            <Button onClick={handleClose}>CLOSE</Button>
                            {
                                action === "create"
                                ? <Button type='submit' id='btn'>CREATE</Button>
                                : <Button type='submit' id='btn'>UPDATE</Button>
                            }
                            
                        </DialogActions>
                    </form>
                    {loading ? <Spinner /> : null}
                </DialogContent>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}