"use client";

import { useState } from "react";
import { FolderUpload } from "../../../components/uploadFilesAndFolders/folderUpload";
import { FilePreview } from "../../../components/uploadFilesAndFolders/filePreview";
import { ProgressBar } from "../../../components/uploadFilesAndFolders/progressBar";
import { LogSection } from "../../../components/uploadFilesAndFolders/logSection";
import { SettingsPanel } from "../../../components/uploadFilesAndFolders/settingsPanel";
import styles from "./uploadImagesNew.module.css";
import axiosInstance from "../../../utility/axios-instance";
import SnackBar from "../../../components/SnackBar";
import axios from "axios";

export default function Home() {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [logs, setLogs] = useState([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  const handleUpload = async () => {
    if (files.length === 0) return alert("No files selected!");

    const fileData = files.map((file) => ({
      name: file.name,
      path: file.webkitRelativePath, // Preserve folder structure
      type: file.type,
    }));

    // Step 1: Get pre-signed URLs
    const response = await axiosInstance.post(
      "/uploadAndProcessImagesRoutes/getPresignedUrls",
      {
        files: fileData,
      }
    );

    const { uploadUrls } = response?.data;

    // Step 2: Upload files directly to S3
    await Promise.all(
      files.map(async (file, index) => {
        const { url } = uploadUrls[index];
        try {
          const response = await axios.put(
            url, 
            file, 
            {
              headers: { "Content-Type": file.type },
            }
          );
          console.log("File uploaded successfully:", response);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: `File uploaded successfully: ${file.name}`,
              type: "success",
            };
          });
          setFiles([]);
        } catch (error) {
          console.error("Error uploading file:", error);
          setSnackBar((prevSnackBar) => {
            return {
              ...prevSnackBar,
              display: true,
              message: `Error uploading file: ${file.name}`,
              type: "error",
            };
          });
        }
      })
    );

    alert("Files uploaded successfully!");
  };

  const handleFileChange = (uploadedFiles) => {
    setFiles(Array.from(uploadedFiles));
    // Simulate processing
    // setProgress(0);
    // setLogs([]);
    // const totalFiles = uploadedFiles.length;
    // uploadedFiles.forEach((file, index) => {
    //   setTimeout(() => {
    //     setProgress(((index + 1) / totalFiles) * 100);
    //     setLogs((prev) => [...prev, `Processing: ${file.name}`]);
    //   }, index * 500);
    // });
  };

  return (
    <main className={styles.main}>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      <div className={styles.container}>
        <div className={styles.uploadSection}>
          <FolderUpload handleFileChange={handleFileChange} />
          {/* <ProgressBar progress={progress} /> */}
        </div>
        <div>
          <SettingsPanel handleUpload={handleUpload} />
          <FilePreview files={files} />
        </div>
      </div>
      {/* <LogSection logs={logs} /> */}
    </main>
  );
}
