import { File } from "lucide-react";
import styles from "./filePreview.module.css";

export function FilePreview({ files }) {
  return (
    <div className={styles.filePreview}>
      <ul className={styles.fileList}>
        {files.map((file, index) => (
          <li key={index} className={styles.fileItem}>
            <File size={16} />
            <span>{file.webkitRelativePath || file.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
