import React, { Fragment, useState, useEffect } from "react";
import axiosInstance from '../../../utility/axios-instance';
import { Autocomplete, TextField } from '@mui/material';
import Classes from '../createOrders/css/Modal.module.css';
import Spinner from "../../../components/spinner";
import { useSelector } from 'react-redux';

const InventoryAdjustmentDialog = (props) => {
    const auth = useSelector((state) => state?.auth);
    const role = auth?.user?.role;
    const [loading,setLoading] = useState(false);
    const [skus,setSkus] = useState([]);
    const [binType,setBinType] = useState('');
    const [locations,setLocations] = useState([]);
    const [bins,setBins] = useState([]);

    const defaultLoc = role === 'super-admin' || role === 'admin'? locations?.[0] : auth?.user?.locations?.[0] || '';
    const [locCode, setLocCode] = useState(defaultLoc);

    const fetchData = (text) => {
        const filters = JSON.stringify([{"field":"isVirtualProduct","value":"false"}])
        axiosInstance
            .get(`/product/variant/sku?text=${text ? text :''}&filters=${filters}`)
            .then((res) => {
                setSkus(res.data.data)
            })
    }

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations?text=${text ? text :''}`)
            .then((res) => {
                const locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                }
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchData();
        fetchLocation();
    },[])

    const {
        showPopup,
        hidePopup,
        setReRender,
        reRender
    } = props;

    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const modes = {
            "Inventory Increase": 'incr',
            "Inventory Decrease": 'decr'
        }

        const data = {
            "sku": event.target.sku.value,
            "binCode": event.target.binCode.value,
            "adjustedQty": parseInt(event.target.adjustedQty.value),
            "type": event.target.binType.value,
            "mode": modes[event.target.mode.value],
            "remark": event.target.remark.value,
            "locationCode": event.target.locationCode.value
        }

        axiosInstance
            .post(`/inventory/createInventoryAdjustment`, { ...data })
            .then((res) => {
                setLoading(false);
                if (setReRender) { setReRender(!reRender) }
                hidePopup();
                console.log(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                alert(err.response.data.message);
                if (setReRender) { setReRender(!reRender) }
                hidePopup();
            })
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    useEffect(() => {
        if (locCode) {
            axiosInstance
              .get(`/inventory/bins?locationCode=${locCode ? locCode : auth?.user?.locations?.[0]}&type=${binType ? binType: 'Saleable'}`)
              .then((res) => {
                if(res.data.data.bins.length !==0) {
                  const bins = [];
                  for (let bin of res?.data?.data?.bins) {
                    bins.push(bin?.code)
                  }
                  setBins(bins);
                } else {
                  setBins(res.data.data.bins);
                }
              });
          }
    },[locCode,binType])

    return (
        <Fragment>
            {showPopup && (
                <div className={[Classes.modal, Classes.contactPopUp].join(' ')} style={{top:'12%',bottom:"16%"}}>
                    <div className={Classes.BoxContent}>
                        <p>Adjust Inventory</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='form'>
                        <div style={{ padding: '30px 20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width:'49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="sku"
                                        name="sku"
                                        defaultValue={skus?.[0]}
                                        options={skus}
                                        renderInput={(params) => <TextField 
                                            {...params} label="SKU" required={true}
                                            onChange={(event)=> {fetchData(event.target.value)}}
                                        />}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="locationCode"
                                        name="locationCode"
                                        defaultValue={defaultLoc}
                                        options={role === 'super-admin' || role === 'admin'? locations : auth?.user?.locations || []}
                                        renderInput={(params) => <TextField 
                                            {...params} label="Location Code" required={true}
                                            // onChange={(event)=> {fetchLocation(event.target.value)}}
                                            onBlur={(event) => {setLocCode(event.target.value)}}
                                        />}
                                    />
                                </div>

                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="binType"
                                        name="binType"
                                        options={["Saleable", "Rejected", "QC", "Packing", "Returned", "Staging"]}
                                        defaultValue={"Saleable"}
                                        renderInput={(params) => <TextField  
                                            {...params} label="Bin Type" onBlur={(event) => {setBinType(event.target.value)}}
                                        />}
                                    />
                                </div>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="binCode"
                                        name="binCode"
                                        // defaultValue={bins?.[0] || ''}
                                        options={bins}
                                        renderInput={(params) => <TextField {...params} label="Bin Code" required={true}/>}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <TextField
                                        required={true}
                                        id='adjustedQty'
                                        name="adjustedQty"
                                        label="Adjust Qty"
                                        type="number"
                                        placeholder='Enter Quantity'
                                        autoComplete='off'
                                        sx={{width:'100%'}}
                                        min={0}
                                        onKeyDown={preventNegativeValues}
                                    />
                                </div>

                                <div style={{ width: '49%' }}>
                                    <TextField
                                        id='remark'
                                        name="remark"
                                        label="Remarks"
                                        placeholder='Enter Remarks'
                                        autoComplete='off'
                                        sx={{width:'100%'}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox}>
                                <div style={{ width: '49%' }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="mode"
                                        name="mode"
                                        defaultValue="Inventory Increase"
                                        options={["Inventory Increase","Inventory Decrease"]}
                                        renderInput={(params) => <TextField  {...params} label="Adjustment Mode" required/>}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.DialogBtnBox}>
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hidePopup}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>
                    </form>
                </div>
            )}

            {loading ? <Spinner /> : null}
        </Fragment>
    );
}


export default InventoryAdjustmentDialog;