import { Button, Chip, Link, TextField, Tooltip } from "@mui/material";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import Table from "../../../components/table/table";
import Permission from '../../auth/permissions';
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { useState } from "react";
import RefundAmountPopup from './refundAmtPopup';
import axiosInstance from "../../../utility/axios-instance";
import RefundClosePopup from "./closeRefundPopup";
import SnackBar from '../../../components/SnackBar';
import Spinner from "../../../components/spinner";
import { services } from "../../../utility/checkRoleBasedPermission";
import MobileResponsive from "./mobileResponsive";
import Classes from '../customers/css/customer.module.css';
import { useSelector } from 'react-redux';
import { setRefunds } from '../../../store/refundsSlice';
import DialogWithInputs from "../../../components/uploadDocWithOtherInputs";
import { requestSignedUrls } from "../../../utility/s3";
import { Ban } from "lucide-react";

const columnVisibilityModel={
    createdAt: false,
    updatedAt: false,
    cancelId: false,
}

const Refund = (props) => {
    const [amountPopup, setAmountPopup] = useState(false);
    const [closePopup, setClosePopup] = useState(false);
    const [refundId, setRefundId] = useState(null);
    const [refundAmt, setRefundAmt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reRender, setReRender] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
    const [billingCode, setBillingCode] = useState(null);
    const [creditDocUploadData, setCreditDocUploadData] = useState({
        creditNoteNumber: "",
        remark: "",
    })

    const refunds = useSelector((state) => state?.refunds);

    const showPopup = (e, id, amt) => {
        setRefundId(id);
        setRefundAmt(amt);
        setAmountPopup(true);
    }
    const hidePopup = () => {
        setRefundId(null);
        setRefundAmt(null);
        setAmountPopup(false);
    }

    const showClosePopup = (e, row) => {
        setRefundId(row?.refundId);
        if(row?.creditNotes?.filter((creditNote)=>{
            return creditNote?.url_key
        })?.length === 0) {
            setCreditDocUploadData((prevCreditDocUploadData)=>{
                return {
                    ...prevCreditDocUploadData,
                    refundId: row?.refundId,
                } 
            });
            setBillingCode(row?.billingCode);
            setClosePopup(true);
            return;
        }
        requestSignedUrls(row?.creditNotes?.map((creditNote) => creditNote?.url_key))
        .then((signedUrls) => {
            setCreditDocUploadData((prevCreditDocUploadData)=>{
                return {
                    ...prevCreditDocUploadData,
                    refundId: row?.refundId,
                    docUrls: signedUrls || [],
                } 
            });
            setBillingCode(row?.billingCode);
            setClosePopup(true);
        });
    }

    const hideClosePopup = () => {
        setRefundId(null);
        setCreditDocUploadData((prevCreditDocUploadData) => ({
            ...prevCreditDocUploadData,
            refundId: "",
        }));
        setBillingCode(null);
        setClosePopup(false);
    }

    const handleRefundAmount = async (e,) => {
        setLoading(true);
        e.preventDefault();
        const data = {
            amount: refundAmt
        }
        await axiosInstance
            .put(`/pgOrder/admin/refundStatus/${refundId}`, data)
            .then((res) => {
                setLoading(false);
                setReRender(!reRender)
                hidePopup();
                const successMessage = `Updated Refund Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                setLoading(false);
                setError(err?.response?.data?.message);
                setReRender(!reRender)
                hidePopup();
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    }

    const handleRefundClose = async (e) => {
        setLoading(true);
        e.preventDefault();
        const file = e?.target?.file?.files[0];
        const formData = new FormData()
        formData.append("documentsAttached", file);

        await axiosInstance
            .put(`/pgOrder/admin/refundMarkClosed/${billingCode}`, formData)
            .then((res) => {
                setLoading(false);
                setReRender(!reRender)
                hideClosePopup();
                const successMessage = `Docs uploaded Successfully`
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
            })
            .catch((err) => {
                setLoading(false);
                setError(err?.response?.data?.message);
                setReRender(!reRender)
                hideClosePopup();
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            });
    }

    const columns = [
    {
        field: 'refundId',
        headerName: 'Refund Id',
        flex: .5,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'orderName',
        headerName: 'Order Name',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: .8,
        headerClassName: 'super-app-theme--header',
        renderCell : (params) => (
            params?.row?.status === "Closed" ? (
                <Chip size="small" label={params?.row?.status} />
            ) : (
                <Chip size="small" style={{ backgroundColor: '#ffea88' }} label={params?.row?.status} />
            )
        )
    },
    {
        field: 'customerName',
        headerName: 'Customer Name',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
            params?.row?.customerName ? <div style={{display: 'flex', alignItems: 'center'}}>
                <Tooltip title={params?.row?.blacklisted ? "Blacklisted customer": ''} placement="top" style={{display: 'flex', alignItems: 'center'}}>
                    {params?.row?.blacklisted ? <Ban style={{color: 'red', marginRight: '8px'}} size='12px'/>: ''}
                    <p style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {params?.row?.customerName}
                    </p>
                </Tooltip>
            </div>
            : null
        )
    },
    {
        field: 'against',
        headerName: 'Against',
        flex: .7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'locationCode',
        headerName: 'Location Code',
        flex: .7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'returnNumber',
        headerName: 'Return Number',
        flex: 1.5,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'cancelId',
        headerName: 'Cancel Id',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'billingCode',
        headerName: 'Billing Code',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'updatedAt',
        headerName: 'Updated At',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'amount',
        headerName: 'Refund Amount',
        flex: 1.2,
        headerClassName: 'super-app-theme--header',
        renderCell : (params) => (
            <div style={{display: 'flex', justifyContent : 'space-between'}}>
                &#8377;{params?.row?.amount ? params?.row?.amount : "N/A"}
                <Permission service={services?.refunds} permission="update">
                    <BorderColorIcon sx={{cursor : 'pointer', color : 'gray'}}
                        fontSize='small' 
                        onClick={(e) => showPopup(e, params?.row?.refundId, params?.row?.amount)}
                    />
                </Permission>

            </div>
        )
    },
    {
        field: 'documentsAttached',
        headerName: 'Documents Attached',
        flex: 2,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (<>
            {params?.row?.documentsAttached?.length > 0 && (
                <div style={{ display: 'flex', gap: "5px", marginRight: '5px' }}>
                    {params?.row?.documentsAttached?.slice(0, 1)?.map((doc) => (
                        <Chip size="small" icon={<FilePresentIcon />} clickable label={decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 6) + "..."} component="a" href={doc} />
                    ))}
                    {params?.row?.documentsAttached?.length > 1 ? (
                        <Tooltip title={
                            <div style={{ gap: "20px" }}>
                                {params?.row?.documentsAttached?.slice(1)?.map((doc, index) => (
                                    <Link color="inherit" href={doc}>{decodeURIComponent(doc?.substring(doc?.lastIndexOf('/') + 1, doc?.lastIndexOf('?')))?.substring(0, 8)} , </Link>
                                ))}
                            </div>
                        } arrow>
                            <Chip size="small" label={`+ ${params?.row?.documentsAttached?.length - 2} `} style={{ margin: '3px', cursor: "pointer", opacity: '0.8' }} />
                        </Tooltip>
                    ) : null}
                </div>
            )}
            {params?.row?.status !== 'Pending' && params?.row?.status !== 'Rejected' ? (
                <div>
                    <Permission service={services?.refunds} permission="update">
                        <Button size="small" onClick={(e) => showClosePopup(e, params?.row)} type='submit' variant="contained">
                            {params?.row?.status === 'Approved' ?  "Mark Close": "Upload"}
                        </Button>
                    </Permission>
                </div>
            ) : null}
        </>)
    },
];

    return (
        <>
            <div className={Classes.MuiTable}>
                <Table
                    columns={columns}
                    dataPosition={"fetchedRefunds"}
                    endpoint={`/pgOrder/admin/refunds/`}
                    rowId={'refundId'}
                    version="version-1"
                    columnVisibilityModel={columnVisibilityModel}
                    reRender={reRender}
                    service={'refundOrder'}
                    tabName={'Refunds'}
                    setData={setRefunds}
                    data={refunds}
                    mobileResponsive={true}
                    exportEndpoint={'/exports/fetchRefundOrders'}
                    exportFileName={`Refund Orders - ${new Date()}`}
                />
            </div>

            <div className={Classes.MobileResponsive}>
                <MobileResponsive 
                    columns={columns}
                    dataPosition={"fetchedRefunds"}
                    endpoint={`/pgOrder/admin/refunds/`}
                    rowId={'refundId'}
                    reRender={reRender}
                    showPopup={showPopup}
                    showClosePopup={showClosePopup}
                />
            </div>

            <div className={closePopup ? Classes.UpdateSalesDialog : undefined}>
                <DialogWithInputs
                    dialogWithInputsOpen={closePopup}
                    handleDialogWithInputsClose={hideClosePopup}
                    dialogFormUploadData={creditDocUploadData}
                    setDialogFormUploadData={setCreditDocUploadData}
                    endpoint={`/pgOrder/admin/refundMarkClosed/${billingCode}`}
                    setReRender={setReRender}
                    documentFieldName={"creditNoteDocument"}
                    documentName={"Refund Credit Note"}
                    dialogTitle={"Mark Close Refund Id: " + refundId}
                >
                    <TextField
                        label="Credit Note Number"
                        name="creditNoteNumber"
                        variant="outlined"
                        size="small"
                        style={{ marginTop: "10px" }}
                        value={creditDocUploadData?.creditNoteNumber}
                        onChange={(e) => {
                        setCreditDocUploadData((prevCreditDocUploadData) => ({
                            ...prevCreditDocUploadData,
                            creditNoteNumber: e?.target?.value,
                        }));
                        }} 
                        fullWidth
                    />
                    
                    <TextField
                        label="Remark"
                        name="remark"
                        variant="outlined"
                        size="small"
                        style={{ marginTop: "10px" }}
                        value={creditDocUploadData?.remark}
                        onChange={(e) => {
                        setCreditDocUploadData((prevCreditDocUploadData) => ({
                            ...prevCreditDocUploadData,
                            remark: e?.target?.value,
                        }));
                        }}
                        fullWidth
                    />

                </DialogWithInputs>
            </div>

            <div className={amountPopup ? Classes.UpdateSalesDialog : undefined}>
                <RefundAmountPopup
                    showPopup={amountPopup}
                    hidePopup={hidePopup}
                    refundAmt={refundAmt}
                    setRefundAmt={setRefundAmt}
                    handleRefundAmount={handleRefundAmount}
                    showClosePopup={showClosePopup}
                    hideClosePopup={hideClosePopup}
                />
            </div>

            {loading ? <Spinner /> : null}
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </>
    );
};

export default Refund;
