import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import { TextField, Autocomplete, Switch, FormControlLabel } from '@mui/material';
import axiosInstance from '../../../utility/axios-instance';
import Classes from "../customers/css/Modal.module.css";
import Spinner from '../../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateFranchise = (props) => {
    const { open, closeDialog, setReRender, franchiseDetails, setFranchiseDetails, Id, reRender } = props;

    const [loading, setLoading] = useState(false);
    const [pincode, setPincode] = useState('');
    const [num, setNum] = useState('');
    const [gstNO, setGstNO] = useState('');
    const [locationCode, setLocationCode] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [locations, setLocations] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [cities,setCities] = useState([]);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [skipQcStep, setSkipQcStep] = useState(false);

    const fetchLocation = () => {
        axiosInstance.get('/location/locations?type=WH&text=')
            .then(res => {
                const locations = [];
                if (res.data.data.length !== 0) {
                    for (let location of res?.data?.data) {
                        locations.push(location?.locationCode)
                    }
                }
                setLocations(locations)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        setLocationCode(franchiseDetails?.locations)
        fetchLocation()
    }, [franchiseDetails])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const data = {
            name: event.target.name.value,
            gst: event.target.gst.value,
            address: event.target.address.value,
            pincode: event.target.pincode.value,
            phone: event.target.phone.value,
            state: event.target.state.value,
            city: event.target.city.value,
            pan: event.target.pan.value,
            email: event.target.email.value,
            bank_account_name: event.target.bank_account_name.value,
            bank_account_number: event.target.bank_account_number.value,
            bank_account_ifsc: event.target.bank_account_ifsc.value,
            bank: event.target.bank.value,
            invoice_prefix: event.target.invoice_prefix.value,
            invoice_infix: event.target.invoice_infix.value,
            cin: event?.target?.cin?.value ? event?.target?.cin?.value : undefined,
            district: event.target.district.value,
            locations: locationCode,
            company_name: event?.target?.company_name?.value,
            skip_qc_step: JSON.parse(skipQcStep),
            draft_order_prefix: event?.target?.draft_order_prefix?.value,
            draft_order_suffix: event?.target?.draft_order_suffix?.value,
            order_prefix: event?.target?.order_prefix?.value,
            order_suffix: event?.target?.order_suffix?.value,
            note_optional: event?.target?.note_optional?.checked,
            refrence_number_optional: event?.target?.refrence_number_optional?.checked,
            parchi_made_by_optional: event?.target?.parchi_made_by_optional?.checked
        }

        axiosInstance
            .put(`/pos/updateFranchise/${Id}`, { ...data })
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                handleClose();
                setReRender(!reRender);
                setLoading(false);
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleClose = () => {
        setNum('');
        setPincode('');
        setGstNO('');
        document.getElementById('isValidGstUpdate').style.display = 'none';
        document.getElementById('pinErrMsgUpdate').style.display = 'none';
        document.getElementById('phoneErrMsgUpdate').style.display = 'none';
        document.getElementById('isValidPANUpdate').style.display = 'none';
        // document.getElementById('isValidCINUpdate').style.display = 'none';
        setLocationCode([]);
        closeDialog();
    }


    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('updateBtn').disabled = false;
            document.getElementById('isValidGstUpdate').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('updateBtn').disabled = false;
                document.getElementById('isValidGstUpdate').style.display = 'none';
            } else {
                document.getElementById('updateBtn').disabled = true;
                document.getElementById('isValidGstUpdate').style.display = 'block';
            }
        }
    }

    const handleValidPAN = (event) => {
        let regex = new RegExp(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('updateBtn').disabled = false;
            document.getElementById('isValidPANUpdate').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('updateBtn').disabled = false;
                document.getElementById('isValidPANUpdate').style.display = 'none';
            } else {
                document.getElementById('updateBtn').disabled = true;
                document.getElementById('isValidPANUpdate').style.display = 'block';
            }
        }
    }


    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if (event.target.value.match(phoneno)) {
                document.getElementById('phoneErrMsgUpdate').style.display = 'none';
                document.getElementById('updateBtn').disabled = false;
            } else {
                document.getElementById('updateBtn').disabled = true;
                document.getElementById('phoneErrMsgUpdate').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsgUpdate').style.display = 'none';
            document.getElementById('updateBtn').disabled = false;
        }
    }

    const handleMinLengthPinCode = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                document.getElementById('pinErrMsgUpdate').style.display = 'none';
                document.getElementById('updateBtn').disabled = false;
            } else {
                document.getElementById('updateBtn').disabled = true;
                document.getElementById('pinErrMsgUpdate').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsgUpdate').style.display = 'none';
            document.getElementById('updateBtn').disabled = false;
        }
    }

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response.data.data)
            })
    }, [])

    useEffect(() => {
        axiosInstance
            .get(`/pincode/getAllDistricts`)
            .then((res) => {
                const districts = res?.data?.data;
                setDistricts(districts);
            })
            .catch((error) => {
                const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : 'Unable to fetch districts'
                setLoading(false)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
        
    }, []);

    useEffect(() => {
        if(selectedState) {
            axiosInstance
            .get(`/pincode/getAllCities?state=${selectedState}`)
            .then((response) => {
                setCities(response.data.data)
            })
        }
    }, [selectedState])

    const handleChange = (event) => {
        setFranchiseDetails({
            ...franchiseDetails,
            [event.target.name]: event.target.checked,
        });
        
    };

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                sx={{'& .MuiDialog-paper': {maxWidth:'775px'}}}
            >
                <DialogTitle>Update Franchise</DialogTitle>
                <form method="post" onSubmit={handleSubmit} id='form' >
                    <div style={{ padding: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TextField
                                label={'Name'}
                                placeholder={'Enter franchise name'}
                                required={true}
                                id='name'
                                name={'name'}
                                key={franchiseDetails?.name}
                                defaultValue={franchiseDetails?.name}
                                autoComplete='off'
                                size="small"
                                sx={{ width: '32%' }}
                            />
                            <div style={{ width: '32%' }}>
                                <TextField
                                    label={'GST'}
                                    placeholder={'Enter franchise GST'}
                                    required={true}
                                    id='gst'
                                    name={'gst'}
                                    minLength={15}
                                    maxLength={15}
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={handleValidGst}
                                    onChange={(event) => setFranchiseDetails((prevFormData) => {
                                        return {
                                            ...prevFormData,
                                            gst: event.target.value
                                        }
                                    })}
                                    value={franchiseDetails?.gst ? franchiseDetails?.gst : ''}
                                    autoComplete='off'
                                    autoFocus='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />

                                <div id='isValidGstUpdate' className={Classes.IsValidGst}>
                                    <p>Please fill valid Gst Number</p>
                                </div>
                            </div>

                            <div style={{ width: '32%' }}>
                                <TextField
                                    type="number"
                                    label={'Phone'}
                                    placeholder={'Enter franchise phone'}
                                    required={true}
                                    id='phone'
                                    name={'phone'}
                                    maxLength={10}
                                    onChange={(event) => setFranchiseDetails((prevFormData) => {
                                        return {
                                            ...prevFormData,
                                            phone: event.target.value.slice(0, 10)
                                        }
                                    })}
                                    onBlur={(e) => handleMinLength(e)}
                                    onKeyDown={preventNegativeValues}
                                    value={franchiseDetails?.phone ? franchiseDetails?.phone : ''}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />

                                <div className={Classes.PhoneErrorMsg} id='phoneErrMsgUpdate' >
                                    <p>Please enter valid 10 digit number</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                            <TextField
                                label={'Email'}
                                type='email'
                                placeholder={'Enter franchise email'}
                                defaultValue={franchiseDetails?.email}
                                key={franchiseDetails?.email}
                                required={true}
                                id='email'
                                name={'email'}
                                autoComplete='off'
                                sx={{ width: '32%' }}
                                size="small"
                            />

                            <div style={{width: '32%'}}>
                                <TextField
                                    label={'PAN No.'}
                                    placeholder={'Enter franchise PAN No.'}
                                    required={true}
                                    id='pan'
                                    name={'pan'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    key={franchiseDetails?.pan}
                                    defaultValue={franchiseDetails?.pan}
                                    onBlur={(e) => handleValidPAN(e)}
                                    minLength={10}
                                    maxLength={10}
                                    inputProps={{ maxLength: 10 }}
                                    size="small"
                                />
                                <div id='isValidPANUpdate' className={Classes.IsValidGst}>
                                    <p>Please fill valid PAN Number</p>
                                </div>
                            </div>

                            <div style={{width: '32%'}}>
                                <TextField
                                    label={'CIN No.'}
                                    placeholder={'Enter franchise CIN No.'}
                                    defaultValue={franchiseDetails?.cin}
                                    key={franchiseDetails?.cin}
                                    id='cin'
                                    name={'cin'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                    minLength={21}
                                    maxLength={21}
                                    inputProps={{ maxLength: 21 }}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '32%' }}>
                                <TextField
                                    type="number"
                                    label={'Pincode'}
                                    placeholder={'Enter pincode'}
                                    required={true}
                                    id='pincode'
                                    name={'pincode'}
                                    value={franchiseDetails?.pincode ? franchiseDetails?.pincode : ''}
                                    onBlur={(e) => handleMinLengthPinCode(e)}
                                    onChange={(event) => setFranchiseDetails((prevFormData) => {
                                        return {
                                            ...prevFormData,
                                            pincode: event.target.value.slice(0, 6)
                                        }
                                    })}
                                    onKeyDown={preventNegativeValues}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                />

                                <div className={Classes.PhoneErrorMsg} id='pinErrMsgUpdate' style={{ fontSize: 14 }}>
                                    <p>Please enter a valid 6 digit pincode</p>
                                </div>
                            </div>

                            <Autocomplete
                                // freeSolo
                                required={true}
                                disablePortal
                                id="state"
                                name='state'
                                options={states}
                                onChange={(e, newValue) => {
                                    setSelectedState(newValue);
                                }}
                                key={franchiseDetails?.state}
                                defaultValue={franchiseDetails?.state}
                                ListboxProps={{ sx: { fontSize: 14 } }}
                                size="small"
                                sx={{ width: '32%' }}
                                renderInput={(params) => <TextField {...params} label="States" required />}
                            />

                            <div style={{width:'32%'}}>
                                <TextField
                                    label={'Company Name'}
                                    placeholder={'Enter franchise Company name'}
                                    id='company_name'
                                    name={'company_name'}
                                    autoComplete='off'
                                    size="small"
                                    sx={{ width: '100%' }}
                                    key={franchiseDetails?.company_name}
                                    defaultValue={franchiseDetails?.company_name}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                            <TextField
                                label={'Address'}
                                placeholder={'Enter franchise address'}
                                required={true}
                                id='address'
                                name={'address'}
                                autoComplete='off'
                                sx={{ width: '32%' }}
                                key={franchiseDetails?.address}
                                defaultValue={franchiseDetails?.address}
                                size="small"
                            />

                            <Autocomplete
                                disablePortal
                                id={'city'}
                                name={'city'}
                                required={true}
                                options={cities}
                                disabled={!selectedState ? true: false}
                                key={franchiseDetails?.city}
                                defaultValue={franchiseDetails?.city}
                                sx={{ width: '32%' }}
                                renderInput={(params) =>
                                    <TextField {...params} label={'City'} required={true} />
                                }
                                size="small"
                            />

                            <Autocomplete
                                disablePortal
                                id={'district'}
                                name={'district'}
                                required={true}
                                options={districts}
                                key={franchiseDetails?.district}
                                defaultValue={franchiseDetails?.district}
                                sx={{ width: '32%' }}
                                renderInput={(params) =>
                                    <TextField {...params} label={'District'} required={true} />
                                }
                                size="small"
                            />
                        </div>

                        <div style={{marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id="locations"
                                name="locations"
                                options={locations}
                                sx={{ width: '66%' }}
                                required
                                multiple
                                onChange={(event, newValue) => setLocationCode(newValue)}
                                renderInput={(params) =>
                                    <TextField {...params} label={"Locations"} />
                                }
                                key={franchiseDetails?.locations}
                                defaultValue={franchiseDetails?.locations}
                                size="small"
                            />
                        </div>

                        <div style={{ marginTop: 10 }}>
                            <p>Bank Details</p>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
                                <TextField
                                    label={'A/C Holder Name'}
                                    placeholder={'Enter A/C Holder Name'}
                                    required={true}
                                    id='bank_account_name'
                                    name={'bank_account_name'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    size="small"
                                    defaultValue={franchiseDetails?.bank_account_name}
                                    key={franchiseDetails?.bank_account_name}
                                />

                                <TextField
                                    label={'A/C Number'}
                                    placeholder={'Enter A/C Number'}
                                    required={true}
                                    type='number'
                                    id='bank_account_number'
                                    name={'bank_account_number'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    onKeyDown={preventNegativeValues}
                                    size="small"
                                    defaultValue={franchiseDetails?.bank_account_number}
                                    key={franchiseDetails?.bank_account_number}
                                />

                                <TextField
                                    label={'IFSC Code'}
                                    placeholder={'Enter IFSC Code'}
                                    required={true}
                                    id='bank_account_ifsc'
                                    name={'bank_account_ifsc'}
                                    autoComplete='off'
                                    sx={{ width: '32%' }}
                                    size="small"
                                    defaultValue={franchiseDetails?.bank_account_ifsc}
                                    key={franchiseDetails?.bank_account_ifsc}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '32%' }}>
                                <TextField
                                    label={'Bank Name'}
                                    placeholder={'Enter Bank Name'}
                                    required={true}
                                    id='bank'
                                    name={'bank'}
                                    autoComplete='off'
                                    sx={{ width: '100%' }}
                                    size="small"
                                    defaultValue={franchiseDetails?.bank}
                                    key={franchiseDetails?.bank}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <p>Invoice Details</p>
                            <span style={{fontSize: '12px', fontWeight: 500}}>Example : <i>HM-STO</i> (prefix) / <i>24-25</i> (infix) / XXXX</span>
                            <div style={{ marginTop: 5, display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        width: '49%',
                                    }}
                                >
                                    <TextField
                                        label={'Invoice Prefix'}
                                        placeholder={'Set Invoice Prefix'}
                                        required={true}
                                        id='invoice_prefix'
                                        name={'invoice_prefix'}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        defaultValue={franchiseDetails?.invoice_prefix}
                                        key={franchiseDetails?.invoice_prefix}

                                    />
                                    <div style={{
                                        "fontSize": "small",
                                        "color": "gray",
                                        "textAlign": "center",
                                    }}>
                                        <span><b>STORE-PREFIX</b></span>
                                        <span>/</span>
                                        <span>STORE-INFIX</span>
                                        <span>/</span>
                                        <span>12</span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: '49%',
                                    }}
                                >
                                    <TextField
                                        label={'Invoice Infix'}
                                        placeholder={'Set Invoice Infix'}
                                        required={true}
                                        id='invoice_infix'
                                        name={'invoice_infix'}
                                        autoComplete='off'
                                        sx={{ width: '100%' }}
                                        size="small"
                                        defaultValue={franchiseDetails?.invoice_infix}
                                        key={franchiseDetails?.invoice_infix}
                                    />
                                    <div style={{
                                        "fontSize": "small",
                                        "color": "gray",
                                        "textAlign": "center",
                                    }}>
                                        <span>STORE-PREFIX</span>
                                        <span>/</span>
                                        <span><b>STORE-INFIX</b></span>
                                        <span>/</span>
                                        <span>12</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <p>Franchise Settings</p>
                            <div
                                style={{
                                    marginTop: 15,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <div style={{ flex: "1 1 66%" }}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="skip-qc-step"
                                        name="skip_qc_step"
                                        options={["true", "false"]}
                                        sx={{ width: '100%' }}
                                        required
                                        onChange={(event, newValue) => setSkipQcStep(newValue)}
                                        renderInput={(params) =>
                                            <TextField {...params} label={"Skip Qc Step"} />
                                        }
                                        defaultValue={franchiseDetails?.skipQcStep === true ? "true" : "false"}
                                        size="small"
                                    />
                                </div>

                                <FormControlLabel
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize:'12px' }}}
                                    control={
                                        <Switch 
                                            checked={franchiseDetails?.note_optional || false}  
                                            name="note_optional"
                                            onChange={(event) => setFranchiseDetails((prevFormData) => {
                                                return {
                                                    ...prevFormData,
                                                    note_optional: event.target.checked
                                                }
                                            })}
                                        />
                                    }
                                    label="Note Optional"
                                />
                            </div>

                            <div
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <TextField
                                    label={'Draft Order Prefix'}
                                    type='text'
                                    placeholder={'Enter Draft order prefix'}
                                    defaultValue={franchiseDetails?.draft_order_prefix}
                                    key={franchiseDetails?.draft_order_prefix}
                                    id='draft_order_prefix'
                                    name={'draft_order_prefix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <TextField
                                    label={'Draft Order Suffix'}
                                    type='text'
                                    placeholder={'Enter Draft order suffix'}
                                    defaultValue={franchiseDetails?.draft_order_suffix}
                                    key={franchiseDetails?.draft_order_suffix}
                                    id='draft_order_suffix'
                                    name={'draft_order_suffix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <FormControlLabel
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize:'12px' }}}
                                    control={
                                        <Switch 
                                            checked={franchiseDetails?.refrence_number_optional || false}  
                                            name="refrence_number_optional"
                                            onChange={(event) => setFranchiseDetails((prevFormData) => {
                                                return {
                                                    ...prevFormData,
                                                    refrence_number_optional: event.target.checked
                                                }
                                            })}
                                        />
                                    }
                                    label="Refrence Num.. Optional"
                                />
                            </div>

                            <div 
                                style={{
                                    marginTop: 10,
                                    display: "flex",
                                    gap: "1rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                <TextField
                                    label={'Order Prefix'}
                                    type='text'
                                    placeholder={'Enter order prefix'}
                                    defaultValue={franchiseDetails?.order_prefix}
                                    key={franchiseDetails?.order_prefix}
                                    id='order_prefix'
                                    name={'order_prefix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <TextField
                                    label={'Order Suffix'}
                                    type='text'
                                    placeholder={'Enter order suffix'}
                                    defaultValue={franchiseDetails?.order_suffix}
                                    key={franchiseDetails?.order_suffix}
                                    id='order_suffix'
                                    name={'order_suffix'}
                                    autoComplete='off'
                                    sx={{ flex: "1 1 32.5%" }}
                                    size="small"
                                />

                                <FormControlLabel
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize:'12px' }}}
                                    control={
                                        <Switch 
                                            checked={franchiseDetails?.parchi_made_by_optional || false}  
                                            name="parchi_made_by_optional"
                                            onChange={(event) => setFranchiseDetails((prevFormData) => {
                                                return {
                                                    ...prevFormData,
                                                    parchi_made_by_optional: event.target.checked
                                                }
                                            })}
                                        />
                                    }
                                    label="Parchi Made By Optional"
                                />
                            </div>
                        </div>
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        <Button type='submit' id='updateBtn'>UPDATE</Button>
                    </DialogActions>
                </form>

                {loading ? <Spinner /> : null}
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}

export default UpdateFranchise;