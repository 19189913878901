import { Fragment, useState, forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import ConfirmPopup from '../customers/confirmPopup';
import NewCustomerModal from '../createOrders/aside/newCustomerModal';
import { useSelector } from 'react-redux';
import { services } from '../../../utility/checkRoleBasedPermission';
import { Autocomplete, Switch } from "@mui/material";
import { AccountBalance, Add, House } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import Spinner from '../../../components/spinner';
import Permission from '../../auth/permissions';
import { setCustomers } from '../../../store/customersSlice';
import { CircularProgress} from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import Table from "../../../components/table/table";
import { ImportFileComponent } from '../../../components/importFileComponent';
import SnackBar from '../../../components/SnackBar';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Customers = (props) => {
    const [storeCustomerId, setCustomerId] = useState([]);
    const [showConfirmPopup, setConfirmShowPopup] = useState(false);
    const [gstNO, setGstNO] = useState();
    const [showModal, setShowModal] = useState(false);
    const [num, setNum] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [loading, setLoading] = useState(false);
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [role,setRole] = useState('');
    const [id,setId] = useState('');

    const auth = useSelector((state) => state.auth);
    const userRole = auth?.user?.role;
    const customers = useSelector((state) => state.customers);

    const [openEditDiaglog, setOpenEditDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const [updateCustomer,setUpdateCustomer] = useState(null);
    const [updateCustomerLoading,setUpdateCustomerLoading] = useState(false);
    const [reRender,setReRender] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleCloseEditDialog = () => {
		setOpenEditDialog(false);
        setRole('');
        setId('');
	}

	const handleEdit = (event) => {
        event.preventDefault();
        setLoading(true);
		setOpenEditDialog(false);

        const roles = {
            "Retailer": "retailer",
            "Special Retailer": "special_retailer",
            "Interior": "interior",
            "Oem": "oem",
            "End User": "end_user",
            "Promoter": "promoter",
            "No Role": "remove"
        }

        axiosInstance
            .post(`/user/admin/${id}/markAsAffiliate`, {
                customerRole: roles[role]
            })
            .then((res) => {
                setLoading(false);
                setReRender(!reRender);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            })
	};
    
	const handleEditDialog = (event,role,row) => {
		event.stopPropagation()
		setOpenEditDialog(true);
        setRole(role ? role : '');
        setId(row?.id);
	};

    const onChangeActive = (e,customer) => {
        e.stopPropagation();
        setUpdateCustomer({
            active: customer?.active,
            id: customer?.id,
        })
        setOpen(true);
	};

    const onBlacklistChange = (e, customer) => {
        e.stopPropagation();
        setUpdateCustomer({
            blacklisted: !customer?.blacklisted,
            id: customer?.id,
        })
        setIsBlacklisted(true);
    }

    useEffect(() => {
        if(isBlacklisted ) {
            handleUpdateCustomer();
            setIsBlacklisted(false);
        }
    }, [isBlacklisted]);

    const columnVisibilityModel = {
        createdBy: false,
        customerRole: false
    }

    const CustomerRoleRenderCell = (row) => {
        return row?.customerRole ? capitalName(row?.customerRole) : ''
    }

    const CustomerTypeRenderCell = (row) => {
        return <Chip icon={row?.customerType === "B2C" ? <PersonIcon /> : <StoreIcon />} label={row?.customerType} />
    }

    const ActiveRenderCell = (row) => {
        return  (
            <Switch
                name="active"
                checked={row?.active}
                onClick={(e) => {['super-admin', 'admin'].includes(userRole) && onChangeActive(e, row)}}
                id="flexSwitchCheckChecked"
                inputProps={{ 'aria-label': 'controlled' }}
            />
        )
    }

    const BlacklistedRenderCell = (row) => {
        return  (
            <Permission service={services?.customers} permission={"update"}>
                <Switch
                    name="blacklisted"
                    checked={row?.blacklisted}
                    onClick={(e) => {onBlacklistChange(e, row)}}
                    id="flexSwitchCheckChecked"
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Permission>
        )
    }

    const MakeAsAffiliateRenderCell = (row) => {
        return (
            !(row?.affiliate) ?
                <Permission service={services?.customers} permission={"update"}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'capitalize', fontSize: 14, padding: '2px 10px' }}
                        onClick={(event) => handleEditDialog(event,row?.customerRole,row)}
                    >Affiliate</Button>
                </Permission>
            :
                <div style={{ display:'flex', justifyContent:'center', width:'69%'}}>
                    <HowToRegIcon sx={{color:'green',fontSize:28}}/> 
                </div>
        )
    }
    
    const columns = [
        { 
            field: 'displayName', 
            headerName: 'NAME', 
            width: 200, 
            headerClassName: 'super-app-theme--header', 
            sortable: true, 
            filterable: false 
        },
        { 
            field: 'email', 
            headerName: 'EMAIL', 
            width: 180, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'phone', 
            headerName: 'PHONE', 
            width: 130, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        {
            field: 'customerType',
            headerName: 'TYPE',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: true,
            // flex: 1,
            width: 120,
            renderCell: (props) => ( <CustomerTypeRenderCell {...props?.row} />),
            customRenderCell: CustomerTypeRenderCell,
        },
        { 
            field: 'active', 
            filterable: false, 
            headerName: 'ACTIVE', 
            width: 100, 
            headerClassName: 'super-app-theme--header', 
            sortable: false,
            renderCell: (props) => ( <ActiveRenderCell {...props?.row} />),
            customRenderCell: ActiveRenderCell,
        },
        { 
            field: 'blacklisted', 
            filterable: false, 
            headerName: 'Blacklisted', 
            width: 100, 
            headerClassName: 'super-app-theme--header', 
            sortable: false,
            renderCell: (props) => ( <BlacklistedRenderCell {...props?.row} />),
            customRenderCell: BlacklistedRenderCell,
        },
        {
            field: 'affiliate',
            headerName: 'Affiliate',
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            renderCell: (props) => ( <MakeAsAffiliateRenderCell {...props?.row} />),
            customRenderCell: MakeAsAffiliateRenderCell
        },
        { 
            field: 'createdFrom', 
            headerName: 'FROM', 
            width: 100, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            type: 'enum',
            tableName: 'users',
            columnName: 'created_from',
        },
        { 
            field: 'customerCode', 
            headerName: 'Customer Code', 
            filterable: false, 
            width: 120, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        },
        { 
            field: 'createdBy', 
            filterable: true, 
            headerName: 'CREATED BY', 
            width: 150, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            renderCell: (params) => ( 
                params?.row?.createdBy ? params?.row?.createdBy?.name : ''
            ),
            type: 'enum',
            tableName: 'admins',
            columnName: 'name',
        },
        {
            field: 'customerRole',
            headerName: 'CUSTOMER ROLE', 
            filterable: true, 
            width: 120, 
            headerClassName: 'super-app-theme--header', 
            sortable: true,
            renderCell: (props) => ( <CustomerRoleRenderCell {...props?.row} />),
            customRenderCell: CustomerRoleRenderCell,
            type: 'enum',
            tableName: 'users',
            columnName: 'customer_role'
        },
        { 
            field: 'createdAt', 
            filterable: true, 
            headerName: 'CREATED AT', 
            width: 195, 
            headerClassName: 'super-app-theme--header', 
            sortable: true 
        }
    ];

    const navigate = useNavigate();
    const getModal = () => {
        setShowModal(true);
    };

    const hideModal = () => {
        setNum('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setBusinessName('');
        setGstNO('');
        setErrorMessage('');
        setShowModal(false);
        setSelectedState('');
        setCity('');
        setArea('');
    };

    const handleDeleteCustomer = () => {
        axiosInstance
            .post('/user/admin/deleteCustomersInBulk/', {
                customersIds: storeCustomerId
            })
            .then((res) => {
                const arr = []
                setCustomerId(arr);
                setReRender(!reRender);
            })
    }

    const handleShowPopup = () => {
        setConfirmShowPopup(true)
    }

    const hideConfirmPopup = (event) => {
        if (event.target.innerText === 'DELETE' && storeCustomerId?.length !== 0) {
            handleDeleteCustomer();
        }
        setConfirmShowPopup(false)
    }

    const roles = ["Retailer","Special Retailer","Interior","Oem","End User"];
    
    const capitalName = (str) => {
        var i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    };

    const handleUpdateCustomer = () => {
        setUpdateCustomerLoading(true);
        axiosInstance
            .post(`/user/admin/${updateCustomer?.id}`, {
                ...updateCustomer,
                active: updateCustomer?.active === true ? false: true
            })
            .then((res) => {
                setUpdateCustomerLoading(false);
                setOpen(false);
                setReRender(!reRender);
            })
            .catch((err) => {
                console.log(err,'err')
                const errorMessage = err?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
            .finally(() => {
                setUpdateCustomerLoading(false);
            })
    }

    return (
        <Fragment>
            <div id="color">
                <Dialog open={openEditDiaglog} onClose={handleCloseEditDialog} 
                sx={{'& .MuiDialogContent-root': {height:'352px',width: window?.innerWidth > 600 ? '400px': '80vw',paddingTop:'10px'}}}>
                    <form method="post" onSubmit={handleEdit} id="form">
                        <DialogTitle>Make As Affiliate</DialogTitle>
                        <DialogContent style={{paddingTop:'10px'}}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                id={'role'}
                                name={'role'}
                                options={roles}
                                key={role ? capitalName(role) : ''}
                                defaultValue={role ? capitalName(role) : ''}
                                sx={{width: '100%'}}
                                renderInput={(params) => 
                                    <TextField {...params} label={'Customer Role'} required />
                                }
                                onChange={(event, newValue) => { setRole(newValue)}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditDialog}>Cancel</Button>
                            <Button type='submit'>Submit</Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle style={{padding:'40px 24px'}}>
                        Are you sure {updateCustomer && updateCustomer?.active === true ? 'In-Active' : "Active"}  
                        &nbsp;this customer {updateCustomer && updateCustomer?.displayName} ?
                    </DialogTitle>
                    <DialogContentText>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={updateCustomerLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContentText>
                    <DialogActions sx={{"& .MuiDialogActions-root": {padding: "8px 20px"}}}>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleUpdateCustomer}>Agree</Button>
                    </DialogActions>
                </Dialog>
                
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "10px", margin: '-25px 10px 10px 0px', flexWrap:'wrap' }} >
                    <div>
                        <Button
                            size='small'
                            style={{ textTransform: 'capitalize' }}
                            onClick={() => navigate('/ledgerDashboard')}
                            startIcon={<AccountBalance />}
                            variant="contained"
                            sx={{ "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "15px", marginTop: "-3px" } }}
                        >Ledger Dashboard</Button>
                    </div>
                    <div>
                        <Button
                            size='small'
                            style={{ textTransform: 'capitalize' }}
                            onClick={() => navigate('/customerAddresses')}
                            startIcon={<House />}
                            variant="contained"
                            sx={{ "& .MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "15px", marginTop: "-3px" } }}
                        >Addresses</Button>
                    </div>

                    <Permission service={services?.customers} permission="delete">
                        <div style={{}}>
                            <Button
                                type="submit"
                                id='deleteBtn'
                                disabled={storeCustomerId.length === 0 ? true : false}
                                onClick={handleShowPopup}
                                variant="contained"
                                color="error"
                                size='small'
                                style={{textTransform:'capitalize'}}
                                sx={{"& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px"}}}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        </div>
                    </Permission>

                        <Permission service={services?.customers} permission="create">
                            <div style={{}}>
                                <Button 
                                    id='createbtn' 
                                    size='small' 
                                    style={{textTransform:'capitalize'}} 
                                    startIcon={<Add />} 
                                    onClick={getModal} 
                                    variant="contained"
                                    sx={{"& .MuiButton-startIcon>*:nth-of-type(1)":{fontSize:"15px",marginTop:"-3px"}}}
                                >Create</Button>
                            </div>
                        </Permission>

                        <div>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => navigate('/customers/fetchCustomerUpdateHistory')}
                                style={{ marginLeft: '10px' }}
                                size='small'
                            >
                                Customer's Update History
                            </Button>
                        </div>
                            
                        <div style={{marginBottom:'3px'}}>
                            <p>Sales User / Pre Sales User Update via CSV will override existing values</p>
                            <ImportFileComponent
                                endpoint="/user/admin/updateCustomersInBulk"
                                serviceName={services?.customers}
                                type="customers"
                                templateDownloadEndpoint={"/templates/customerCsv"}
                                setReRender={setReRender}
                            />
                        </div>
                </div>
                
                <div className={Classes.Container} style={{ width: '99%' }} >
                    <Table
                        columns={columns}
                        dataPosition={"users"}
                        endpoint={`/user/admin`}
                        rowId={'id'}
                        checkBoxSelection={true}
                        version="version-1"
                        navigateOnRowClickEndpoint={`/customers`}
                        reRender={reRender}
                        service={'customers'}
                        tabName={'Customers'}
                        setData={setCustomers}
                        data={customers}
                        columnVisibilityModel={columnVisibilityModel}
                        redirectUrlOnSnackBarClick={'/dashboard/reportAnalytics'}
                        setSelectedId={setCustomerId}
                        mobileResponsive={true}
                        exportEndpoint={'/exports/customers'}
                        exportFileName={`Customers - ${new Date()}`}
                        searchKey={'text'}
                        clickable={true}
                    />

                    <div className={showConfirmPopup ? Classes.UpdateSalesDialog: undefined}>
                        <ConfirmPopup
                            showConfirmPopup={showConfirmPopup}
                            hideConfirmPopup={hideConfirmPopup}
                            storeCustomerId={storeCustomerId}
                        />
                    </div>
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewCustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    // popUpType='customerDashboard'
                    endpoint={`/user/admin`}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    setCity={setCity}
                    city={city}
                    setArea={setArea}
                    area={area}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
            {loading && <Spinner />}
        </Fragment>
    );
};

export default Customers;
