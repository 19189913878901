import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../components/SnackBar';
import axiosInstance from '../../../utility/axios-instance';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {  TextField } from "@mui/material";
import Fab from '@mui/material/Fab';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { downloadCSV } from "../../../utility/downloadCSV";
import { SimpleTreeView } from '@mui/x-tree-view';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const QrCodesDownloadPopup = (props) => {
    const { open, handleClose, header, buttonText, endPoint, importFile } = props;
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [alignment, setAlignment] = useState('brands');
    const [brands,setBrands] = useState([]);
    const [selectedBrands,setSelectedBrands] = useState([]);
    const [collections,setCollections] = useState([]);
    const [selectedL3s,setSelectedL3s] = useState([]);
    const [selectedL2s,setSelectedL2s] = useState([]);
    const [selectedL1s,setSelectedL1s] = useState([])
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [csv,setCSV] = useState(null)

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };
    
    const handleSelect = (event, nodeIds) => {
        setSelected(nodeIds);
    };

    useEffect(() => {
        axiosInstance
            .get('/others/adminBrands')
            .then((res) => {
                setBrands(res.data.data)
            })
	},[]);

    useEffect(() => {
        axiosInstance
            .get('/others/getAll-l1s')
            .then((res) => {
                setCollections(res.data.data)
            })
    },[])

    const handleChange = (event) => {
        setAlignment(event.target.value);
    };

    const onButtonClick = () => {
        setLoading(true);
        const url = alignment === 'brands' ? `brandQRCodePdf?brands=${JSON.stringify(selectedBrands)}` : 
        alignment === 'l1s' ? `l1CategoriesQRCodePdf?l1s=${JSON.stringify(selectedL1s)}` :
        alignment === 'l2s' ? `l2CategoriesQRCodePdf?l2s=${JSON.stringify(selectedL2s)}`:
        `categoriesQRCodePdf?l3s=${JSON.stringify(selectedL3s)}`

        axiosInstance
            .get(`/pdf/${url}&type=${endPoint ? endPoint : ''}`,{
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                },
            })
            .then((response) => {
                setLoading(false);
                const blob = response.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `${alignment === 'brands' ? 'brands' : alignment === 'l1s' ? 'l1s': alignment === 'l2s' ? 'l2s':'l3s'}_${new Date()}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err, 'err');
            })
            .finally(() => {
                setLoading(false);
                setSelectedBrands([]);
                setSelectedL3s([]);
                handleClose();
            });
    };

    const submit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("sku", csv);
        setLoading(true);
  
        axiosInstance.post('/pdf/skuToCatalogue', formData, {
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                },
            }).then(res => {
                setLoading(false);
                const blob = res.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `SkuToCatalogue.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                setCSV(null)
            }).catch(err => {
                console.log(err);
                setCSV(null)
            //   handleError(err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
                setSelectedBrands([]);
                setSelectedL3s([]);
                setCSV(null);
                handleClose();
            });
    } 

    
    const handleTemplateDownload = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(`/templates/skuToQrcodeBarcodeCsv`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
	            downloadCSV(arrayOfRowObjects,`SKU template - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `SKU template downloaded successfully`, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: error?.response?.data?.message, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const checkDuplicateIds = (collections) => {
        const idMap = new Map();
        const duplicates = [];
    
        collections.forEach((l1) => {
            if (idMap.has(l1.l1_id)) {
                duplicates.push({ id: l1.l1_id, level: "L1" });
            } else {
                idMap.set(l1.l1_id, true);
            }
    
            l1.l2s?.forEach((l2) => {
                if (idMap.has(l2.l2_id)) {
                    duplicates.push({ id: l2.l2_id, level: "L2" });
                } else {
                    idMap.set(l2.l2_id, true);
                }
    
                l2.l3s?.forEach((l3) => {
                    if (idMap.has(l3.l3_id)) {
                        duplicates.push({ id: l3.l3_id, level: "L3" });
                    } else {
                        idMap.set(l3.l3_id, true);
                    }
                });
            });
        });
    
        return duplicates;
    };
    
    const duplicateIds = checkDuplicateIds(collections);
    
    if (duplicateIds.length > 0) {
        console.error("🚨 Duplicate IDs found:", duplicateIds);
    } else {
        console.log("✅ No duplicate IDs found!");
    }
    

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>{header}</DialogTitle>
                <DialogContent>
                    <div style={{ backgroundColor: '#9e9e9e1f', borderRadius: 4,padding: 4 }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="pdfType"
                            sx={{
                                
                                width:'100%',
                                "& .MuiToggleButtonGroup-root": {width:'100%'},
                                "& .MuiToggleButtonGroup-grouped": { border: '0px', color: 'black', textTransform: 'capitalize', padding: '2px 11px' },
                                "& .MuiToggleButton-root.Mui-selected": { backgroundColor: '#fff' }
                            }}
                        >
                            <ToggleButton value="brands" style={{padding:"4px 10px",height:34, width:'33%',textAlign:'center'}}>Brands</ToggleButton>
                            <ToggleButton value="l1s" style={{padding:"4px 10px",height:34,width:'33%',textAlign:'center'}}>L1s</ToggleButton>
                            <ToggleButton value="l2s" style={{padding:"4px 10px",height:34,width:'33%',textAlign:'center'}}>L2s</ToggleButton>
                            <ToggleButton value="l3s" style={{padding:"4px 10px",height:34,width:'33%',textAlign:'center'}}>L3s</ToggleButton>
                            {importFile && (
                                <ToggleButton value="importFile" style={{padding:"4px 10px",height:34,width:'33%',textAlign:'center'}}>Import</ToggleButton>
                            )}
                        </ToggleButtonGroup>
                    </div>

                    {alignment === 'brands' && (
                        <FormGroup sx={{ 
                            overflowY:"auto", height:"60vh", padding:"10px 20px", 
                            marginTop:"5px",flexWrap:'nowrap'
                        }}>
                            {brands?.length !== 0 && brands?.map((brand,index) => (
                                <FormControlLabel
                                    key={index}
                                    sx={{"& .MuiCheckbox-root": {padding:"3px"}}} 
                                    control={
                                    <Checkbox 
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelectedBrands([...selectedBrands, brand?.title]);
                                            } else {
                                                setSelectedBrands(
                                                    selectedBrands.filter((b) => b !== brand?.title)
                                                );
                                            }
                                        }}
                                        value={brand?.title}
                                        checked={selectedBrands.includes(brand?.title)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} 
                                    label={brand?.title} 
                                />
                            ))}
                        </FormGroup>
                    )}

                    {alignment === 'l1s' && (
                        <FormGroup sx={{ 
                            overflowY:"auto", height:"60vh", padding:"10px 20px", 
                            marginTop:"5px",flexWrap:'nowrap'
                        }}>
                            {collections?.length !== 0 && collections?.map((l1) =>  (
                                <FormControlLabel
                                    key={l1?.l1_id}
                                    sx={{"& .MuiCheckbox-root": {padding:"3px"}}} 
                                    control={
                                    <Checkbox 
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                setSelectedL1s([...selectedL1s, l1?.l1_id]);
                                            } else {
                                                setSelectedL1s(
                                                    selectedL1s?.filter((b) => b !== l1?.l1_id)
                                                );
                                            }
                                        }}
                                        value={l1?.l1_id}
                                        checked={selectedL1s?.includes(l1?.l1_id)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} 
                                    label={l1?.title} 
                                />
                            ))}
                        </FormGroup>
                    )}


                    {alignment === 'l2s' && (
                        <SimpleTreeView
                            aria-label="controlled"
                            slots={{collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon}}
                            expandedItems={expanded}
                            selectedItems={selected}
                            onExpandedItemsChange={handleToggle}
                            onSelectedItemsChange={handleSelect}
                            multiSelect
                            sx={{ 
                                overflowY:"auto", height:"60vh", padding:"10px", 
                                marginTop: "5px",flexWrap:'nowrap'
                            }}
                        >
                            {collections?.length !== 0 && collections?.map((l1) =>  (
                                <TreeItem nodeId={l1?.l1_id} label={l1?.title} key={l1?.l1_id}>
                                    {l1?.l2s?.length !== 0 && l1?.l2s?.map((l2) => (
                                        <div style={{paddingLeft:22}} key={l2?.l2_id}>
                                            <FormControlLabel
                                                sx={{"& .MuiCheckbox-root": {padding:"3px"}}} 
                                                control={
                                                    <Checkbox 
                                                        onChange={(event) => {
                                                            if (event.target.checked) {
                                                                setSelectedL2s([...selectedL2s, l2?.l2_id]);
                                                            } else {
                                                                setSelectedL2s(
                                                                    selectedL2s.filter((l) => l !== l2?.l2_id)
                                                                );
                                                            }
                                                        }}
                                                        checked={selectedL2s.includes(l2?.l2_id)}
                                                        value={l2?.l2_id}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } 
                                                label={l2?.title} 
                                            />
                                        </div>
                                    ))}
                                </TreeItem>
                            ))}
                        </SimpleTreeView>
                    )}

                    {alignment === 'l3s' && (
                        <SimpleTreeView
                            aria-label="controlled"
                            slots={{collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon}}
                            expandedItems={expanded}
                            selectedItems={selected}
                            onExpandedItemsChange={handleToggle}
                            onSelectedItemsChange={handleSelect}
                            multiSelect
                            sx={{ 
                                overflowY: "auto", height: "60vh", padding: "10px", 
                                marginTop: "5px", flexWrap: 'nowrap'
                            }}
                        >
                            {collections?.length > 0 && collections?.map((l1) => l1?.l1_id ? ( // Ensure l1_id exists
                                    <TreeItem nodeId={l1.l1_id} label={l1.title} key={l1.l1_id}>
                                        {l1?.l2s?.length > 0 && l1?.l2s?.map((l2) => 
                                            l2?.l2_id ? ( // Ensure l2_id exists
                                                <TreeItem nodeId={l2.l2_id} label={l2.title} key={l2.l2_id}>
                                                    {l2?.l3s?.length > 0 && l2?.l3s?.map((l3, index) => 
                                                        l3?.l3_id ? ( // Ensure l3_id exists
                                                            <div style={{ paddingLeft: 22 }} key={l3.l3_id}>
                                                                <FormControlLabel
                                                                    sx={{ "& .MuiCheckbox-root": { padding: "3px" } }} 
                                                                    control={
                                                                        <Checkbox 
                                                                            onChange={(event) => {
                                                                                if (event.target.checked) {
                                                                                    setSelectedL3s([...selectedL3s, l3.l3_id]);
                                                                                } else {
                                                                                    setSelectedL3s(
                                                                                        selectedL3s.filter((l) => l !== l3.l3_id)
                                                                                    );
                                                                                }
                                                                            }}
                                                                            checked={selectedL3s.includes(l3.l3_id)}
                                                                            value={l3.l3_id}
                                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                                        />
                                                                    } 
                                                                    label={l3.title} 
                                                                />
                                                            </div>
                                                        ) : null
                                                    )}
                                                </TreeItem>
                                            ) : null
                                        )}
                                    </TreeItem>
                                ) : null
                            )}
                        </SimpleTreeView>
                    )}


                    {alignment === 'importFile' && (
                        <div style={{
                            overflowY:"auto", height:"60vh", padding:"10px 0px", marginTop:15,flexWrap:'nowrap'
                        }}>
                            <form onSubmit={submit} style={{ display:'flex',alignItems: 'stretch',gap: '1rem' }}>
                                <TextField 
                                    id='csv'
                                    required
                                    name='csv'
                                    type='file'
                                    // label='Upload CSV'
                                    inputProps={{ 'accept': "file/csv" }}
                                    sx={{'& .MuiOutlinedInput-input': {padding:'5px 14px',height:'inherit'}}}
                                    autoComplete='off'
                                    onChange={(e) => setCSV(e.target.files[0])} 
                                />

                                <Button variant="contained" type='submit'>
                                    Submit
                                </Button>

                                <Fab color="primary" size="small" onClick={handleTemplateDownload}>
                                    <FileOpenIcon />
                                </Fab>
                            </form>
                        </div>
                    )}
                </DialogContent>
                {!(importFile && alignment === 'importFile') && (
                    <DialogActions style={{padding: "12px 20px"}}>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={onButtonClick} variant="contained" color="primary">
                            Generate {alignment === 'brands' ? "Brands": alignment === 'l1s'?
                            'L1s' : alignment === 'l2s'? 'L2s': 'L3s' } {buttonText}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}

export default QrCodesDownloadPopup;