import { Fragment, useState, useEffect } from 'react';
import Classes from "../createOrders/css/Modal.module.css";
import axiosInstance from '../../../utility/axios-instance';
import Spinner from '../../../components/spinner';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import '../createOrders/css/styles.css';

const CustomerUpdateModal = (props)=> {
    const [loading,setLoading] = useState(false);


    const { 
        errorMessage,
        setErrorMessage,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        setBusinessName,
        fetchCustomers,
        area,
        setArea,
        email,
        setEmail,
        updateCustomerModal,
        hideContactPop,
        editCustomer,
        setDisplayName,
        phoneNo,
        setPhoneNo,
        states,
        setStates,
        cities,
        setCities,
        areas,
        setAreas,
        selectedState,
        setSelectedState,
        selectedCity,
        setSelectedCity,
        selectedArea,
        setSelectedArea,
        selectedCityName,
        setSelectedCityName,
        selectedStateName,
        setSelectedStateName,
        setReRender,
        reRender
    } = props;

    useEffect(() => {
        axiosInstance
            .get(`/user/admin/${editCustomer}`)
            .then((res) => {
                setFirstName(res.data.data.firstName);
                if (res.data.data.lastName) {
                    setLastName(res.data.data.lastName)
                }

                setDisplayName(res.data.data.displayName);
                setPhoneNo(res.data.data.phone);
                if (res.data.data.email) {
                    setEmail(res.data.data.email)
                }

                if (res.data.data.businessName) {
                    setBusinessName(res.data.data.businessName);
                }

                if (res.data.data.state) {
                    setSelectedStateName(res.data.data.state)
                }

                if (res.data.data.city) {
                    setSelectedCityName(res.data.data.city)
                }

                if (res.data.data.area) {
                    setSelectedArea(res.data.data.area)
                }

                axiosInstance
                    .get('/pincode/getAllStates')
                    .then((response) => {
                        setStates(response.data.data);
                    })
        })
    },[editCustomer]);

    useEffect(()=> {
        if (selectedState) {
            axiosInstance
            .get(`/pincode/getAllCities?state=${selectedState}`)
            .then((response) => {
                setCities(response.data.data);
            })
        }
    },[selectedState]);

    // AREA DEPRECATED
    // useEffect(()=> {
    //     if (selectedCity) {
    //         axiosInstance
    //         .get(`/others/areas/${selectedCity}`)
    //         .then((response) => {
    //             const areas = [];
    //             for (let areaName of response.data.data) {
    //                 areas.push({id:areaName?._id,label:areaName?.area})
    //             }
    //             setAreas(areas);
    //         })
    //     }
    // },[selectedCity]);

    const handleSubmit = (event)=> {
        event.preventDefault();
        setLoading(true);
        const button = document.getElementById('btn')
        button.innerHTML = 'Updating Customer..';
        console.log(event.target)


        const data = {
            email: event.target.email.value,
            phone:`+91${event.target.phone.value}`,
            firstName: event.target.firstName.value,
            lastName: event.target.lastName.value ? event.target.lastName.value : undefined,
            state: selectedState ? selectedState : undefined,
            city: selectedCity ? selectedCity : undefined,
            area: event.target.area.value ? event.target.area.value : undefined
        }

        axiosInstance
            .post(`/user/admin/${editCustomer}`,{...data})
            .then((res) =>{
                const form = document.getElementById('form');
                form.reset();
                hideContactPop();
                fetchCustomers();
                setLoading(false);
                setErrorMessage('');
                setEmail('');
                setArea('');
                setSelectedState('');
                setFirstName('');
                setLastName('');
                setPhoneNo('');
                setSelectedCity('');
                button.innerHTML = 'Customer Updated';
            }).catch((err) => {
                button.innerHTML = 'Update Customer';
                setLoading(false);
            })
            if (reRender && setReRender) {
                setReRender(!reRender)
            }
    }

    return (
        <Fragment>
            {updateCustomerModal && (
                <div 
                    className={Classes.modal} 
                    style={{top:'8%',bottom:'11%'}}
                >
                    <div className={Classes.BoxContent}>
                        <p>Edit Customer Information</p>
                    </div>
                    
                    <form 
                        method="post" 
                        onSubmit={handleSubmit} 
                        id='form'
                    >
                        <div>
                            {errorMessage && (
                                <p className={Classes.ErrorMessage}
                                >&#x2022; {errorMessage}</p>
                            )}
                        </div>
                        
                        <div style={{padding:20}}>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div style={{ width:'49%' }}>
                                    <TextField
                                        required
                                        id='firstName'
                                        name="firstName"
                                        label="First Name"
                                        autoComplete='off'
                                        key={firstName ? firstName : ''}
                                        defaultValue={firstName ? firstName : ''}
                                        sx={{ width: '100%',"& label": {top: firstName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{ width:'49%'}}>
                                    <TextField
                                        id='lastName'
                                        name="lastName"
                                        label="Last Name"
                                        autoComplete='off'
                                        key={lastName ? lastName : ''}
                                        defaultValue={lastName ? lastName :''}
                                        sx={{ width: '100%',"& label": {top: lastName ? "0%": "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{ width:'49%' }}>
                                    <TextField 
                                        required
                                        id='phoneNumber'
                                        name="phone"
                                        type="text"
                                        label="Phone Number"
                                        key={phoneNo ? phoneNo : ''}
                                        defaultValue={phoneNo ? phoneNo : ''}
                                        disabled={true}
                                        autoComplete='off'
                                        sx={{ width: '100%',"& label": {top: phoneNo ? "0%" : "-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>

                                <div style={{width:'49%'}}>
                                    <TextField 
                                        required
                                        id='email'
                                        name="email"
                                        label="Email"
                                        autoComplete='off'
                                        key={email ? email : ''}
                                        defaultValue={email ? email : ''}
                                        sx={{ width: '100%',"& label": {top: editCustomer?.email ? "0%":"-16%",fontSize:14},"& .MuiOutlinedInput-input": {padding:"7.5px 14px" }}}
                                    />
                                </div>
                            </div>


                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{width:'49%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="state"
                                        name="state"
                                        options={states}
                                        key={selectedStateName ? selectedStateName : ''}
                                        defaultValue={selectedStateName ? selectedStateName : ''}
                                        sx={{
                                            width: '100%',
                                            "& label": {top: selectedState ? "0%": "-16%",fontSize:14},
                                            "& .MuiInputBase-input": {height:'12px'},
                                            "& .MuiOutlinedInput-root":{padding:'6px'},
                                        }}
                                        onChange={(event, newValue) => {
                                            setSelectedState(newValue.id);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="States"/>}
                                    />
                                </div>

                                <div style={{width:'49%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="city"
                                        name='city'
                                        options={cities}
                                        key={selectedCityName ? selectedCityName : ''}
                                        defaultValue={selectedCityName ? selectedCityName : ''}
                                        disabled={!selectedState ? true: false}
                                        sx={{width: '100%',"& label": {top: selectedCity ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onChange={(event, newValue) => {
                                            setSelectedCity(newValue.id ? newValue.id : event.target.value);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="City"/>}
                                    />
                                </div>
                            </div>

                            <div className={Classes.ContentBox} style={{marginTop:25}}>
                                <div style={{width:'49%'}}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        id="area"
                                        name='area'
                                        options={areas}
                                        key={selectedArea ? selectedArea : ''}
                                        defaultValue={selectedArea ? selectedArea : ''}
                                        disabled={!selectedCity ? true: false}
                                        sx={{width: '100%',"& label": {top: area ? "0%": "-16%",fontSize:14},"& .MuiInputBase-input": {height:'12px'},"& .MuiOutlinedInput-root":{padding:'6px'}}}
                                        onBlur={(event, newValue) => {setArea(newValue ? newValue.id : event.target.value )}}
                                        renderInput={(params) => <TextField {...params} label="Area"/>}
                                    />
                                </div>
                            </div>
                        </div>


                        <div 
                            className={ Classes.SaveCustomerBox} 
                            style={{
                                justifyContent:'flex-end',
                                bottom:'11%',
                                position:'fixed',
                                borderTop:0,
                                boxShadow:'none'
                            }}
                        >
                            <button 
                                type="button" 
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideContactPop}
                            >
                                CANCEL
                            </button>

                            <button 
                                id='btn' 
                                type="submit" 
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                UPDATE
                            </button>
                        </div>
                        
                    </form>
                    
                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default CustomerUpdateModal;