import { ExternalLink, Upload } from "lucide-react";
import styles from "./settingsPanel.module.css";
import SnackBar from "../SnackBar";
import React from "react";
import { ToggleButton, Tooltip } from "@mui/material";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import InvertColorsOffIcon from "@mui/icons-material/InvertColorsOff";
import { downloadCSV } from "../../utility/downloadCSV";
import axiosInstance from "../../utility/axios-instance";
import { toTitleCase } from "../../utility/textFormatting";

export function SettingsPanel({handleUpload}) {
  const isWaterMarkControl = true;
  const [isWaterMarkToBeApplied, setIsWaterMarkToBeApplied] =
    React.useState(true);
  const [snackBar, setSnackBar] = React.useState({
    display: false,
    type: "success",
    message: "default message!",
  });

  const [selectedImageType, setSelectedImageType] = React.useState("products");

  const handleImageTypeChange = (event) => {
    setSelectedImageType(event.target.value);
  };

  return (
    <div className={styles.settingsPanel}>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      <h3 style={{marginBottom: "1.5rem", fontSize: "large", marginTop: "0.5rem"}}>Settings</h3>
      <div className={styles.setting}>
        <select
          id="imageType"
          className={styles.select}
          value={selectedImageType}
          onChange={handleImageTypeChange}
        >
          <option value="products">Products</option>
          {/* <option value="brands">Brands</option>
          <option value="l1">L1</option>
          <option value="l2">L2</option>
          <option value="l3">L3</option> */}
        </select>
        {/* {isWaterMarkControl && (
          <div>
            <Tooltip title="Watermark">
              <ToggleButton
                value="true"
                selected={isWaterMarkToBeApplied}
                onChange={() => {
                  setIsWaterMarkToBeApplied((prevIsWaterMarkToBeApplied) => {
                    return !prevIsWaterMarkToBeApplied;
                  });
                  if (isWaterMarkToBeApplied) {
                    setSnackBar((prevSnackBar) => {
                      return {
                        ...prevSnackBar,
                        display: true,
                        message: "Watermark Disabled",
                        type: "error",
                      };
                    });
                  } else {
                    setSnackBar((prevSnackBar) => {
                      return {
                        ...prevSnackBar,
                        display: true,
                        message: "Watermark Enabled",
                        type: "success",
                      };
                    });
                  }
                }}
                style={{ padding: "10px", border: "none", outline: "none" }} // Reduce the size of the button
              >
                {isWaterMarkToBeApplied ? (
                  <WaterDropIcon fontSize="small" /> // Reduce the size of the icon
                ) : (
                  <InvertColorsOffIcon fontSize="small" /> // Reduce the size of the icon
                )}
              </ToggleButton>
            </Tooltip>
          </div>
        )} */}
      </div>

      <div className={styles.buttons}>
        <button className={styles.uploadButton} onClick={handleUpload}>
          <Upload size={16} />
          Upload Images
        </button>
        <a 
          href={"https://depo24-images.s3.ap-south-1.amazonaws.com/assets/Upload+Template.jpg"} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <button className={styles.downloadButton}>
            <ExternalLink size={16}/>
            View Template
          </button>
        </a>
      </div>
    </div>
  );
}
