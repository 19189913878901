import { useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListIcon from '@mui/icons-material/List';
import Permission from '../../auth/permissions';
import axiosInstance from '../../../utility/axios-instance';
import SnackBar from '../../../components/SnackBar';
import Spinner from '../../../components/spinner';
import { services } from '../../../utility/checkRoleBasedPermission';
import { Fab } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { downloadCSV } from '../../../utility/downloadCSV';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SettingsPopup({
    open, handleClose, handleUpdateIndexSortKey,
    dashboard, routes, setReRender, reRender
}) {
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const handleImport = (event) => {
        setLoading(true);
        event.preventDefault();
        var formData = new FormData();
        formData.append("products", document.getElementById('importInput').files[0]);
        document.getElementById("importForm").reset();

        axiosInstance
            .post('/product/createProduct', formData)
            .then(result => {
                setLoading(false);
                if (result.data.alreadyExistSku.length !== 0) {
                    const successMessage = "File imported succesfully!"
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                } else if (result.data.mandatoryFieldSku.length !== 0) {
                    const arr = ["sku", "groupName", "productName", "mrp", "listingPrice", "listingDiscount", "gst", "variable1Type", "variable1Value"]
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: `Please must fill these fields ${arr?.map((field) => field)} for creating product`, type: "error" }
                    });
                } else if (result.data.fieldName.fieldName.length !== 0) {
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: 'Please fill valid', type: "error" }
                    });
                } else {
                    const successMessage = "File imported succesfully!"
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                }
                handleClose();
                setReRender(!reRender);
            })
            .catch(error => {
                setLoading(false);
                console.log('error', error)
                const errorMessage = error?.response?.data?.message;
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleUpdate = (event) => {
        setLoading(true);
        event.preventDefault();
        var formData = new FormData();
        formData.append("products", document.getElementById('updateInput').files[0]);
        document.getElementById("updateForm").reset();

        axiosInstance
            .post('/product/updateProduct', formData)
            .then(result => {
                setLoading(false);
                if (result.data.status === 'Server Busy') {
                    alert('Server Busy.. Try Again Later');
                    event.target.innerHTML = 'Update Product';
                }
                if (result.data.notExistSku.length !== 0) {
                    const successMessage = "File imported succesfully!"
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                } else if (result.data.mandatoryFieldSku.length !== 0) {
                    let text;
                    if (result.data.mandatoryFieldSku.length === 1) {
                        text = 'this'
                    } else {
                        text = 'these'
                    }
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: `Please must fill sku for updating ${text} product`, type: "error" }
                    });
                } else if (result.data.fieldName.fieldName.length !== 0) {
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: `Please fill valid`, type: "error" }
                    });
                } else {
                    const successMessage = "File updated succesfully!"
                    setSnackBar((prevSnackBar) => {
                        return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                    });
                }
                handleClose();
                setReRender(!reRender);
            })
            .catch(error => {
                setLoading(false);
                console.log('error', error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleDelete = (event) => {
        setLoading(true);
        event.preventDefault();
        var formData = new FormData();
        formData.append("products", document.getElementById('deleteInput').files[0]);
        document.getElementById("deleteForm").reset();

        axiosInstance
            .post('/product/deleteViaCsv', formData)
            .then(result => {
                setLoading(false);
                if (result.data.status === 'Server Busy') {
                    alert('Server Busy.. Try Again Later');
                }
                const successMessage = "File updated succesfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                handleClose();
                setReRender(!reRender);
            })
            .catch(error => {
                setLoading(false);
                console.log('error', error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
            })
    }

    const handleTemplateDownloadToDeleteProducts = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(routes ? routes : `/templates/productBulkDeleteCsv`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
                downloadCSV(arrayOfRowObjects, `Product BULK template - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `Product BULK template downloaded successfully`, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: error?.response?.data?.message, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleTemplateDownload = (event) => {
        event.preventDefault();
        setLoading(true);

        axiosInstance
            .get(routes ? routes : `/templates/productBulkUploadCsv`)
            .then((result) => {
                const arrayOfRowObjects = result?.data?.data
                downloadCSV(arrayOfRowObjects, `Product BULK template - ${new Date()}`);
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: `Product BULK template downloaded successfully`, type: "success" }
                });
            })
            .catch((error) => {
                console.log(error)
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: error?.response?.data?.message, type: "error" }
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            // aria-describedby="alert-dialog-slide-description"
            >
                {loading && <Spinner />}
                <DialogTitle>Settings Menu</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Here you can {dashboard === 'Products' && "Import /"} Update Products from CSV file {dashboard === 'Products' && "and Update Inventory and Index"} in Database
                    </DialogContentText>
                </DialogContent>

                {dashboard === 'Products' && (
                    <DialogContent>
                        <Permission service={services?.products} permission="update">
                            <div>
                                <form className="d-flex import" id="importForm"
                                    onSubmit={handleImport}
                                >
                                    <div className="input-group">
                                        <input
                                            required
                                            type="file"
                                            id="importInput"
                                            name="products"
                                            accept=".csv"
                                            className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                        ></input>
    
                                        <Button
                                            id='import'
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                        >
                                            Import
                                        </Button>
                                    </div>
                                    <Fab style={{marginLeft:5}} color="primary" size="small" onClick={handleTemplateDownload}>
                                        <FileOpenIcon />
                                    </Fab>
                                </form>
                            </div>
                        </Permission>
                    </DialogContent>
                )}

                <DialogContent>
                    <Permission service={services?.products} permission="update">
                        <div>
                            <form className="d-flex import" id="updateForm"
                                onSubmit={handleUpdate}
                            >
                                <div className="input-group">
                                    <input
                                        required
                                        type="file"
                                        id="updateInput"
                                        name="products"
                                        accept=".csv"
                                        className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
                                    ></input>

                                    <Button
                                        id='export'
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                    >
                                        Update
                                    </Button>
                                </div>
                                <Fab style={{marginLeft:5}} color="primary" size="small" onClick={handleTemplateDownload}>
                                    <FileOpenIcon />
                                </Fab>
                            </form>
                        </div>
                    </Permission>
                </DialogContent>

                <DialogContent>
                    <Permission service={services?.products} permission="delete">
                        <div>
                            <form className="d-flex import" id="deleteForm"
                                onSubmit={handleDelete}
                            >
                                <div className="input-group">
                                    <input
                                        required
                                        type="file"
                                        id="deleteInput"
                                        name="products"
                                        accept=".csv"
                                        className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Delete"
                                    ></input>

                                    <Button
                                        id='export'
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                    >
                                        Delete
                                    </Button>
                                </div>
                                <Fab style={{marginLeft:5}} color="primary" size="small" onClick={handleTemplateDownloadToDeleteProducts}>
                                    <FileOpenIcon />
                                </Fab>
                            </form>
                        </div>
                    </Permission>
                </DialogContent>

                {dashboard === 'Products' && ( 
                    <DialogContent>
                        <Permission service={services?.products} permission="update">
                            <div>
                                <Button
                                    id='export'
                                    variant='contained'
                                    color='primary'
                                    onClick={handleUpdateIndexSortKey}
                                    startIcon={<ListIcon />}

                                >
                                    Update Index
                                </Button>
                            </div>
                        </Permission>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        </div>
    );
}