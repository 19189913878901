import React, { useEffect, useState } from 'react'
import Classes from './css/addProduct.module.css';
import { FormControlLabel, IconButton, Switch, TextField } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ClearIcon from '@mui/icons-material/Clear';
import InfinityScrollAutoComplete from '../../../../components/infinityScrollAutocomplete';

export default function ChildProductsCollections(props) {
    const {setIsParentProduct, isVirtualProduct, setIsVirtualProduct, action, payloadToUpload, setPayloadToUpload} = props;

    const addChildProductSection = (e) => {
        setIsParentProduct(true);

        //check Any dialogBox of child is have Empty field
        const isAnyFieldEmpty = payloadToUpload?.childProducts?.some(childProduct => !(childProduct?.childProductSku || childProduct?.childProductQuantity));
        if(!isAnyFieldEmpty){
            setPayloadToUpload((prevPayloadToUpload) => {
              return {
                ...prevPayloadToUpload,
                childProducts: [
                  ...(prevPayloadToUpload?.childProducts || []),
                  {
                    id: prevPayloadToUpload?.childProducts?.length || 0,
                    childProductSku: null,
                    childProductQuantity: null,
                    mrp: null,
                  },
                ],
              };
            });
        }
	}

    const handleRemove = (index) => () => {
        // maintain min 1 child product
        if(action === 'update' && payloadToUpload?.childProducts?.length === 1){
            return;
        }
        setPayloadToUpload((prevPayloadToUpload) => {
            const filteredChildProducts =
              prevPayloadToUpload?.childProducts?.filter(
                (childProduct) => childProduct?.id !== index
              );
            return {
              ...prevPayloadToUpload,
              childProducts: filteredChildProducts,
            };
          });
	}

    const handleUpdateData = ( id, updatedAttributes ) => {
        setPayloadToUpload((prevPayloadToUpload) => {
            const updatedChildProducts = prevPayloadToUpload?.childProducts?.map(
              (prevChildProduct) =>
                prevChildProduct?.id === id
                  ? { ...prevChildProduct, ...updatedAttributes }
                  : prevChildProduct
            );
            return {
              ...prevPayloadToUpload,
              childProducts: updatedChildProducts,
            };
          });
    }

    useEffect(() => {
        if(payloadToUpload?.childProducts?.length === 0){
            setIsParentProduct(false);
            setIsVirtualProduct(false);
        }
    },[payloadToUpload?.childProducts]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px auto', width: '100%'}}>
                <div className={Classes.Box} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 12px', width: '65%' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: 500 }}>Add Child Products To Create Bundle</div>
                    <IconButton size='large' onClick={addChildProductSection}><ControlPointIcon /></IconButton>
                </div>
                <div className={Classes.Box} style={{width: '33%'}}>
                <FormControlLabel
                    control={
                        <Switch 
                        disabled={action === 'update' ? true : false} 
                        checked={isVirtualProduct}
                        onChange={e => setIsVirtualProduct(e?.target?.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color='warning'
                    />
                    }
                    label="Virtual Product"
                />
                </div>
            </div>

                {payloadToUpload?.childProducts && payloadToUpload?.childProducts?.map((childProductDetail, index) => (
                    <ChildProductSection 
                        childProducts={payloadToUpload?.childProducts} 
                        childProductDetail={childProductDetail} 
                        removeSection={handleRemove(childProductDetail?.id)} 
                        handleUpdateData={handleUpdateData}
                        key={index}
                        index={index}
                    />
                ))}
            
        </>
    )
}


function ChildProductSection(props) {
    const {removeSection, childProductDetail, childProducts, handleUpdateData, index } = props;
    let {childProductQuantity, childProductSku, mrp, productName} = childProductDetail;
    // we store only single selected sku
    const [selectedChildSku, setSelectedChildSku] = useState([]);

    useEffect(() => {
        //Selective Sku should not be repeated , ignore above childProductSku , if it is already selected
        const isSkuAlreadySelected = (childProducts.find(item => item.childProductSku === selectedChildSku[0]?.key && item?.childProductSku !== childProductSku))
        if(isSkuAlreadySelected && Object?.keys(isSkuAlreadySelected)?.length){
            removeSection();
        }
        else if(selectedChildSku[0]?.key){//undefined value must not be update 
            handleUpdateData(childProductDetail?.id, {
                childProductSku : selectedChildSku[0]?.key,
                mrp : selectedChildSku[0]?.mrp,
                productName: selectedChildSku[0]?.productName
            });
        }
    },[selectedChildSku]);

    const handleQuantity = (e) => {
        const quantity = +e?.target?.value;
        if(quantity > 0 ){
            handleUpdateData(childProductDetail?.id, {childProductQuantity : quantity});
        }
        else{
            handleUpdateData(childProductDetail?.id, {childProductQuantity : null});
        }
    }

    return (
        <div className={Classes.Pricing}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton onClick={removeSection}><ClearIcon /></IconButton>
            </div>
            <div className={Classes.Boxes}>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                    <div style={{width: '70%'}}>
                        {/* This is component to select any item from infinity scroll autocomplete */}
                        <InfinityScrollAutoComplete 
                            endpoint={'/product'} 
                            rowId={'pgDbProductId'}
                            searchTag={'text'} 
                            filters={[{"field":"active","operator":"=","value":true},{"field":"isParentProduct","operator":"=","value": false}]}
                            limit={30}
                            dataPosition={'products'} 
                            uniqueKey={'sku'} 
                            label={'productName'} 
                            placeholder={'Search products'}
                            selectedValue={selectedChildSku}
                            setSelectedValue={setSelectedChildSku}
                            multiSelect={false}
                            // required={true}
                            index={index}
                        />
                    </div>

                    <div style={{width: '25%'}}>
                        <TextField
                            label="Quantity in Bundle"
                            name='quantity'
                            type="number"
                            id="Quantity in Bundle"
                            required={true}
                            autoComplete='off'
                            size='small'
                            placeholder='Quantity in Bundle'
                            value={+childProductQuantity||''}
                            onChange={handleQuantity}
                            key={index}
                        />
                    </div>
                </div>
                {productName ? <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px', marginTop: '20px' }}>
                    <div style={{fontWeight: 500, color: '#295191', width:'75%'}}>
                        <p style={{color:'grey'}}>{childProductSku}</p>
                        <p>{productName}</p>
                    </div>
                    <p style={{fontWeight: 500, color: '#295191', width:'20%'}}>MRP - ₹{mrp}</p>
                </div> : null}
            </div>
        </div>
    )
}