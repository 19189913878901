import React, {Fragment, useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Classes from "../css/Modal.module.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from '../../../../utility/axios-instance';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { debounce } from "../../../../utility/utilityFunctions";
import { useCallback } from "react";
import { CustomerAddressFetchResponse, CustomerAddressUpdateResponse, DefaultAddressPopUpProps } from "@/types";

const DefaultAddressPopUp = (props: DefaultAddressPopUpProps)=> {
    const [searchText, setSearchText] = useState<string>('');
    const [deleteAddressArray, setDeleteAddressArray] = useState<{ id: string }[]>([]);
    const [loading,setLoading] = useState<boolean>(false);
    const {
        defaultAddressModal,
        hideDefaultAddressPopUp,
        showCustomerDetail,
        addressArray,
        setPreviousTarget,
        setAddressArray,
        fetchCustomerAddresses,
        selectedShippingAddress, 
        setSelectedShippingAddress,
    } = props;

    const handleDeleteAddressId = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        if (target.checked) {
            (document.getElementById('deleteAddressBtn') as HTMLElement).style.display = 'block';
            let array = deleteAddressArray;
            array.push({id: target.value})
            setDeleteAddressArray(array)
        } else {
            let array = deleteAddressArray;
            const store = array.filter((ele) => ele.id !== target.value)
            if (store.length === 0) {
                (document.getElementById('deleteAddressBtn') as HTMLElement).style.display = 'none';
            }
            setDeleteAddressArray(store)
        }
    }

    const handleShippingAddressSelection =(event: React.MouseEvent<HTMLButtonElement, MouseEvent>,addressId: number) => {
        setPreviousTarget([(event.target as HTMLButtonElement).id])
        axiosInstance
            .get<CustomerAddressFetchResponse>(`/user/admin/${showCustomerDetail?.id}/address/${addressId}`)
            .then((res) =>{
                const fetchedShippingAddress = res?.data?.data;
                const selectedShippingAddressModified = {
                    ...fetchedShippingAddress,
                    addressLine1: fetchedShippingAddress?.address1,
                    addressLine2: fetchedShippingAddress?.address2,
                    pincode: fetchedShippingAddress?.zip, 
                    state: fetchedShippingAddress?.province, 
                    phoneNumber: fetchedShippingAddress?.phone
                }
                setSelectedShippingAddress(selectedShippingAddressModified)
            })
            .catch((err) => {
                console.log(err,'error')
            })
    }

    const handleDeleteAddress = async (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        if (deleteAddressArray?.length !== 0) {
            setLoading(true);
            const array: string[] = [];
            for (let ele of deleteAddressArray) {
                array.push(ele.id);
            }
            await axiosInstance
                .post(`/user/admin/${showCustomerDetail?.id}/deleteAddressInBulk`, {
                    addressId: array
                })
                .then(async (res) => {
                    await axiosInstance
                        .get(`/user/admin/${showCustomerDetail?.id}/address`)
                        .then((resp) => {
                            const arr: {
                                id: string;
                            }[] = [];
                            setDeleteAddressArray(arr);
                            if (resp.data.data !== undefined) {
                                setAddressArray(resp.data.data);
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            console.log(err, 'error');
                        });
                })
                .catch((err) => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const searchAddress = async (searchText:string, customerId: string) => {
        fetchCustomerAddresses(customerId, searchText)
    }

    // useCallback ensures that debouncedAddressSearch remains the same across renders
    // such that it doesn't defeat the purpose of debouncing
    const debouncedAddressSearch = useCallback(debounce(searchAddress, 500), []);

    useEffect(() => {
        if(showCustomerDetail?.id !== undefined){
            debouncedAddressSearch(searchText, showCustomerDetail?.id);
        }
    }, [searchText, debouncedAddressSearch]);

    const updateDefaultShippingAddress = (event: React.ChangeEvent<HTMLInputElement>, addressId:number, checked: boolean) => {
        setLoading(true);
        axiosInstance
            .post<CustomerAddressUpdateResponse>(`/user/admin/${showCustomerDetail?.id}/address/${addressId}`, {
                default: checked
            })
            .then((res) => {
                const updatedAddress = res?.data?.data;
                setLoading(false);
                const modifiedAddress = {
                    ...updatedAddress,
                    addressLine1: updatedAddress?.address1,
                    addressLine2: updatedAddress?.address2,
                    pincode: updatedAddress?.zip, 
                    state: updatedAddress?.province, 
                    phoneNumber: updatedAddress?.phone
                }
                setSelectedShippingAddress(modifiedAddress);
                const updatedAddressArray = addressArray.map((address) => {
                    if (address?.id === addressId && address?.addressType === 'shipping') {
                        address.default = checked;
                    }else if(address?.addressType === 'shipping'){
                        address.default = false;
                    }
                    return address;
                });
                setAddressArray([...updatedAddressArray]);
            });
    }

    return (
        <Fragment>
            {defaultAddressModal && (
                <div className={Classes.modal} style={{bottom:'6%', zIndex:'9999'}}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className={Classes.Box}>
                        <div className={Classes.BoxContent}>
                            <p>Manage address</p>
                            <div style={{display:'flex'}}>
                                <p 
                                    className={Classes.DeleteAddressBtn}
                                    id='deleteAddressBtn'
                                    onClick={handleDeleteAddress}
                                >
                                    Delete Address
                                </p>
                                <FontAwesomeIcon 
                                    icon={faXmark} 
                                    className={Classes.ClosePopUpIcon}
                                    onClick={hideDefaultAddressPopUp}
                                />
                            </div>
                        </div>

                        <div className={Classes.BoxContent} style={{
                            padding: "0px 25px 20px 25px"
                        }}>
                            <TextField 
                                size="small" 
                                type="text" 
                                label="Search Address" 
                                onChange={(e) => {
                                    setSearchText(e?.target?.value);
                                }} 
                            />
                        </div>
                    </div>
                    
                    <div style={{overflowY: 'auto',height: 486 }}>
                        <div style={{ marginTop: '10px', padding: '10px 20px' }}>
                            <div>
                                <p style={{ fontWeight: '600', fontSize: '14px' }}>
                                    SELECT ADDRESS
                                </p>
                            </div>
                        </div>

                        {showCustomerDetail ?
                            <div>
                                {addressArray?.map((address, index) => (
                                    <>
                                        {address?.addressType === 'shipping' &&  (
                                            <div
                                                key={index}
                                                style={{
                                                    borderBottom: '1px solid #ced4da',
                                                    padding: '20px'
                                                }}
                                            >
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ width: '5%' }}>
                                                        <input
                                                            type="checkbox"
                                                            id={`${index}`}
                                                            value={address?.id}
                                                            onChange={handleDeleteAddressId}
                                                        />
                                                    </div>
                                                    <div className={Classes.AddressContainer}>
                                                        {address.nick_name ? <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.nick_name}
                                                            </p>
                                                        </div> : null}

                                                        <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.firstName}
                                                            </p>
                                                            {address?.lastName !== 'undefined'
                                                                ? <p>{address?.lastName}</p>
                                                                : null
                                                            }
                                                        </div>

                                                        <div>
                                                            <p>{address?.address1}</p>
                                                            {address?.address2 !== null
                                                                ? <p>{address?.address2}</p>
                                                                : ''}
                                                        </div>

                                                        <div style={{ display: 'flex' }}>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.zip},
                                                            </p>
                                                            <p style={{ marginRight: '5px' }}>
                                                                {address?.city}
                                                            </p>
                                                            <p>{address?.province?.toTitleCase()}</p>
                                                        </div>
                                                        <div>
                                                            <p>{address?.country}</p>
                                                        </div>

                                                        <div>
                                                            <p>{address?.phone}</p>
                                                        </div>

                                                    </div>

                                                    <div style={{
                                                        marginLeft: 'auto',
                                                    }}>
                                                        <div>
                                                            <p style={{ textAlign: 'center' }}>
                                                                Address Type
                                                            </p>
                                                            <p
                                                                className={Classes.AddressType}
                                                                style={{
                                                                    backgroundColor: address?.addressType === 'shipping' ?
                                                                        'turquoise' : 'greenyellow'
                                                                }}
                                                            >
                                                                {address?.addressType}
                                                            </p>
                                                        </div>

                                                        {selectedShippingAddress !== null ? (
                                                            <div style={{ marginTop: 60 }}>
                                                                {selectedShippingAddress?.id === address?.id ? (
                                                                    <button
                                                                        id={`${address?.id}`}
                                                                        disabled={true}
                                                                        className={Classes.selectedAddress}
                                                                    >
                                                                        Selected
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        id={`${address?.id}`}
                                                                        className={Classes.selectAddress}
                                                                        onClick={(e) => handleShippingAddressSelection(e, address?.id)}
                                                                    >
                                                                        Select
                                                                    </button>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div style={{ marginTop: 60 }}>
                                                                <button
                                                                    id={`${address?.id}`}
                                                                    className={Classes.selectAddress}
                                                                    onClick={(e) => handleShippingAddressSelection(e, address?.id)}
                                                                >
                                                                    Select
                                                                </button>
                                                            </div>
                                                        )}
                                                        <FormControlLabel 
                                                            control={
                                                                <Checkbox 
                                                                    checked={address?.default}
                                                                    onChange={(event, checked)=>{updateDefaultShippingAddress(event,address?.id, checked)}}
                                                                />
                                                            } 
                                                            label="Default" 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                            : null}

                        <div style={{
                            height: "80%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                        }}>
                            {addressArray?.length === 0 || addressArray?.filter((address)=>{
                                return address?.addressType === 'shipping'
                            })?.length === 0 && showCustomerDetail 
                                ? <div style={{padding:'20px'}}>
                                    <p style={{textAlign:'center'}}>No address found</p>
                                </div>
                            : ''}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}


export default DefaultAddressPopUp;