import React, { Fragment, useEffect } from 'react';

import { useState } from 'react';
import Slide from '@mui/material/Slide';
import axiosInstance from '../../../utility/axios-instance';
import { useSelector } from 'react-redux';
import Classes from '../createOrders/css/Modal.module.css';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
// import Spinner from '../../../components/spinner';
import { Button, Spinner } from 'react-bootstrap';




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StockTransferDialog({ open, handleClose, reRender, setReRender , setSnackBar, setLoading}) {
    
    const auth = useSelector((state) => state?.auth);
    const role = auth?.user?.role;
    const [skus,setSkus] = useState([]);
    const [binType,setBinType] = useState('');
    const [locations,setLocations] = useState([]);
    const [bins,setBins] = useState([]);

    const defaultLoc = role === 'super-admin' || role === 'admin'? locations?.[0] : auth?.user?.locations?.[0] || '';
    const [locCode, setLocCode] = useState(defaultLoc);
    

    const fetchData = (text) => {
        axiosInstance
            .get(`/product/fetchChildOrParentProductSkus?text=${text ? text :''}`)
            .then((res) => {
                setSkus(res.data.data)
            })
    }

    const fetchLocation = (text) => {
        axiosInstance
            .get(`/location/locations?text=${text ? text :''}`)
            .then((res) => {
                const locations = [];
                for (let location of res?.data?.data){
                    locations.push(location?.locationCode)
                }
                setLocations(locations) 
            })
    }

    useEffect(() => {
        fetchData();
        fetchLocation();
    },[])

    useEffect(() => {
        if (locCode) {
            axiosInstance
                .get(`/inventory/bins?locationCode=${locCode ? locCode : auth?.user?.locations?.[0]}&type=${binType ? binType: 'Saleable'}`)
                .then((res) => {
                    if(res.data.data.bins.length !==0) {
                    const bins = [];
                    for (let bin of res?.data?.data?.bins) {
                        bins.push(bin?.code)
                    }
                    setBins(bins);
                    } else {
                    setBins(res.data.data.bins);
                    }
                });
            }
    },[locCode,binType])

    const handleVirtualStockTransfer = (event) => {

        setLoading(true);
        event.preventDefault();

        const data = {
            "sku": event.target.sku.value,
            "binCode": event.target.binCode.value,
            "transferQty": parseInt(event.target.transferQuantity.value),
            "locationCode": event.target.locationCode.value
        }
        axiosInstance.put('/inventory/virtualChildStockTransfer', {...data})
            .then(response => {
                setLoading(false);
                handleCloseDialog();
                setReRender(!reRender);
                setSnackBar({ display: true, type: "success", message: response.data.message })
            })
            .catch(error => {
                setLoading(false);
                handleCloseDialog();
                setSnackBar({ display: true, type: "error", message: error.response.data.message })
            })
    }

    const handleCloseDialog = () => {
        handleClose();
        setSkus([]);
        setBinType('')
        setLocations([])
        setBins([])
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <Fragment>
            <Dialog 
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                fullWidth={true}
                sx={{'& .MuiDialogContent-root': {height:'352px',width:'400px',paddingTop:'10px'}}}>
                    <form method="post" onSubmit={handleVirtualStockTransfer} id="form">
                        <DialogTitle>Virtual Stock Transfer</DialogTitle>
                        {/* <DialogContent style={{paddingTop:'10px'}}> */}
                            <div style={{ padding: '30px 20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width:'49%' }}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="sku"
                                            name="sku"
                                            defaultValue={skus?.[0]}
                                            options={skus}
                                            renderInput={(params) => <TextField 
                                                {...params} label="SKU" required={true}
                                                onChange={(event)=> {fetchData(event.target.value)}}
                                            />}
                                        />
                                    </div>

                                    <div style={{ width: '49%' }}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="locationCode"
                                            name="locationCode"
                                            defaultValue={defaultLoc}
                                            options={role === 'super-admin' || role === 'admin'? locations : auth?.user?.locations || []}
                                            renderInput={(params) => <TextField 
                                                {...params} label="Location Code" required={true}
                                                // onChange={(event)=> {fetchLocation(event.target.value)}}
                                                onBlur={(event) => {setLocCode(event.target.value)}}
                                            />}
                                        />
                                    </div>

                                </div>

                                <div className={Classes.ContentBox}>
                                    <div style={{ width: '49%' }}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="binType"
                                            name="binType"
                                            options={["Saleable", "Rejected", "QC", "Packing", "Returned", "Staging"]}
                                            defaultValue={"Saleable"}
                                            renderInput={(params) => <TextField  
                                                {...params} label="Bin Type" onBlur={(event) => {setBinType(event.target.value)}}
                                            />}
                                        />
                                    </div>
                                    <div style={{ width: '49%' }}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            id="binCode"
                                            name="binCode"
                                            // defaultValue={bins?.[0] || ''}
                                            options={bins}
                                            renderInput={(params) => <TextField {...params} label="Bin Code" required={true}/>}
                                        />
                                    </div>
                                </div>

                                <div className={Classes.ContentBox}>
                                    <div style={{ width: '49%' }}>
                                        <TextField
                                            required={true}
                                            id='transferQuantity'
                                            name="transferQuantity"
                                            label="Transfer Qty"
                                            type="number"
                                            placeholder='Enter Quantity'
                                            autoComplete='off'
                                            sx={{width:'100%'}}
                                            min={0}
                                            onKeyDown={preventNegativeValues}
                                        />
                                    </div>
                                </div>
                            </div>
                        {/* </DialogContent> */}
                        <DialogActions>
                            <Button onClick={handleCloseDialog}>Cancel</Button>
                            <Button type='submit'>Submit</Button>
                        </DialogActions>
                    </form>
                </Dialog>
        </Fragment>
    );
}