import React, { useCallback, useEffect, useState } from "react";
import Classes from "../pages/home/settings/dashboard.module.css";
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Chip, Tooltip } from "@mui/material";
import axiosInstance from "../utility/axios-instance";
import _ from "lodash";
import { useSelector } from "react-redux";
import SnackBar from "./SnackBar";
import { Play, Square, RotateCw } from "lucide-react"

const SettingsItem = ({ onClick, iconSrc, iconAlt, title, subTitle }) => {
  const [ec2Status, setEc2Status] = useState("unknown");
  const auth = useSelector((state) => state.auth);
  const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })
  const [hoveredButton, setHoveredButton] = useState(null)

  const fetchEc2Status = useCallback(_.debounce(() => {
    axiosInstance.get('/aws/getEc2Info')
      .then(response => {
        setEc2Status(response?.data?.data?.state);
        setSnackBar({ display: true, type: "success", message: "Tally server status refreshed!"})
      })
      .catch(error => {
        const errorMessage = error?.response?.data?.message || "There was an error fetching the Tally server status!";
        setSnackBar({ display: true, type: "error", message: errorMessage})
        console.error("There was an error fetching the Tally server status!", error);
      });
  }, 2000), []);

  const updateEc2Status = (status) => {
    if (window.confirm(`Are you sure you want to ${status} the Tally server instance?`)) {
      axiosInstance.post('/aws/updateEc2Status', {
        status: status
      })
        .then(response => {
          setSnackBar({ display: true, type: "success", message: "Tally server status updated!"})
          fetchEc2Status();
        })
        .catch(error => {
          const errorMessage = error?.response?.data?.message || "There was an error updating the EC2 status!";
          setSnackBar({ display: true, type: "error", message: errorMessage})
          console.error("There was an error updating the Tally server status!", error);
        });
    }
  }

  useEffect(() => {
    if(title === "AWS EC2"){
      fetchEc2Status();
    }
  }, [title]);

  const buttonStyle = (buttonId) => ({
    width: "32px",
    height: "32px",
    padding: "0",
    border: "none",
    borderRadius: "6px",
    backgroundColor: "transparent",
    color: hoveredButton === buttonId ? "#0f172a" : "#475569",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "color 0.2s ease",
  })

  // Add keyframes for pulse animation to document head
  if (typeof document !== "undefined") {
    const style = document.createElement("style")
    style.textContent = `
      @keyframes pulse {
        0%, 100% { opacity: 1; }
        50% { opacity: .5; }
      }
    `
    document.head.appendChild(style)
  }

  return (
    <div className={Classes.Item} onClick={onClick}>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "100%",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {React.isValidElement(iconSrc) ? (
            iconSrc
          ) : (
            <img
              className={`${Classes.BtnImage}`}
              style={{ width: "auto", height: "4rem" }}
              src={iconSrc}
              alt={iconAlt}
            />
          )}
        </div>
        {title === "AWS EC2" && auth?.user?.role === "super-admin" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "66.666%",
            }}
          >
            <div style={{ flex: 1 }}>
              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  margin: 0,
                  color: "rgb(100, 100, 100)",
                }}
              >
                Tally Server Status
              </h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "4px",
                }}
              >
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor:
                      ec2Status === "running"
                        ? "#10b981"
                        : ec2Status === "stopped"
                        ? "#ef4444"
                        : ec2Status === "pending"
                        ? "#f59e0b"
                        : ec2Status === "stopping"
                        ? "#3b82f6"
                        : "#6b7280",
                    animation:
                      ec2Status === "running" ||
                      ec2Status === "pending" ||
                      ec2Status === "stopping"
                        ? "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite"
                        : "none",
                  }}
                />
                <Tooltip title="Refresh Status" placement="top">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#475569",
                    }}
                    onClick={fetchEc2Status}
                  >
                    {ec2Status}
                  </span>
                </Tooltip>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Tooltip title="Start" placement="top">
                <button
                  style={buttonStyle("start")}
                  onMouseEnter={() => setHoveredButton("start")}
                  onMouseLeave={() => setHoveredButton(null)}
                  aria-label="Start"
                  onClick={() => updateEc2Status("start")}
                  disabled={
                    ec2Status === "running" ||
                    ec2Status === "pending" ||
                    ec2Status === "stopping"
                      ? true
                      : false
                  }
                >
                  <Play style={{ width: "16px", height: "16px" }} />
                </button>
              </Tooltip>
              <Tooltip title="Stop" placement="top">
                <button
                  style={buttonStyle("stop")}
                  onMouseEnter={() => setHoveredButton("stop")}
                  onMouseLeave={() => setHoveredButton(null)}
                  aria-label="Stop"
                  onClick={() => updateEc2Status("stop")}
                  disabled={
                    ec2Status === "stopped" || ec2Status === "stopping"
                      ? true
                      : false
                  }
                >
                  <Square style={{ width: "16px", height: "16px" }} />
                </button>
              </Tooltip>
              <Tooltip title="Reboot" placement="top">
                <button
                  style={buttonStyle("reboot")}
                  onMouseEnter={() => setHoveredButton("reboot")}
                  onMouseLeave={() => setHoveredButton(null)}
                  aria-label="reboot"
                  onClick={() => updateEc2Status("restart")}
                  disabled={
                    ec2Status === "stopped" || ec2Status === "stopping"
                      ? true
                      : false
                  }
                >
                  <RotateCw style={{ width: "16px", height: "16px" }} />
                </button>
              </Tooltip>
            </div>
          </div>
        ) : (
          <h4
            style={{
              fontSize: "1.25rem",
              width: "66.666%",
            }}
          >
            {title}
            <p
              style={{
                fontSize: "11px",
                marginTop: "10px",
              }}
            >
              {subTitle}
            </p>
          </h4>
        )}
      </div>
    </div>
  );
};

export default SettingsItem;