import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SnackBar from '../../../../components/SnackBar';
import { Autocomplete, TextField } from '@mui/material';
import axiosInstance from '../../../../utility/axios-instance';
import Classes from "../css/Modal.module.css";
import Spinner from '../../../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateSellerAddress = (props)  => {
    const { open, handleClose, fetchData, setSellers,seller, setSellerBillingAddress,sellerBillingAddress } = props;

    const [loading,setLoading] = useState(false);
    // const [phoneNo,setPhoneNo] = useState('');
    const [pincode,setPincode] = useState('');
    // const [selectedState,setSelectedState] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" });
    const [states, setStates] = useState([])
    const [selectedState, setSelectedState] = useState(sellerBillingAddress?.state ?? '');
    const [cities, setCities] = useState([]);

    useEffect(() => {
        axiosInstance
            .get('/pincode/getAllStates')
            .then((response) => {
                setStates(response.data.data);
            })
    }, [])

    useEffect(()=> {
        if (selectedState) {
            axiosInstance
            .get(`/pincode/getAllCities?state=${selectedState}`)
            .then((response) => {
                setCities(response.data.data);
            })
        }
    },[selectedState]);

    const handleSubmit = async (event)=> {
        event.preventDefault();
        setLoading(true);

        const data = {
            id: sellerBillingAddress?.id,
            sellerId: seller?.id,
            addressLine1: event.target.address1.value,
            addressLine2: event.target.address2.value,
            city: event.target.city.value,
            state: event.target.state.value,
            pincode: event.target.pincode.value,
            firstName: event.target.first.value,
            lastName: event.target.last.value ? event.target.last.value : undefined,
            phoneNumber: event.target.phone.value,
            addressType: 'Billing',
        }

        axiosInstance
            .put(`/seller/sellerAddress`, {...data})
            .then((res) => {
                const form = document.getElementById('form');
                form.reset();
                fetchData('/seller/fetchAllSellers', setSellers)
                // setPhoneNo('');
                handleClose();
                setLoading(false);
                setSellerBillingAddress(res.data.data);
                setSnackBar({ display: true, type: "success", message: res?.data?.message })
            }).catch((err) => {
                setLoading(false);
                setSnackBar({ display: true, type: "error", message: err?.response?.data?.message })
            })
    }

    const handleMinLength = (event) => {
        if (event.target.value) {
            const phoneno = /^\d{10}$/;
            if(event.target.value.match(phoneno)){
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            }else{
                document.getElementById('btn').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }


    const handleMinLengthPinCode = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                document.getElementById('pinErrMsg').style.display = 'none';
                document.getElementById('btn').disabled = false;
            } else {
                document.getElementById('btn').disabled = true;
                document.getElementById('pinErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsg').style.display = 'none';
            document.getElementById('btn').disabled = false;
        }
    }

    async function handlePincodeChange(event) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        setPincode(enteredPincode);
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${enteredPincode}`);
            const data = await response.json();

            if (data[0]?.Status === 'Success') {
                const extractedState = data[0]?.PostOffice[0]?.State;
                // setSelectedState(extractedState);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle>Update Seller Billing Address</DialogTitle>
                <form method="post" onSubmit={handleSubmit} id='form' >
                    <div style={{padding:20}}>
                        <div style={{ display:'flex',justifyContent:'space-between' }}>
                            <div style={{ width:'49%' }}>
                                <TextField 
                                    required={true}
                                    id='first'
                                    name='first'
                                    label='First Name'
                                    autoComplete='off'
                                    size='small'
                                    key={sellerBillingAddress?.firstName}
                                    defaultValue={sellerBillingAddress?.firstName}
                                    placeholder='Enter Your First Name'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ width: '49%' }}>
                                <TextField 
                                    id='last'
                                    name='last'
                                    label='Last Name'
                                    autoComplete='off'
                                    size='small'
                                    key={sellerBillingAddress?.lastName}
                                    defaultValue={sellerBillingAddress?.lastName}
                                    placeholder='Enter Your Last Name'
                                    sx={{ width: '100%'}}
                                />
                            </div>
                        </div>

                        <div style={{marginTop:10,display:'flex',justifyContent:'space-between'}}>
                            <div style={{ width:'49%' }}>
                                <TextField 
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    autoComplete='off'
                                    size='small'
                                    key={sellerBillingAddress?.addressLine1}
                                    defaultValue={sellerBillingAddress?.addressLine1}
                                    placeholder='Enter Your Address'
                                    sx={{ width: '100%'}}
                                />
                            </div>

                            <div style={{ width:'49%' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    size='small'
                                    key={sellerBillingAddress?.addressLine2}
                                    defaultValue={sellerBillingAddress?.addressLine2}
                                    placeholder='Apartment,Suite,etc'
                                    sx={{ width: '100%'}}
                                />
                            </div>
                        </div>

                        <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                            <div style={{ width: '49%'}}>
                                <Autocomplete
                                    freeSolo
                                    disablePortal
                                    id="city"
                                    size="small"
                                    options={cities}
                                    key={sellerBillingAddress?.city}
                                    defaultValue={sellerBillingAddress?.city}
                                    disabled={!selectedState ? true: false}
                                    renderInput={(params) => <TextField {...params} label="City" required/>}
                                />
                            </div>

                            <div style={{ width: '49%' }}>
                                <TextField 
                                    type="number"
                                    required={true}
                                    id='pincode'
                                    name='pincode'
                                    label='Pincode'
                                    autoComplete='off'
                                    placeholder='Enter Your Pincode'
                                    maxLength={6}
                                    sx={{ width: '100%'}}
                                    // value={pincode}
                                    onInput={(e)=>{ e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)}}
                                    key={sellerBillingAddress?.pincode}
                                    defaultValue={sellerBillingAddress?.pincode}
                                    size='small'
                                    onBlur={(e) => handleMinLengthPinCode(e)}
                                    onChange={handlePincodeChange}
                                    onKeyDown={preventNegativeValues}
                                />

                                <div className={Classes.PhoneErrorMsg} id='pinErrMsg' style={{ fontSize:14 }}>
                                    <p>Please enter a valid 6 digit pincode</p>
                                </div>
                            </div>
                        </div>

                        <div style={{ display:'flex',justifyContent:'space-between',marginTop:10 }}>
                            <div style={{ width: '49%' }}>
                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    id="state"
                                    options={states}
                                    defaultValue={sellerBillingAddress?.state}
                                    ListboxProps={{ sx: { fontSize: 14 } }}
                                    sx={{ width: '100%' }}
                                    onChange={(e, newValue) => setSelectedState(newValue ?? '')}
                                    renderInput={(params) => <TextField {...params} label="States" required />}
                                />
                            </div>

                            <div style={{ width: '49%' }}>
                                <TextField 
                                    type="number"
                                    id='phone'
                                    name='phone'
                                    label='Phone'
                                    autoComplete='off'
                                    placeholder='Enter Your Phone Number'
                                    maxLength={10}
                                    sx={{ width: '100%'}}
                                    // value={phoneNo}
                                    onInput={(e)=>{ e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)}}
                                    size='small'
                                    key={sellerBillingAddress?.phoneNumber ? parseInt(sellerBillingAddress?.phoneNumber): ''}
                                    defaultValue={sellerBillingAddress?.phoneNumber? parseInt(sellerBillingAddress?.phoneNumber): ''}
                                    onKeyDown={preventNegativeValues}
                                    onBlur={(e) => handleMinLength(e)}
                                />

                                <div
                                    className={Classes.PhoneErrorMsg}
                                    id='phoneErrMsg'
                                >
                                    <p>Please enter a valid 10 digit mobile number</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DialogActions>
                        <Button onClick={handleClose}>CLOSE</Button>
                        <Button type='submit' id='btn'>UPDATE</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />

            {loading ? <Spinner /> : null}
        </div>
    );
}

export default UpdateSellerAddress;