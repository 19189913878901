import Table from '../../../components/table/table';
import { setCustomersUpdateHistory } from '../../../store/customerUpdateHistory';
import { useSelector } from 'react-redux';

const CustomerUpdateHistory = () => {
    const customersUpdateHistory = useSelector((state) => state.customersUpdateHistory);

    const columns = [
        {
            field: `display_name`,
            headerName: `Name`,
            flex: 1.2,
            headerClassName: "super-app-theme--header",
            headerAlign: "left",
            align: "left",
            editable: false,
            sortable: false,
            filterable: true,
        },
        {
            field: `column_altered`,
            headerName: `Field Updated`,
            flex: 1.1,
            headerClassName: "super-app-theme--header",
            headerAlign: "left",
            align: "left",
            editable: false,
            sortable: false,
            filterable: true,
        },
        {
            field: `previous_value`,
            headerName: `Previous Value`,
            flex: 1,
            headerClassName: "super-app-theme--header",
            headerAlign: "left",
            align: "left",
            editable: false,
            sortable: false,
            filterable: true,
        },
        {
            field: `current_value`,
            headerName: `Updated Value`,
            flex: 1,
            headerClassName: "super-app-theme--header",
            headerAlign: "left",
            align: "left",
            editable: false,
            sortable: false,
            filterable: true,
        },
        {
            field: `alter_by`,
            headerName: `Updated By`,
            flex: 0.8,
            headerClassName: "super-app-theme--header",
            headerAlign: "left",
            align: "left",
            editable: false,
            sortable: false,
            filterable: true,
        },
        {
            field: `alter_at`,
            headerName: `Updated At`,
            flex: 1.2,
            headerClassName: "super-app-theme--header",
            headerAlign: "left",
            align: "left",
            editable: false,
            sortable: true,
            filterable: true,
            // renderCell: (params) =>
            // moment(params?.row?.alter_at).format("MMM DD, YYYY h:mm:ss A"),
        },
    ];

    return (
        <Table
            columns={columns}
            dataPosition={"usersUpdateHistory"}
            endpoint={`/user/admin/customer/updateHistory`}
            rowId={'id'}
            checkBoxSelection={false}
            service={'customers'}
            tabName={'Customers'}
            setData={setCustomersUpdateHistory}
            data={customersUpdateHistory}
            mobileResponsive={true}
        />
    )
}

export default CustomerUpdateHistory;