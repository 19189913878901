import { Fragment, useContext} from 'react';
import { Autocomplete, TextField, Tooltip, IconButton} from '@mui/material';
import { GridApiContext } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import axiosInstance from '../../utility/axios-instance';
import { Clear } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const MultiFilters = ({ filters, filterId, removeSection, setMultiFilters }) => {
    const [fetchedEnumValues, setFetchedEnumValues] = useState([]);
    const [filterEnum,setFilterEnum] = useState(false);
    const apiRef = useContext(GridApiContext);
    const findAllColumns = apiRef?.current?.getAllColumns();
    const filterColumns = findAllColumns?.filter((column) => column?.filterable === true);
    const auth = useSelector((state) => state.auth);

    const fetchEnumValues = async (tableName, columnName) => {
        if (!tableName || !columnName) return;
        const query = { tableName: tableName, columnName: columnName }
        axiosInstance
        .get(`/product/filtersForUI`, {
            params: query,
        })
        .then((res) => {
            let enumValues = res?.data?.data?.enumValues;
            enumValues = enumValues?.filter((value) => value !== null && value !== undefined && value !== '' && value !== 'null');
            setFetchedEnumValues(enumValues);
        });
    };

    const handleFilterEnumValues = (field) => {
        const filterData = filterColumns?.find((column) => column?.field === field);
        if (field &&  filterData?.type === 'enum') {
            const filterColumn = filterColumns?.find((column) => column?.field === field);
            fetchEnumValues(filterColumn?.tableName, filterColumn?.columnName);
            setFilterEnum(true);
        } else if (field==="locationCode"){
            setFilterEnum(true)
            setFetchedEnumValues(auth?.user?.locations || [])
        } else if (field && filterData?.enumValues){
            setFilterEnum(true)
            setFetchedEnumValues(filterData?.enumValues)
        } else {
            setFilterEnum(false);
        }
    }

    const handleFilterColumn = (event) => {
        const operators = (filterColumns?.find((column) => column?.field === event?.target?.value))?.filterOperators;
        let operator = '';
        if (operators?.length === 1) {
            operator = operators?.[0]?.value
        }
        handleFilterEnumValues(event?.target?.value)
        setMultiFilters((prevFilters) =>
            prevFilters.map((filter, idx) =>
            idx === filterId
                ? { ...filter, field: event?.target?.value, value: '', operator: operator } // Update the filter at the given index
                : filter // Keep the other filters unchanged
            )
        );
    }

    const handleFilterValue = (value) => {
        const filterData = filterColumns?.find((column) => column?.field === filters?.field)
        setMultiFilters((prevFilters) =>
            prevFilters.map((filter, idx) =>
            idx === filterId
                ? { ...filter, value: value, operator: filterData?.filterOperators[0]?.value } // Update the filter at the given index
                : filter // Keep the other filters unchanged
            )
        );
    }

    const handleFilterOperator = (event) => {
        setMultiFilters((prevFilters) =>
            prevFilters.map((filter, idx) =>
            idx === filterId
                ? { ...filter, operator: event?.target?.value } // Update the filter at the given index
                : filter // Keep the other filters unchanged
            )
        );
    }

    const operators = filters?.field ? 
        ((filterColumns?.find((column) => column?.field === filters?.field))
        ?.filterOperators)?.map((operator) => operator?.value)
        :  ['contains', 'equals', 'startsWith', 'endsWith', 'isEmpty', 'isNotEmpty', 'isAnyOf']

    useEffect(() => {
        if (!filters?.field) {
            setMultiFilters((prevFilters) =>
                prevFilters?.map((filter, idx) =>
                idx === filterId
                    ? { ...filter, field: filterColumns?.[0]?.field } // Update the filter at the given index
                    : filter // Keep the other filters unchanged
                )
            );
        } else {
            handleFilterEnumValues(filters?.field)
        }
    }, [])
    // useEffect(() => {
    //     const filterData = filterColumns?.find((column) => column?.field === filters?.field);
    //     if (filters?.field &&  filterData?.type === 'enum') {
    //         const filterColumn = filterColumns?.find((column) => column?.field === filters?.field);
    //         fetchEnumValues(filterColumn?.tableName, filterColumn?.columnName);
    //         setFilterEnum(true);
    //     } else if (filters?.field==="locationCode"){
    //         setFilterEnum(true)
    //         setFetchedEnumValues(auth?.user?.settings?.locationSetting || [])
    //     } else if (filters?.field && filterData?.enumValues){
    //         setFilterEnum(true)
    //         setFetchedEnumValues(filterData?.enumValues)
    //     } else {
    //         setFilterEnum(false);
    //     }
    // }, [filters]);

    const handleRemoveFilter = () => {
        const filterData = filterColumns?.find((column) => column?.field === filters?.field);
        if (filterData?.type === 'enum' || filterData?.enumValues) {
            setFilterEnum(false);
        }
        removeSection()
    }

    const InputValue = (inputComponent) => {
        const InputComponent = inputComponent?.InputComponent;
        const InputComponentProps = inputComponent?.InputComponentProps;
        return (
            <div>
                <InputComponent {...InputComponentProps} />
            </div>
        )
    }

    return (
        <Fragment>
            <div style={{display:'flex', marginBottom: 10,padding: '5px 0px'}} id={filterId}>
                <div style={{ marginTop:20  }}>
                    <Tooltip title="Delete" onClick={handleRemoveFilter}>
                        <IconButton>
                            <Clear sx={{ color:"gray", fontSize:"20px" }} />
                        </IconButton>
                    </Tooltip>
                </div>
                
                <div>
                    <label style={{fontSize:12, marginLeft: 8}}>Columns</label>
                    <div>
                        <select
                            style={{ 
                                border:0, borderBottom:"1px solid black", borderRadius:0, 
                                minWidth: 16, width:200
                            }}
                            onChange={(e) => handleFilterColumn(e)}
                            value={filters?.field}
                        >
                            {filterColumns?.length && filterColumns?.map((column) => (
                                <option key={column?.field} value={column?.field}>{column?.headerName}</option>
                            ))}
                        </select>
                    </div>
                </div>
                
                <div> 
                    <label style={{ fontSize:12, marginLeft:8 }}>Operator</label>
                    <div>
                        <select 
                            style={{border:0, borderBottom:"1px solid black", borderRadius:0, minWidth:16, width:170 }}
                            onChange={(event) => handleFilterOperator(event)}
                            value={filters?.operator}
                        >
                            {operators?.map((operator) => (
                                <option key={operator} value={operator}>{operator}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div style={{width:240}}>
                    {(filterColumns?.filter((column) => column?.field === filters?.field)?.[0]?.filterOperators?.[0]?.InputComponentProps 
                    && operators?.[0] === 'between') && (
                        <>{InputValue(filterColumns?.filter((column) => column?.field === filters?.field)?.[0]?.filterOperators?.[0])}</>
                    )}

                    {(filterEnum && operators?.[0] !== 'between') && (
                        <Autocomplete
                            id="attributeName"
                            value={filters?.value || ''}
                            options={fetchedEnumValues || []}
                            onChange={(event, newValue) => { handleFilterValue(newValue) }}
                            isOptionEqualToValue={(option, value) => option === value}
                            sx={{ "& .MuiInput-root": {marginTop:"22px"}}}
                            renderInput={(params) =>
                                <TextField 
                                {...params} variant="standard" label="Attribute Name" 
                                InputLabelProps={{ shrink: true }}
                                />
                            }
                        />
                    )}

                    {(!filterEnum &&  operators?.[0] !== 'between') && (
                        <TextField 
                            label="Value" variant="standard" placeholder='Filter Value'
                            size='small'
                            sx={{ 
                                "& .MuiInput-input": {marginTop: '8px',borderBottom:"1px solid black", borderRadius:0 }, 
                                "& label": {top: !filters?.value ? "-24%" : "0%", fontSize: '12px'}
                            }}
                            value={filters?.value || ''}
                            onChange={(event) => handleFilterValue(event?.target?.value)}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default MultiFilters;