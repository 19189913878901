import { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Classes from "./css/create.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faXmark,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import Aside from "./aside/aside";
import Modal from "./Modal";
import axiosInstance from "../../../utility/axios-instance";
import TaxRatesPopup from "./TaxRates_popup";
import DiscountPopup from "./addDiscountPopup";
import ShippingPopUp from "./AddShippingPopup";
import TaxRatesPrint from "./TaxRatesPrint";
import ConfirmPopup from "./confirmPopup";
import { Spinner } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { snakeToCamelCase } from "../../../utility/textFormatting";
import { CheckRoleBasedPermission, services } from "../../../utility/checkRoleBasedPermission";
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CapsuleChip from "../../../components/CapsuleChip";
import SmartProductSearch from "./SmartProductSearch";
import ChildProductsList from "./childProductsList";
import BulkProductAddForCreateOrders from "./bulkProductAddForCreateOrders";
import { roundOfUptoXDecimal } from "../../../utility/calculation";
import SelectCompany from '../../../components/selectCompany';
import AddCoupon from "./applyCouponCodePopup";
import Permission from "../../auth/permissions";
import SnackBar from "../../../components/SnackBar";
import {
  AutoCompleteOptions,
  ConfirmPopupEvent,
  CreateOrderProps,
  Customer,
  CustomerRoles,
  EstimateResponse,
  HandleBlurEvent,
  HandleDiscountParams,
  LineItemDiscount,
  OverallDiscount,
  PaymentGateway,
  ProductDetails,
  ProductNew,
  SellersResponse,
  ShowLimitDiscountParams,
  CalculationServiceResponse,
  GstPercentage,
  DraftOrderResponse,
  StructuredTaxLine,
  ShippingLine,
  ShippingPriceType,
  DoCustomerAddress,
  SellerAddress,
  HsnCode,
  TaxArrayType,
  DraftOrderCreateResponse,
  CustomerRolesResponse,
  CustomerRoleObj,
  CustomerAddressesFetchResponse,
  LineItemToBeCalculated,
  CalculatedLineItem,
  ProductDetail,
  CalculationServiceRequest,
  SalesUsersApiResponse,
  AutoCompleteOption,
  Seller,
  DraftOrderCreatePayload,
} from "@/types";
import { useAppSelector } from "../../../hooks/hooks";
import { AxiosResponse } from "axios";
import SerialNumber from "../../../components/SerialNumber";

const CreateOrder = (props: CreateOrderProps) => {
  const { isCreateOrderPage, setIsCreateOrderPage } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchProduct, setSearchProduct] = useState<string>("");
  const [productId, setProductId] = useState<ProductDetails>(null);
  const [variant, setVariant] = useState(0);
  const [addToggleBtn, setAddToggle] = useState(false);
  const [showProduct, setProduct] = useState<CalculationServiceResponse["product"] | null>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [showTaxRate, setTaxRates] = useState<boolean>(false);
  const [discountModal, setDiscountModal] = useState<boolean>(false);
  const [shippingModal, setShippingModal] = useState<boolean>(false);
  const [productDetails, setProductDetails] = useState<ProductDetails>(null);
  const [updateDiscountPercent, setUpdateDiscountPercent] = useState<string[]>([]);
  const [editDiscountPercent, setDiscountPercent] = useState<string[]>([]);
  const [storePrevDiscountId, setStorePrevDiscountId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [draftOrderId, setDraftOrderId] = useState<string | null>(null);
  const [orderTime, setOrderTime] = useState<string | null>(null);
  const [invoiceType, setInvoiceType] = useState("");
  const [productIdArr, setProductIdArr] = useState([]);
  const [sellerBillingAddress, setSellerBillingAddress] = useState<SellerAddress | null>(null)
  const [seller, setSeller] = useState<SellersResponse["data"][0] | null>(null)
  const adminUser = useAppSelector((state) => state?.auth?.user);
  const filterSellers = adminUser?.sellers
  const [selectedRoleForDiscount, setSelectedRoleForDiscount] = useState<CustomerRoles | "DEFAULT">("DEFAULT");
  const defaultSeller = filterSellers?.find((seller) => {
    return seller?.default === true
  })

  const [taxLines, setTaxLines] = useState<StructuredTaxLine[]>([]);
  const [totalTaxAmount, setTotalTaxAmount] = useState<number | null>(null);
  // modal
  const [productNew, setProductNew] = useState<ProductNew[]>([]);
  const [showConfirmPopup, setConfirmShowPopup] = useState(false);

  // ShippingPopUp
  const [shippingPrice, setShippingPrice] = useState<ShippingLine | null>(null);
  const [previousTarget, setPreviousTarget] = useState<string[]>([]);
  const [shippingPriceType, setShippingPriceType] = useState<ShippingPriceType | null>(null);
  const [shippingAmount, setShippingAmount] = useState(false);

  // aside
  const [customerDetails, setCustomerDetails] = useState<Customer | null>(null);
  const [selectedShippingAddress, setSelectedShippingAddress ] = useState<DoCustomerAddress | null>(null);
  const [selectedBillingAddress, setSelectedBillingAddress ] = useState<DoCustomerAddress | null>(null);
  const [gstNumber, setGstNumber] = useState<string | null>(null);
  const [addressArray, setAddressArray] = useState<CustomerAddressesFetchResponse["data"]>([]);
  const [overAllDiscount, setOverAllDiscount] = useState<OverallDiscount>(null);
  const [draftOrder, setDraftOrder] = useState<DraftOrderCreateResponse["data"] | null>(null);
  const [showSelectedTags, setSelectedTags] = useState<string[]>([]);
  const [fetchCustomerRoleDB, setCustomerRoleDB] = useState<CustomerRoleObj[]>([]);

  // store deliveryRemark & paymentRemark in State
  const [paymentRemark, setPaymentRemark] = useState<string | null>(null);
  const [deliveryRemark, setDeliveryRemark] = useState<string | null>(null);

  // sendPIDraftOrder
  const [sendPIDraftOrder, setSendPIDraftOrder] = useState<DraftOrderResponse["data"]["draftOrder"] | null>(null);
  const navigate = useNavigate();

  // sendExistOrder
  const [updateToggle, setUpdateToggle] = useState(true);
  const [updateDraftOrderId, setUpdateDraftOrderId] = useState<string[]>([]);
  const [updateExistOrder, setUpdateExistOrder] = useState(false);
  const [showDiscountType, setDiscountType] = useState([]);
  const [affiliateUser, setAffiliateUser] = useState<AutoCompleteOption | null>(null);
  const [salesUser, setSalesUser] = useState<AutoCompleteOption | null>(null);
  const [preSalesUser, setPreSalesUser] = useState<AutoCompleteOption | null>(null);
  const [salesList, setSalesList] = useState<AutoCompleteOptions | null>(null);
  const productsAddedToCartRef = useRef([]);
  const [snackBar, setSnackBar] = useState({
    display: false,
    message: "",
    type: "success",
  });
  const [loadingSalesOrderCalculationService, setLoadingSalesOrderCalculationService] = useState(false);

  const [ specialRetailerPermission, setSpecialRetailerPermission] = useState(true);
  const districts = adminUser?.settings?.districts
  const districtOptions = districts?.map((district) => {
    return {
      label: district,
      value: district
    }
  });
  const [selectedDistrict, setSelectedDistrict] = useState(districtOptions?.[0]);

  const { Id } = useParams();

  const location = useLocation();

  const [estimateId, setEstimateId] = useState<string|null>(null);
  const [estimate, setEstimate] = useState<EstimateResponse["data"] | null>(null);
  const [estimateUserId, setEstimateUserId] = useState<string | null>(null);
  // We state new Product Ids in array
  const [newAddedProductIds , setNewAddedProductIds] = useState<string[]>([]);


  const [draftOrderCreate, setDraftOrderCreate] = useState(false);
  
  const [paymentGateways, setPaymentGateways] = useState<PaymentGateway[]>([]);

  const [couponCode, setCouponCode] = useState<string|null>(null);
  const [lineItemDiscountApplicable, setLineItemDiscountApplicable] = useState(true);

  const findAndSelectSeller = (id: string) =>{
    axiosInstance
    .get<SellersResponse>(`/seller/fetchAllSellers`)
    .then((response) => {
      const responseData  = response?.data?.data;
      const foundSeller = responseData?.find((seller) => {
        return seller?.id === id
      }) || null
      setSeller(foundSeller)
    });
  }
  
  useEffect(() => {
    if (!updateExistOrder) {
      if(CheckRoleBasedPermission(adminUser, services?.sellers, "read") && defaultSeller?.id){
        findAndSelectSeller(defaultSeller?.id)
      }
    }
  }, [])
  useEffect(() => {
    const arr = location.search.split(RegExp("[?&]")).slice(1);

    for (let i = 0; i < arr.length; i++) {
      const q = arr[i].split("=");

      if (q[0] === "estimateId") {
        setEstimateId(q[1]);
        break;
      }
    }
  }, [location]);

  const fetchEstimate = async () => {
    if (estimateId) {
      setLoading(true);
      axiosInstance.get<EstimateResponse>(`/estimate/admin/${estimateId}`).then((response) => {
        setEstimate(response.data.data);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!estimate) return;

    let ps:ProductDetails = [];

    ps = estimate.products?.map((estimateProduct) => {
      return {
        productId: estimateProduct.productId._id,
        quantity: estimateProduct.quantity,
        PCS: estimateProduct.quantity,
        districtForPrices: null,
        rateInclGST: null,
        bulkPackingValue1: null,
        bulkPackingValue2: null,
        appliedDiscount: null,
      };
    }) ?? null;

    setEstimateUserId(`${estimate.user?.id}` || null);
    setProductDetails(ps);
    setAddToggle(true);
  }, [estimate]);

  useEffect(() => {
    fetchEstimate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateId]);

  const years = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dn = new Date();

  interface CreatedAtProps {
    createdAt: string | number | Date;
  }

  const createdAt = (createdAt: CreatedAtProps['createdAt']): string => {
    const date = new Date(createdAt);
    let createdAtDate =
      date.getFullYear() === dn.getFullYear()
        ? date.getMonth() === dn.getMonth()
          ? date.getDate() === dn.getDate()
            ? `Today at ${date.toLocaleTimeString()}`
            : date.getDate() === dn.getDate() - 1
              ? `Yesterday at ${date.toLocaleTimeString()}`
              : years[date.getMonth()] +
              " " +
              date.getDate() +
              " at " +
              date.toLocaleTimeString()
          : years[date.getMonth()] +
          " " +
          date.getDate() +
          " at " +
          date.toLocaleTimeString()
        : years[date.getMonth()] + " " + date.getFullYear();

    return createdAtDate;
  };

  const handleInvoiceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceType(event.target.value);
  };

  const handleSalesList = async () => {
    await axiosInstance.get<SalesUsersApiResponse>("/admin/salesTeam").then((res) => {
      const arr: AutoCompleteOptions = [];
      for (let ele of res.data.data?.users) {
        arr.push({ label: ele?.name, Id: `${ele?.id}` });
      }
      setSalesList(arr);
    });
  };

  // show Modal popup
  const getModal = (searchItem: string) => {
    const outlineElement = document.getElementById("outline");
    if (outlineElement) {
      outlineElement.style.border = "1px solid rgb(206, 212, 218)";
    }
    setToggle(true);
    setSearchProduct(searchItem);
    const searchElement = document.getElementById("search");
    if(searchElement){
      (searchElement as HTMLInputElement).value = "";
    }
    setShowModal(true);
  };

  const handleShowPopup = () => {
    setConfirmShowPopup(true);
  };

  const hideConfirmPopup = (event: ConfirmPopupEvent) => {
    if (event.target.innerText === "Yes") {
      handleCreateOrder();
    }
    setConfirmShowPopup(false);
  };

  // AddProductButton&HideModal
  const addHideModal = async () => {
    setSearchProduct("");
    productIdArr.splice(0, productIdArr.length);
    setVariant(0);
    setShowModal(false);
    setAddToggle(true);

    if (productNew.length > 0) {
      handleEnableSelect();
    }

    //if array have elements then find all child elements
    if (productDetails) {
      const newProducts = productNew;
      newProducts.reverse();
      const prevProduct = productDetails;
      let arr = newProducts;

      for (let prev of prevProduct) {
        for (let newProd of newProducts) {
          if (newProd.productId === prev.productId) {
            prev.quantity += +newProd?.quantity || 1;
            prev.PCS += +newProd?.PCS || 1;
            prev.districtForPrices = newProd?.districtForPrices
            const index = arr.findIndex(
              (product) => product.productId === newProd.productId
            );
            arr.splice(index, 1);
          }
        }
      }

      let newArray = [];
      if (arr.length !== 0) {
        newArray.push(...arr, ...prevProduct);
        setProductId(newArray);
        setProductDetails(newArray);
        setProductNew([]);
      } else {
        setProductId(prevProduct);
        setProductDetails([...prevProduct]);
        setProductNew([]);
      }
    } else {
      const reversedProductNew = productNew.reverse()
      setProductDetails(reversedProductNew);
      setProductNew([]);
    }
  };

  // hide modal popup
  const hideModal = () => {
    productIdArr.splice(0, productIdArr.length);
    setSearchProduct("");
    setVariant(0);
    productNew.splice(0, productNew.length);
    setShowModal(false);
  };

  // show TaxRate Popup
  const handleTaxRates = () => {
    setTaxRates(true);
  };

  // hide TaxRate Popup
  const hideTaxPopup = () => {
    setTaxRates(false);
  };

  // show discount popup
  const showDiscountPopup = () => {
    document.getElementById("selectDiscountType");
    setDiscountModal(true);
  };

  // hide Discount popup
  const hideDiscountPopup = () => {
    setDiscountModal(false);
  };

  const shippingPopup = () => {
    setShippingModal(true);
  };

  const hideShippingPopup = () => {
    setPreviousTarget([]);
    setShippingModal(false);
  };

  document.addEventListener("mouseup", function (e) {
    let container = document.getElementById("outline");
    if (container !== null) {
      if (!container.contains(e.target as Node)) {
        container.style.border = "1px solid rgb(206, 212, 218)";
      }
    }
  });

  const handleOutline = () => {
    const outlineElement = document.getElementById("outline");
    if (outlineElement) {
      outlineElement.style.border = "2px solid #458fff";
    }
  };

  // remove product from array
  const updateArr = (Id: string) => {
    let arr:ProductDetails = productDetails;
    if(arr){
      let store = arr.filter((ele) => ele.productId !== Id);
      if (store.length !== 0) {
        setProductId(store);
        setProductDetails(store);
        setAddToggle(true);
      } else {
        setProductId(store);
        setProductDetails(store);
        handleDisableSelect();
        setOverAllDiscount(null);
        setShippingPrice(null);
        setPreviousTarget([]);
      }
    }
  };

  // increase qty from product
  const incQty = (Id: string) => {
    let arr: ProductDetails= productDetails;
    console.log("incQty -> arr", {productDetails, Id})
    if(arr){
      for (let ele of arr) {
        if (ele.productId === Id) {
          ele.quantity = ele.quantity + 1;
          ele.PCS = ele.PCS + 1;
          let variant = document.getElementById(ele.productId);
          if (variant?.children[0]?.children[1]?.lastChild) {
            (variant.children[0].children[1].lastChild as HTMLElement).style.display = "none";
          }
          const draftOrder = document.getElementById("draftOrderBtn");
          if (draftOrder !== null) {
            (draftOrder as HTMLButtonElement).disabled = false;
          }
          const createOrder = document.getElementById("createOrderBtn");
          if (createOrder !== null) {
            (createOrder as HTMLButtonElement).disabled = false;
          }
        }
        setAddToggle(true);
        setProductId(arr);
        setProductDetails([...arr]);
      }
    }
  };

  // decrease qty from product
  const decQty = (Id: string) => {
    let arr: ProductDetails= productDetails;
    if(arr){
      const index = arr.findIndex(
        (variantIndex) => variantIndex.productId === Id
      );
      if (arr[index].PCS !== 1 && arr[index].PCS !== 0) {
        arr[index].quantity = arr[index].quantity - 1;
        arr[index].PCS = arr[index].PCS - 1;
      }
  
      setAddToggle(true);
      setProductId(arr);
      setProductDetails([...arr]);
    }
  };

  // increase qty from product
  const incQtyBulkPackaging = (Id: string, bulkPackagingValue:number, type:string) => {
    console.log("incQtyBulkPackaging -> arr", {productDetails, Id, bulkPackagingValue, type})
    let arr:ProductDetails = productDetails;
    if(arr){
      for (let ele of arr) {
        if (ele.productId === Id) {
          if (type === "bulkPackingValue1") {
            ele.quantity = ele.quantity + bulkPackagingValue;
            ele.bulkPackingValue1 = ele.bulkPackingValue1
              ? ele.bulkPackingValue1 + 1
              : 1;
          } else {
            ele.quantity = ele.quantity + bulkPackagingValue;
            ele.bulkPackingValue2 = ele.bulkPackingValue2
              ? ele.bulkPackingValue2 + 1
              : 1;
          }
  
          let variant = document.getElementById(ele.productId) as HTMLElement;
          if(variant?.children[0]?.children[1]?.lastChild){
            (variant.children[0].children[1].lastChild as HTMLElement).style.display = "none";
          }
          const draftOrder = document.getElementById("draftOrderBtn");
          if (draftOrder !== null) {
            (draftOrder as HTMLButtonElement).disabled = false;
          }
          const createOrder = document.getElementById("createOrderBtn");
          if (createOrder !== null) {
            (createOrder as HTMLButtonElement).disabled = false;
          }
        }
      }
    }

    setAddToggle(true);
    setProductId(arr);
    if(arr){
      setProductDetails([...arr]);
    }
  };

  const decQtyBulkPackaging = (Id: string, bulkPackagingValue:number, type:string) => {
    let arr = productDetails;
    if(arr){
      for (let ele of arr) {
        if (ele.productId === Id) {
          if (ele.quantity !== 1) {
            if (type === "bulkPackingValue1") {
              ele.quantity =
                ele.bulkPackingValue1 && ele.bulkPackingValue1 > 0
                  ? ele.quantity - bulkPackagingValue
                  : ele.quantity;
              ele.bulkPackingValue1 =
                typeof ele.bulkPackingValue1 === "number" &&
                ele.bulkPackingValue1 > 0
                  ? ele.bulkPackingValue1 - 1
                  : 0;
            } else {
              ele.quantity =
                ele.bulkPackingValue2 && ele.bulkPackingValue2 > 0
                  ? ele.quantity - bulkPackagingValue
                  : ele.quantity;
              ele.bulkPackingValue2 = 
                typeof ele.bulkPackingValue2 === "number" &&
                ele.bulkPackingValue2 > 0 
                  ? ele.bulkPackingValue2 - 1
                  : 0;
            }
          }
        }
      }

      setAddToggle(true);
      setProductId(arr);
      setProductDetails([...arr]);
    }
  };

  // Handle Manually Percentage
  const handleEditPercentage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyboardEvent = event.nativeEvent as KeyboardEvent;
    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.preventDefault();
      event.currentTarget.blur();
      return;
    } 
  };

  // Handle Blur Percentage
  const handleBlurPercentage = (
    event: React.FocusEvent<HTMLInputElement>,
    Id: string,
    MRP: string,
    GST: string,
  ) => {
    const limitDiscount = event.target.parentNode?.parentNode?.parentNode?.lastChild as HTMLElement;
    const storeClassName = limitDiscount?.getAttribute("class")?.split(" ")[1] || null;
    if (event.target.value) {
      if (parseFloat(event.target.value) <= 100) {
        setStorePrevDiscountId(storeClassName);
        const discountPercent = (limitDiscount?.lastChild as HTMLElement)?.children;
        const percentage = parseFloat(event.target.value);
        if (parseFloat((discountPercent?.[1] as HTMLElement)?.innerText) <= percentage) {
          if (event?.target?.value?.length !== 0) {
            // It will now display value iserted by user instead of calculating discount percentage from formula
            const variantId = (event.target.closest(".productVariant") as HTMLElement).id;
            const percentageDiscount = event?.target?.value;
          }
          limitDiscount.style.display = "none";
          const mrp = parseFloat(MRP);
          const gst = parseInt(GST);
          const discountAmount = -1 * (((percentage - 100) * mrp) / (100 * (1 + gst / 100)));
          
          let discount: LineItemDiscount = {
            appliedDiscount: {
              value: discountAmount,
              valueType: "FIXED_AMOUNT",
              title: "MANUAL",
              description: "MANUAL",
            },
          };

          const rateInclGST = discountAmount * (1 + gst / 100);
          let arr = productDetails;
          if(arr){
            for (let ele of arr) {
              if (ele.productId === Id) {
                ele.rateInclGST = rateInclGST;
                Object.assign(ele, discount);
              }
            }
            if (updateDiscountPercent !== undefined) {
              if (updateDiscountPercent.length !== 0) {
                for (let element of updateDiscountPercent) {
                  if (element !== Id) {
                    const array = [element, Id];
                    setUpdateDiscountPercent([...array]);
                  }
                }
              } else {
                setUpdateDiscountPercent([Id]);
              }
            }
  
            setTimeout(() => {
              event.target.value = "";
            }, 1500);
  
            setAddToggle(true);
            setProductId(arr);
            setProductDetails([...arr]);
          }
        } else {
          limitDiscount.style.display = "block";
        }
      } else {
        const targetElement = document.getElementById(event.target.id);
        if (targetElement) {
          (targetElement as HTMLInputElement).value = '';
        }
        alert('Discount Must be Smaller Than 100%');
      }
    } else {
      limitDiscount.style.display = "none";
    }
  };

  const handleEdit = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((document.activeElement as HTMLElement)?.isContentEditable) {
      if (event.key === 'Enter') {
        event.preventDefault();
        (event.target as HTMLDivElement).blur();
        return;
      } else {
        preventValueWithoutDecimal(event);
      }
    }
  };

  // handle Blur Rate(Price)
  const handleBlur = (event: HandleBlurEvent, Id: string, gst: GstPercentage, listingPrice: string) => {
    const variantId = Id;
    const percentageDiscount = 0;
    if (parseFloat(event.target.innerText) !== parseFloat(listingPrice)) {
      if (parseFloat(listingPrice) >= parseFloat(event.target.innerText)) {
        let array = updateDiscountPercent;
        let store;
        if (array !== undefined) {
          store = array.filter((ele) => ele !== Id);
        }

        setUpdateDiscountPercent(store || []);
        const userAmount = JSON.parse(event.target.innerText);
        const rateInclGST = userAmount * (1 + parseFloat(gst) / 100);

        const checkDecimal = countPlaces(rateInclGST);
        const InclGstPrice =
          checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2
            ? rateInclGST
            : Math.trunc(rateInclGST * 100) / 100;

        const discount: LineItemDiscount = {
          appliedDiscount: {
            value: userAmount,
            valueType: "FIXED_AMOUNT",
            title: "MANUAL",
            description: "MANUAL",
          },
        };
        let arr: ProductDetails = productDetails;
        if (arr) {
          for (let ele of arr) {
            if (ele.productId === Id) {
              ele.rateInclGST = InclGstPrice;
              Object.assign(ele, discount);
            }
          }
          const fetchParent = event.target;
          const childNode =
            fetchParent.parentNode?.parentNode?.parentNode?.children[4];
          if (childNode) {
            (childNode as HTMLElement).style.display = "none";
          }
          if (customerDetails) {
            if (selectedShippingAddress !== null) {
              const submitBtn = document.getElementById(
                "submitBtn"
              ) as HTMLButtonElement;
              const createOrderBtn = document.getElementById(
                "createOrderBtn"
              ) as HTMLButtonElement;
              if (submitBtn !== null) {
                submitBtn.disabled = false;
              }
              createOrderBtn.disabled = false;
              const draftOrder = document.getElementById(
                "draftOrderBtn"
              ) as HTMLButtonElement;
              if (draftOrder !== null) {
                draftOrder.disabled = false;
              }
            }
          }
          setAddToggle(true);
          setProductId(arr);
          setProductDetails([...arr]);
      }
      } else {
        const fetchParent = event.target;
        const childNode =
          fetchParent.parentNode?.parentNode?.parentNode?.children[4];
        if (childNode) {
          (childNode as HTMLElement).style.display = "block";
        }
        if (customerDetails) {
          if (selectedShippingAddress !== null) {
            const submitBtn = document.getElementById("submitBtn");
            if (submitBtn !== null) {
              const submitBtn = document.getElementById("submitBtn");
              if (submitBtn !== null) {
                (submitBtn as HTMLButtonElement).disabled = true;
              }
            }
            const createOrderBtn = document.getElementById("createOrderBtn");
            if (createOrderBtn) {
              (createOrderBtn as HTMLButtonElement).disabled = true;
            }
            const draftOrder = document.getElementById("draftOrderBtn");
            if (draftOrder !== null) {
              (draftOrder as HTMLButtonElement).disabled = true;
            }
          }
        }
      }
    }
  };

  // handle Blur Rate Incl GST(Price)
  const handleBlurRateInclGst = (
    event: HandleBlurEvent,
    Id:string,
    listingPrice: string,
    gst: GstPercentage,
    inclPrice: number
  ) => {
    const result = handleInclGst(inclPrice);
    const inclGstRateInput = event.target.innerText; 
    if (result !== parseFloat(inclGstRateInput)) {
      const inclGstListingPrice:number = Number(listingPrice) * (1 + +gst / 100);
      if (inclGstListingPrice >= parseFloat(inclGstRateInput)) {
        let array = updateDiscountPercent;
        let store;
        if (array !== undefined) {
          store = array.filter((ele) => ele !== Id);
        }

        setUpdateDiscountPercent(store || []);
        const rateExclGst = +inclGstRateInput / (1 + +gst / 100);
      
        const discount = {
          appliedDiscount: {
            value: rateExclGst,
            valueType: "FIXED_AMOUNT",
            title: "MANUAL",
            description: "MANUAL",
          },
        };
        let arr = productDetails;
        if(arr){
          for (let ele of arr) {
            if (ele.productId === Id) {
              ele.rateInclGST = +inclGstRateInput;
              Object.assign(ele, discount);
            }
          }
  
          const fetchParent = event.target;
          const childNode =
            fetchParent?.parentNode?.parentNode?.parentNode?.children[4];
          if (childNode) {
            (childNode as HTMLElement).style.display = "none";
          }
          if (customerDetails) {
            if (selectedShippingAddress !== null) {
              const submitBtn = document.getElementById("submitBtn");
              if (submitBtn !== null) {
                const submitBtn = document.getElementById("submitBtn");
                if (submitBtn !== null) {
                  (submitBtn as HTMLButtonElement).disabled = false;
                }
              }
              const createOrderBtn = document.getElementById("createOrderBtn");
              if (createOrderBtn) {
                (createOrderBtn as HTMLButtonElement).disabled = false;
              }
              const draftOrder = document.getElementById("draftOrderBtn");
              if (draftOrder !== null) {
                (draftOrder as HTMLButtonElement).disabled = false;
              }
            }
          }
          setAddToggle(true);
          setProductId(arr);
          setProductDetails([...arr]);
        }
      } else {
        const fetchParent = event.target;
        const childNode = fetchParent.parentNode?.parentNode?.parentNode?.children[4];
        if (childNode) {
          (childNode as HTMLElement).style.display = "block";
        }
        if (customerDetails) {
          if (selectedShippingAddress !== null) {
            const submitBtn = document.getElementById("submitBtn");
            if (submitBtn !== null) {
              (document.getElementById("submitBtn") as HTMLButtonElement).disabled = true;
            }
            (document.getElementById("createOrderBtn") as HTMLButtonElement).disabled = true;
            const draftOrder = document.getElementById("draftOrderBtn");
            if (draftOrder !== null) {
              (draftOrder as HTMLButtonElement).disabled = true;
            }
          }
        }
      }
    }
  };

  // handle Blur Qty
  const handleBlurQty = (
    event: string,
    Id: string,
    bulkPacking1: number,
    bulkPacking2: number,
    restrictMsgId: string
  ) => {
    if (event === "" || event === "0") {
      const restrictMsgElement = document.getElementById(restrictMsgId);
      if (restrictMsgElement) {
        restrictMsgElement.style.display = "none";
      }
      let arr = productDetails;
      if(arr){
        for (let ele of arr) {
          if (ele.productId === Id) {
            let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
            let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
            ele.quantity = 0 + packing1 + packing2;
            ele.PCS = 0;
          }
        }
  
        setAddToggle(true);
        setProductId(arr);
        setProductDetails([...arr]);
      }
    } else {
      if (event) {
        if (Number(event) < 0) {
          const restrictMsgElement = document.getElementById(restrictMsgId);
          if (restrictMsgElement) {
            restrictMsgElement.style.display = "block";
          }
          const draftOrder = document.getElementById("draftOrderBtn");
          if (draftOrder !== null) {
            (draftOrder as HTMLButtonElement).disabled = true;
          }
          const createOrder = document.getElementById("createOrderBtn");
          if (createOrder !== null) {
            (createOrder as HTMLButtonElement).disabled = true;
          }
        } else {
          const restrictMsgElement = document.getElementById(restrictMsgId);
          if (restrictMsgElement) {
            restrictMsgElement.style.display = "none";
          }
          const pro = JSON.parse(event);
          let arr = productDetails;
          if(arr){
            for (let ele of arr) {
              if (ele.productId === Id) {
                let packing1 = ele.bulkPackingValue1 ? ele.bulkPackingValue1 * bulkPacking1 : 0;
                let packing2 = ele.bulkPackingValue2 ? ele.bulkPackingValue2 * bulkPacking2 : 0;
                ele.quantity = pro + packing1 + packing2;
                ele.PCS = pro;
                if (pro > 0) {
                  let variant = document.getElementById(ele.productId);
                  if(variant?.children[0]?.children[1]?.lastChild){
                    (variant.children[0].children[1].lastChild as HTMLElement).style.display = "none";
                  }
                  const draftOrder = document.getElementById("draftOrderBtn");
                  if (draftOrder !== null) {
                    (draftOrder as HTMLButtonElement).disabled = false;
                  }
                  const createOrder = document.getElementById("createOrderBtn");
                  if (createOrder !== null) {
                    (createOrder as HTMLButtonElement).disabled = false;
                  }
                }
              }
            }
  
            setAddToggle(true);
            setProductId(arr);
            setProductDetails([...arr]);
          }
        }
      }
    }
  };

  const handleBlurBulkPackagingQty1 = (
    event: string,
    Id: string,
    bulkPackagingValue: number,
    bulkPackagingValue2: number,
    restrictMsgId: string
  ) => {
    if (event === "" || event === "0") {
      (document.getElementById(restrictMsgId) as HTMLElement).style.display =
        "none";
      let arr = productDetails;
      if (arr) {
        for (let ele of arr) {
          if (ele.productId === Id) {
            let packing1 =
              bulkPackagingValue2 !== undefined && ele.bulkPackingValue2
                ? ele.bulkPackingValue2 * bulkPackagingValue2
                : 0;
            ele.quantity = ele.PCS + 0 + packing1;
            ele.bulkPackingValue1 = 0;
          }
        }

        setAddToggle(true);
        setProductId(arr);
        setProductDetails([...arr]);
      }
    } else {
      if (event) {
        if (+event < 0) {
          (
            document.getElementById(restrictMsgId) as HTMLElement
          ).style.display = "block";
          const draftOrder = document.getElementById("draftOrderBtn");
          if (draftOrder !== null) {
            (draftOrder as HTMLButtonElement).disabled = true;
          }
          const createOrder = document.getElementById("createOrderBtn");
          if (createOrder !== null) {
            (createOrder as HTMLButtonElement).disabled = true;
          }
        } else {
          (
            document.getElementById(restrictMsgId) as HTMLElement
          ).style.display = "none";
          const pro = JSON.parse(event);
          let arr = productDetails;
          if (arr) {
            for (let ele of arr) {
              if (ele.productId === Id) {
                let packing2 =
                  bulkPackagingValue2 !== undefined && ele.bulkPackingValue2
                    ? ele.bulkPackingValue2 * bulkPackagingValue2
                    : 0;
                ele.quantity = ele.PCS + pro * bulkPackagingValue + packing2;
                ele.bulkPackingValue1 = pro;
                if (pro > 0) {
                  let variant = document.getElementById(ele.productId);
                  if (variant?.children[0]?.children[1]?.lastChild) {
                    (
                      variant.children[0].children[1].lastChild as HTMLElement
                    ).style.display = "none";
                  }
                  const draftOrder = document.getElementById("draftOrderBtn");
                  if (draftOrder !== null) {
                    (draftOrder as HTMLButtonElement).disabled = false;
                  }
                  const createOrder = document.getElementById("createOrderBtn");
                  if (createOrder !== null) {
                    (createOrder as HTMLButtonElement).disabled = false;
                  }
                }
              }
            }

            setAddToggle(true);
            setProductId(arr);
            setProductDetails([...arr]);
          }
        }
      }
    }
  };

  const handleBlurBulkPackagingQty2 = (
    event:string,
    Id: string,
    bulkPackagingValue: number,
    bulkPackagingValue1: number,
    restrictMsgId: string
  ) => {
    if (event === "" || event === "0") {
      (document.getElementById(restrictMsgId) as HTMLElement).style.display = "none";
      let arr = productDetails;
      if(arr){
        for (let ele of arr) {
          if (ele.productId === Id) {
            let packing1 =
              bulkPackagingValue1 !== undefined && ele.bulkPackingValue1
                ? ele.bulkPackingValue1 * bulkPackagingValue1
                : 0;
            ele.quantity = ele.PCS + 0 + packing1;
            ele.bulkPackingValue2 = 0;
          }
        }
  
        setAddToggle(true);
        setProductId(arr);
        setProductDetails([...arr]);
      }
    } else {
      if (+event < 0) {
        (document.getElementById(restrictMsgId) as HTMLElement).style.display = "block";
        const draftOrder = document.getElementById("draftOrderBtn");
        if (draftOrder !== null) {
          (draftOrder as HTMLButtonElement).disabled = true;
        }
        const createOrder = document.getElementById("createOrderBtn");
        if (createOrder !== null) {
          (createOrder as HTMLButtonElement).disabled = true;
        }
      } else {
        (document.getElementById(restrictMsgId) as HTMLElement).style.display = "none";
        const pro = JSON.parse(event);
        let arr = productDetails;
        if(arr){
          for (let ele of arr) {
            if (ele.productId === Id) {
              let packing1 =
                bulkPackagingValue1 !== undefined && ele.bulkPackingValue1
                  ? ele.bulkPackingValue1 * bulkPackagingValue1
                  : 0;
              ele.quantity = ele.PCS + pro * bulkPackagingValue + packing1;
              ele.bulkPackingValue2 = pro;
              if (pro > 0) {
                let variant = document.getElementById(ele.productId);
                if(variant?.children[0]?.children[1]?.lastChild){
                  (variant.children[0].children[1].lastChild as HTMLElement).style.display = "none";
                }
                const draftOrder = document.getElementById("draftOrderBtn");
                if (draftOrder !== null) {
                  (draftOrder as HTMLButtonElement).disabled = false;
                }
                const createOrder = document.getElementById("createOrderBtn");
                if (createOrder !== null) {
                  (createOrder as HTMLButtonElement).disabled = false;
                }
              }
            }
          }
  
          setAddToggle(true);
          setProductId(arr);
          setProductDetails([...arr]);
        }
      }
    }
  };

  // handle Discount
  const handleDiscount = ({ mrp, discountedPrice, gst }: HandleDiscountParams): number => {
    const MRP = parseFloat(mrp);
    const DP = parseFloat(discountedPrice);
    const GST = parseInt(gst);
    const Discount = (MRP - DP * (1 + GST / 100)) / MRP;
    const amount = Discount * 100;
    const result = +amount.toFixed(2);
    return result;
  };

  // showLimitDiscount

  const showLimitDiscount = ({ mrp, originalUnitPrice, gst }: ShowLimitDiscountParams): number => {
    const MRP = parseInt(mrp);
    const DP = parseFloat(originalUnitPrice);
    const GST = parseInt(gst);
    const result = (MRP - DP * (1 + GST / 100)) / MRP;
    const TotalDiscount = result * 100;
    const decimalPlaces = countPlaces(TotalDiscount);
    let results =
      decimalPlaces === 1 || decimalPlaces === 0 || decimalPlaces === 2
        ? TotalDiscount
        : Math.trunc(TotalDiscount * 100) / 100;
    return results;
  };

  // Select Role
  const handleRole = () => {
    if(overAllDiscount?.description === "Special Discount"){
      setOverAllDiscount(null);
    }
    if (storePrevDiscountId) {
      const classDiscount = document.getElementsByClassName(
        storePrevDiscountId
      ) as HTMLCollectionOf<HTMLElement>;
      if (classDiscount) {
        classDiscount[0].style.display = "none";
      }
    }
    if(showProduct){
      for (let product of showProduct.lineItems) {
        //This Update Selected Role Discount for a single product
        updateProductRoleDiscount(product);
      }
    }
    if(productDetails){
      setProductDetails([...productDetails]);
    }
    setProductId(productDetails);
    setAddToggle(true);
    setDiscountPercent([]);
    setUpdateDiscountPercent([]);
  };

  const roleTypes = {
    end_user: "endUser",
    special_retailer: "specialRetailer",
    oem : "oem",
    interior : "interior",
    retailer : "retailer",
    promoter: "promoter",
    "DEFAULT": undefined,
  };

  //This Update Selected Role Discount for a single product
  const updateProductRoleDiscount  = (product:CalculationServiceResponse["product"]["lineItems"][0]) => {
      //handling Role And PriceKeyOfRole
      let role;
      if (roleTypes[selectedRoleForDiscount])
        role = roleTypes[selectedRoleForDiscount];
  
      let selectedRolePriceKey: "retailPrice" |
       "specialRetailPrice" |
       "oemPrice" |
       "interiorPrice" |
       "retailerPrice" | 
       "promoterPrice" | 
       `${string}Price` |
       undefined;
      if (selectedRoleForDiscount === "retailer") {
        selectedRolePriceKey = "retailPrice";
      }
      else if (selectedRoleForDiscount === "special_retailer") {
        selectedRolePriceKey = "specialRetailPrice"
      } else {
        selectedRolePriceKey = `${snakeToCamelCase(selectedRoleForDiscount)}Price`;
      }
      let oldSelectedRolePriceKey = selectedRolePriceKey;
      let oldRole = role;
      if(!product.priceDataAffiliateData.hasOwnProperty(selectedRolePriceKey) 
        && (selectedRolePriceKey === "specialRetailPrice" && product.priceDataAffiliateData.hasOwnProperty("retailPrice"))
      ) {
        selectedRolePriceKey="retailPrice";
        role = 'retailer'
      }

      if (product.priceDataAffiliateData.hasOwnProperty(selectedRolePriceKey)) {
        for (const [key, value] of Object.entries( product.priceDataAffiliateData )) {
          if (key === selectedRolePriceKey && productDetails) {
            const index:number = productDetails.findIndex(
              (variantIndex) => variantIndex.productId === product.product_id
            );
            if (value) {
              const rateInclGST =
                value *
                (1 + parseFloat(product?.priceDataAffiliateData?.gst) / 100);
              const checkDecimal = countPlaces(rateInclGST);
              const InclGstPrice =
                checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2
                  ? rateInclGST
                  : Math.trunc(rateInclGST * 100) / 100;
              productDetails[index].rateInclGST = InclGstPrice;
              if(role){
                if (productDetails[index].appliedDiscount) {
                  productDetails[index].appliedDiscount.value = value;
                  productDetails[index].appliedDiscount.title =
                    role.toUpperCase();
                  productDetails[index].appliedDiscount.description =
                    role.toUpperCase();
                } else {
                  productDetails[index].appliedDiscount = {
                    value: value,
                    valueType: "FIXED_AMOUNT",
                    title: role.toUpperCase(),
                    description: role.toUpperCase(),
                  };
                }
              }
            } else {
              delete productDetails[index].appliedDiscount;
            }
            const variantId = product["product_id"];
            const percentageDiscount = productDetails[index][selectedRolePriceKey as keyof ProductDetail];
          }
        }
      } else if (productDetails) {
        const index = productDetails.findIndex(
          (variantIndex) => variantIndex.productId === product.product_id
        );
        delete productDetails[index].appliedDiscount;
      }
      selectedRolePriceKey = oldSelectedRolePriceKey;
      role = oldRole;
  }

  const handleDisableSelect = () => {
    const $handleSelect = document.getElementById("selectToggle") as HTMLSelectElement;
    $handleSelect.value = "DEFAULT";
    $handleSelect.disabled = true;
  };

  const handleEnableSelect = () => {
    const $handleSelect = document.getElementById("selectToggle") as HTMLSelectElement;
    if ($handleSelect !== null) {
      $handleSelect.value = "DEFAULT";
      $handleSelect.disabled = false;
    }
  };

  // Open Window Print
  const handleSendPI = (event: React.MouseEvent<HTMLButtonElement>) => {
    (event.target as HTMLButtonElement).disabled = true;
    const gstRate = document.getElementsByClassName("gstRate");
    const contactBox = document.getElementById("customerHeader");
    const customerBox = document.getElementById("customerBox");
    const contactInformationBox = document.getElementById(
      "contactInformationBox"
    );
    const contactInformationHeader = document.getElementById(
      "contactInformationHeader"
    );
    if (contactBox !== null && customerBox !== null) {
      if (gstNumber?.length !== 0) {
        contactBox.style.marginBottom = "0.5rem";
        customerBox.style.padding = "0.6rem 1.1rem";
        (contactInformationHeader as HTMLElement).style.marginBottom = "0.5rem";
        (contactInformationBox as HTMLElement).style.padding = "0.6rem 1.1rem";
        (contactInformationBox as HTMLElement).style.borderBottom = "1px solid #DBDDE0";
      }
    }

    for (let ele of gstRate) {
      onbeforeprint = function() {
        (ele as HTMLElement).style.display = "none";
      };
    }

    const bills = document.getElementById("bill");
    const billBox = document.getElementById("billBox");
    const challanBox = document.getElementById("challanBox");
    const challan = document.getElementById("challan");
    if (bills && !(bills as HTMLInputElement).checked) {
      (billBox as HTMLInputElement).style.display = "none";
    }

    if (challan && !(challan as HTMLInputElement).checked) {
      (challanBox as HTMLInputElement).style.display = "none";
    }

    const discountHide = document.getElementById("noDiscountAvailPrint");
    if (overAllDiscount) {
      (discountHide as HTMLElement).style.display = "none";
    }

    if (sendPIDraftOrder !== null) {
      onbeforeprint = () => {
        document.title = sendPIDraftOrder?.draftOrderName;
      };
    }

    setTimeout(() => {
      window.print();
      onafterprint = () => {
      document.title = "DEPO24 PORTAL";
      (event.target as HTMLButtonElement).disabled = false;
      (discountHide as HTMLElement).style.display = "flex";
      (challanBox as HTMLElement).style.display = "flex";
      (billBox as HTMLElement).style.display = "flex";
      if (contactBox !== null && customerBox !== null) {
        contactBox.style.marginBottom = "1.5rem";
        customerBox.style.padding = "1.1rem";
        (contactInformationHeader as HTMLElement).style.marginBottom = "1.5rem";
        (contactInformationBox as HTMLElement).style.padding = "1.1rem";
        (contactInformationBox as HTMLElement).style.borderBottom = "0";
      }
      }
    }, 500);

    const handle = (gst: HTMLCollectionOf<Element>) => {
      for (let ele of gst) {
      (ele as HTMLElement).style.display = "flex";
      }
    };

    onafterprint = function () {
      handle(gstRate);
    };
  };

  const [loadingForDraftOrder, setLoadingForDraftOrder] = useState(false);
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState<React.MouseEvent<HTMLButtonElement> | null>(null);

  const sendPaymentLink = (company: string) => {
    if(event && paymentGateway && amount){
      if (!Id) {
        handleCreateDraftOrder(event, paymentGateway, amount, company)
      } else {
        handleUpdateOrder(event, paymentGateway, amount, company)
      }
    }
  }

  // Create draftOrder(Save as Draft Order)
  const handleCreateDraftOrder = (event?:React.MouseEvent<HTMLButtonElement>, pg?:string, amount?: number, company?: string) => {
    if (seller && !sellerBillingAddress) {
      alert("Please add seller billing address");
      return;
    }
    const variantLineItems = productDetails ? [...productDetails] : [];
    if(variantLineItems){
      for (let ele of variantLineItems) {
        if (ele.quantity === 0) {
          let variant = document.getElementById(ele.productId);
          (variant as HTMLElement).scrollIntoView();
          if(variant?.children[0]?.children[1]?.lastChild){
            (variant.children[0].children[1].lastChild as HTMLElement).style.display = "block";
          }
          if(event) (event.target as HTMLButtonElement).disabled = true;
          return;
        }
      }
    }

    if (pg) {
      if(event) (event.target as HTMLButtonElement).innerHTML = `Send Payment Link ...`;
    } else if (event) {
      if(event) (event.target as HTMLButtonElement).innerHTML = "Draft Order Created...";
    }

    const userId = customerDetails?.id;

    let metafield;
    if (gstNumber && gstNumber !== "remove") {
      metafield = [
        {
          namespace: "custom",
          key: "gstin",
          value: Array.isArray(gstNumber) ? gstNumber?.[0] : gstNumber,
          type: "single_line_text_field",
        },
      ];
    }

    setLoadingForDraftOrder(true);
    if(variantLineItems){
      for (let product of variantLineItems) {
        if(showProduct){
          const index = showProduct?.lineItems?.findIndex(
            (variantIndex) => variantIndex?.product_id === product?.productId
          );
          product.totalTax = showProduct?.lineItems[index]?.totalTax;
          product.subTotal = showProduct?.lineItems[index]?.productTotal;
          product.overallDiscountedTotal =
            showProduct?.lineItems[index]?.overallDiscountedTotal;
          product.discountedTotal = showProduct?.lineItems[index]?.discountedTotal;
          product.discountPercentage =
            showProduct.lineItems[index]?.discountPercentage;
          product.rateExclGST = showProduct?.lineItems[index]?.rateExclGST;
          product.rateInclGST = showProduct?.lineItems[index]?.rateInclGST;
        }
      }
    }

    const address = selectedShippingAddress;
    const addressObject: { [key: string]: string } = {};
    for (const key in address) {
      if (
        key !== "_id" &&
        key !== "__v" &&
        key !== "user" &&
        key !== "addressType" &&
        key !== "default" &&
        key !== "createdAt" && 
        key !== "billingCode"
        // && !(key === 'address2' && (address[key] === 'null' || address[key] === null))
      ) {
        addressObject[key as keyof DoCustomerAddress] = `${address[key as keyof DoCustomerAddress]}`;
      }
    }

    const billingAddressObject: { [key: string]: string } = {};
        
        if (selectedBillingAddress !== null) {
        let billingAddress = selectedBillingAddress;
    
        for (const key in billingAddress) {
          if (
            key !== "_id" &&
            key !== "__v" &&
            key !== "user" &&
            key !== "addressType" &&
            key !== "default" &&
            key !== "createdAt"
            // && !(key === 'address2' && billingAddress[key] === 'null')
          ) {
            billingAddressObject[key as keyof DoCustomerAddress] = `${billingAddress[key as keyof DoCustomerAddress]}`;
          }
        }
      }

    let shippingLine = shippingPrice ? shippingPrice : null;
    let payment = paymentRemark ? paymentRemark : null;
    let delivery = deliveryRemark ? deliveryRemark : null;
    const data = {
      user: userId,
      lineItems: variantLineItems,
      billingAddress:
        Object.keys(billingAddressObject).length !== 0
          ? billingAddressObject
          : undefined,
      shippingAddress:
        Object.keys(addressObject).length !== 0 ? addressObject : undefined,
      ...(typeof sellerBillingAddress === 'object' && sellerBillingAddress !== null && { sellerBillingAddress: sellerBillingAddress }),
      ...(seller && { seller: seller }),
      tags: showSelectedTags,
      ...(shippingLine && {shippingLine: shippingLine}),
      ...(payment && {paymentRemark: payment}),
      ...(delivery && {deliveryRemark: delivery}),
      metafields: gstNumber ? metafield : undefined,
      appliedDiscount: overAllDiscount ? overAllDiscount : undefined,
      appliedCouponCode: couponCode || undefined,
      overallDiscountAmount: showProduct?.overAllDiscounts?.overallDiscountAmount,
      overallDiscountPercentage: showProduct?.overAllDiscounts?.overallDiscountPercentage,
      total: showProduct?.Total,
      subTotal: showProduct?.SubTotal,
      TaxAmount: showProduct?.TaxAmount ? showProduct?.TaxAmount : undefined,
      invoiceType: invoiceType ? invoiceType : undefined,
      TaxLines: taxLines,
      affiliateUser: affiliateUser ? +affiliateUser?.Id : undefined,
      affiliateUserName: affiliateUser ? affiliateUser?.label : undefined,
      salesUser: salesUser ? +salesUser?.Id : undefined,
      preSalesUser: preSalesUser ? +preSalesUser?.Id : undefined,
      salesUserName: salesUser ? salesUser.label : undefined,
      preSalesUserName: preSalesUser ? preSalesUser.label : undefined,
      estimateId: estimateId ? estimateId : undefined,
    };

    axiosInstance
      .post<DraftOrderCreateResponse>(`/orders/admin?${pg ? `paymentGatewayId=${pg}&amount=${amount}&company=${company}` : ''}`, { ...data })
      .then((res) => {
        if (pg) {
          if (event) {
            (event.target as HTMLButtonElement).innerHTML = `Send Payment Link`;
          }
        } else {
          if (event) {
            (event.target as HTMLButtonElement).innerHTML = "Draft Order Created";
          }
        }

          setIsCreateOrderPage(false);
          setSendPIDraftOrder(res?.data?.data);
          if (event) {
            (event.target as HTMLButtonElement).innerHTML = "Draft Order Created";
          }
          navigate(`/draftOrdersNew/${res?.data?.data?.draftOrderName}`);
      }).catch((err) => {
        console.log(err)
        alert("Error in creating Draft Order.. Please try again");
      }).finally(() => {
        setLoadingForDraftOrder(false);
      });
  };

  // Create New Order
  const handleCreateOrder = () => {

    if (seller && !sellerBillingAddress) {
      alert("Please add seller billing address");
      return;
    }
    
    let metafield;
    if (gstNumber?.length !== 0 && gstNumber !== "remove") {
      metafield = [
        {
          namespace: "custom",
          key: "gstin",
          value: Array.isArray(gstNumber) ? gstNumber?.[0] : gstNumber,
          type: "single_line_text_field",
        },
      ];
    }


    if (!metafield && !seller) {
      alert("Seller is required to create order as GST is not provided");
      return;
    }

    if(productDetails){
      for (let ele of productDetails) {
        if (ele.quantity === 0) {
          let variant = document.getElementById(ele.productId);
          (variant as HTMLElement).scrollIntoView();
          if(variant?.children[0]?.children[1]?.lastChild){
            (variant.children[0].children[1].lastChild as HTMLElement).style.display = "block";
          }
          (document.getElementById("createOrderBtn") as HTMLButtonElement).disabled = true;
          return;
        }
      }
    }

    setLoading(true);
    const submitBtn = document.getElementById("createOrderBtn");
    (submitBtn as HTMLButtonElement).disabled = true;
    (submitBtn as HTMLButtonElement).innerHTML = "Order Creating...";
    const userId = customerDetails?.id;

    

    let variantLineItems;
    if(productDetails && showProduct){
      for (let product of productDetails) {
        const index = showProduct.lineItems.findIndex(
          (variantIndex) => variantIndex.product_id === product.productId
        );
        product.totalTax = showProduct.lineItems[index].totalTax;
        product.subTotal = showProduct.lineItems[index].productTotal;
        product.overallDiscountedTotal =
          showProduct.lineItems[index]?.overallDiscountedTotal;
        product.discountedTotal = showProduct.lineItems[index]?.discountedTotal;
        product.discountPercentage =
          showProduct.lineItems[index]?.discountPercentage;
        product.rateExclGST = showProduct.lineItems[index]?.rateExclGST;
        product.rateInclGST = showProduct.lineItems[index]?.rateInclGST;
      }
      variantLineItems = productDetails;
    }
    const address = selectedShippingAddress;
    const addressObject: { [key: string]: string } = {};
    for (const key in address) {
      if (
        key !== "_id" &&
        key !== "__v" &&
        key !== "user" &&
        key !== "addressType" &&
        key !== "default" &&
        key !== "createdAt" && 
        key !== "billingCode"
        // && !(key === 'address2' && (address[key] === 'null' || address[key] === null))
      ) {
        addressObject[key as keyof DoCustomerAddress] = `${address[key as keyof DoCustomerAddress]}`;
      }
    }
    const billingAddressObject: { [key: string]: string } = {};

    if (selectedBillingAddress !== null) {
    let billingAddress = selectedBillingAddress;

    for (const key in billingAddress) {
      if (
        key !== "_id" &&
        key !== "__v" &&
        key !== "user" &&
        key !== "addressType" &&
        key !== "default" &&
        key !== "createdAt"
        // && !(key === 'address2' && billingAddress[key] === 'null')
      ) {
        billingAddressObject[key as keyof DoCustomerAddress] = `${billingAddress[key as keyof DoCustomerAddress]}`;
      }
    }
  }
    const data: DraftOrderCreatePayload = {
      user: userId,
      lineItems: variantLineItems,
      billingAddress: billingAddressObject,
      ...(sellerBillingAddress && { sellerBillingAddress: sellerBillingAddress }),
      ...(seller && { seller: seller }),
      shippingAddress: addressObject,
      tags: showSelectedTags,
      ...(shippingPrice && {shippingLine: shippingPrice || undefined}),
      ...(paymentRemark && {paymentRemark: paymentRemark || undefined}),
      ...(deliveryRemark && {deliveryRemark: deliveryRemark || undefined}),
      metafields: gstNumber ? metafield : undefined,
      appliedDiscount: overAllDiscount ? overAllDiscount : undefined,
      appliedCouponCode: couponCode || undefined,
      overallDiscountAmount: showProduct?.overAllDiscounts?.overallDiscountAmount,
      overallDiscountPercentage: showProduct?.overAllDiscounts?.overallDiscountPercentage,
      total: showProduct?.Total,
      subTotal: showProduct?.SubTotal,
      TaxLines: taxLines,
      TaxAmount: showProduct?.TaxAmount ? showProduct?.TaxAmount : undefined,
      invoiceType: invoiceType ? invoiceType : undefined,
      affiliateUser: affiliateUser ? +affiliateUser?.Id : undefined,
      affiliateUserName: affiliateUser ? affiliateUser?.label : undefined,
      salesUser: salesUser ? +salesUser.Id : undefined,
      preSalesUser: preSalesUser ? preSalesUser?.Id : undefined,
      salesUserName: salesUser ? salesUser.label : undefined,
      preSalesUserName: preSalesUser ? preSalesUser?.label : undefined,
      estimateId: estimateId
    };

    axiosInstance
      .post<DraftOrderCreateResponse, AxiosResponse<DraftOrderCreateResponse>, DraftOrderCreatePayload>("/orders/admin", data)
      .then((res) => {
        setDraftOrder(res?.data?.data);
        (submitBtn as HTMLButtonElement).innerHTML = "Order Created";
        setLoading(false);
      })
      .catch((err) => {
        alert("Error in creating Order.. Please try again");
        (submitBtn as HTMLButtonElement).innerHTML = "Create Order";
        (submitBtn as HTMLButtonElement).disabled = false;
        setLoading(false);
      });
  };

  // handleInclGSTSpecificProduct
  const handleInclGst = (rateInclGST:number) => {
    // const inclGstPrice = listingPrice* (1 + gst/100);
    const checkDecimal = countPlaces(rateInclGST);
    return checkDecimal === 1 || checkDecimal === 0 || checkDecimal === 2
      ? rateInclGST
      : Math.trunc(rateInclGST * 100) / 100;
  };

  // Update Exist Order
  const handleUpdateOrder = (event: React.MouseEvent<HTMLButtonElement>, pg?: string, amount?: number, company?:string) => {
    if (seller && !sellerBillingAddress) {
      alert("Please add seller billing address!");
      (event.target as HTMLButtonElement).innerHTML = "Update Order";
      (event.target as HTMLButtonElement).disabled = false;
      return;
    }
    if(productDetails){
      for (let ele of productDetails) {
        if (ele.quantity === 0) {
          let variant = document.getElementById(ele.productId);
          (variant as HTMLElement).scrollIntoView();
          if(variant?.children[0]?.children[1]?.lastChild){
            (variant.children[0].children[1].lastChild as HTMLElement).style.display = "block";
          }
          (event.target as HTMLButtonElement).disabled = true;
          return;
        }
      }
    }

    if (pg) {
      (event.target as HTMLButtonElement).innerHTML = `Send Payment Link ...`;
    } else if (event) {
      (event.target as HTMLButtonElement).innerHTML = "Order Updating...";
    }
    (event.target as HTMLButtonElement).disabled = true;
    
    const userId = customerDetails?.id;

    let metafield;

    if (customerDetails && customerDetails?.gst_numbers?.length > 0 && gstNumber !== "remove") {
      metafield = [
        {
          namespace: "custom",
          key: "gstin",
          value: Array.isArray(gstNumber) === false ? gstNumber : customerDetails?.gst_numbers?.[0],
          type: "single_line_text_field",
        },
      ];
    }
    let variantLineItems;
    if(productDetails && showProduct){
      for (let product of productDetails) {
        console.log({product, showProduct})
        const index = showProduct.lineItems.findIndex(
          (variantIndex) => variantIndex.product_id === product.productId
        );
        product.totalTax = showProduct.lineItems[index].totalTax;
        product.subTotal = showProduct.lineItems[index].productTotal;
        product.overallDiscountedTotal =
          showProduct.lineItems[index]?.overallDiscountedTotal;
        product.discountedTotal = showProduct.lineItems[index]?.discountedTotal;
        product.discountPercentage =
          showProduct.lineItems[index]?.discountPercentage;
        product.rateExclGST = showProduct.lineItems[index]?.rateExclGST;
        product.rateInclGST = showProduct.lineItems[index]?.rateInclGST;
      }
      variantLineItems = productDetails;
    }

    const address = selectedShippingAddress;
    const addressObject : {[key: string]: string} = {};
    for (const key in address) {
      if (
        key !== "_id" &&
        key !== "__v" &&
        key !== "user" &&
        key !== "addressType" &&
        key !== "default" &&
        key !== "createdAt"
        // && !(key === 'address2' && address[key] === 'null')
      ) {
        addressObject[key as keyof DoCustomerAddress] = `${address[key as keyof DoCustomerAddress]}`;
      }
    }

    const billingAddressObject:{[key: string]: string} = {};

    if (selectedBillingAddress !== null) {
    let billingAddress = selectedBillingAddress;

    for (const key in billingAddress) {
      if (
        key !== "_id" &&
        key !== "__v" &&
        key !== "user" &&
        key !== "addressType" &&
        key !== "default" &&
        key !== "createdAt"
        // && !(key === 'address2' && billingAddress[key] === 'null')
      ) {
        billingAddressObject[key as keyof DoCustomerAddress] = `${billingAddress[key as keyof DoCustomerAddress]}`;
      }
    }
  }
  if((!sellerBillingAddress || !seller) && !metafield){
    alert("Seller is required to update order as GST is not provided");
    (event.target as HTMLButtonElement).innerHTML = "Update Order";
    (event.target as HTMLButtonElement).disabled = false;
    return;
  }
    const data = {
      user: userId,
      lineItems: variantLineItems,
      billingAddress:
        Object.keys(billingAddressObject).length !== 0
          ? billingAddressObject
          : undefined,
      shippingAddress:
        Object.keys(addressObject).length !== 0 ? addressObject : undefined,
      metafields: metafield,
      tags: showSelectedTags,
      shippingLine: shippingPrice ? shippingPrice : undefined,
      paymentRemark: paymentRemark ? paymentRemark : undefined,
      deliveryRemark: deliveryRemark ? deliveryRemark : undefined,
      appliedDiscount: overAllDiscount ? overAllDiscount : undefined,
      overallDiscountAmount: showProduct?.overAllDiscounts?.overallDiscountAmount || undefined,
      overallDiscountPercentage: showProduct?.overAllDiscounts?.overallDiscountPercentage || undefined,
      appliedCouponCode: couponCode || undefined,
      total: showProduct?.Total,
      TaxLines: taxLines,
      subTotal: showProduct?.SubTotal,
      TaxAmount: showProduct?.TaxAmount ? showProduct?.TaxAmount : undefined,
      invoiceType: invoiceType ? invoiceType : undefined,
      affiliateUser: affiliateUser ? +affiliateUser?.Id : undefined,
      affiliateUserName: affiliateUser ? affiliateUser?.label : undefined,
      salesUser: salesUser ? +salesUser?.Id : undefined,
      preSalesUser: preSalesUser ? +preSalesUser?.Id : undefined,
      salesUserName: salesUser ? salesUser.label : undefined,
      preSalesUserName: preSalesUser ? preSalesUser?.label : undefined,
      ...(sellerBillingAddress && { sellerBillingAddress: sellerBillingAddress }),
      ...(seller && { seller: seller }),
    };

    axiosInstance
      .post(`/orders/admin/${updateDraftOrderId[0]}?paymentGatewayId=${pg}&amount=${amount}&company=${company}`, { ...data })
      .then((res) => {
        if (pg) {
           (event.target as HTMLButtonElement).innerHTML = `Send Payment Link`;
        } else {
          (event.target as HTMLButtonElement).innerHTML = "Order Updated";
        }


        setSendPIDraftOrder(res.data.data);
        setIsCreateOrderPage(false);
        navigate(`/draftOrdersNew/${res?.data?.data?.draftOrderName}`);
      })
      .catch((err) => {
        alert(err?.response?.data?.message ?? "Error in updating Order.. Please try again");
        if (pg) {
          (event.target as HTMLButtonElement).innerHTML = "Send Payment Link";
        } else {
          (event.target as HTMLButtonElement).innerHTML = "Update Order";
        }
        (event.target as HTMLButtonElement).disabled = false;
      });
  };

  const setLoadingSalesOrderCalculationServiceState = (value:boolean) => {
    setLoadingSalesOrderCalculationService(value);
  };

  useEffect(() => {
    if (Id) {
      if (updateToggle) {
        setLoading(true);
        const salesOrderCalculationServiceEndpoint = `/product/fetchProductDetails/bulk`;
        const timeoutId = setTimeout(()=>{
          axiosInstance
            .get<DraftOrderResponse>(`/pgOrder/admin/draftOrder/${Id}`)
            .then((response) => {
              const fetchDraftOrderResponse = response?.data?.data;
              const draftOrderFetched = fetchDraftOrderResponse?.draftOrder;
              if (draftOrderFetched !== null) {
                setDraftOrderId(draftOrderFetched?.draftOrderName);
                setOrderTime(draftOrderFetched.createdAt);
                setSendPIDraftOrder(draftOrderFetched);
                if (draftOrderFetched.hasOwnProperty("deliveryRemark")) {
                  const delivery = document.getElementById("deliveryRemark");
                  if (delivery !== null) {
                    if (draftOrderFetched.deliveryRemark !== null) {
                      delivery.innerText = draftOrderFetched.deliveryRemark;
                    }
                    setDeliveryRemark(draftOrderFetched.deliveryRemark);
                  }
                }
  
                if (draftOrderFetched.hasOwnProperty("paymentRemark")) {
                  const payment = document.getElementById("paymentRemark");
                  setPaymentRemark(draftOrderFetched.paymentRemark);
                  // if (payment !== null) {
                  //   payment.innerText = draftOrderFetched.paymentRemark;
                  //   setPaymentRemark(draftOrderFetched.paymentRemark);
                  // }
                }
              }
  
              if (draftOrderFetched) {
                setUpdateExistOrder(true);
              }
              if (draftOrderFetched?.DoAffiliateUser?.affiliateId) {
                const affiliateUser = {
                  label: draftOrderFetched?.DoAffiliateUser?.name,
                  Id: `${draftOrderFetched?.DoAffiliateUser?.affiliateId}`,
                };
                setAffiliateUser(affiliateUser);
              }
              if (draftOrderFetched?.DoSalesUser?.adminId) {
                const salesUser = {
                  label: draftOrderFetched.DoSalesUser?.name,
                  Id: `${draftOrderFetched.DoSalesUser?.adminId}`,
                };
                setSalesUser(salesUser);
              }
              if (draftOrderFetched?.DoPreSalesUser?.adminId) {
                const preSalesUser = {
                  label: draftOrderFetched.DoPreSalesUser?.name,
                  Id: `${draftOrderFetched.DoPreSalesUser?.adminId}`,
                };
                setPreSalesUser(preSalesUser);
              }
              if(draftOrderFetched?.DoSellerAddresses) {
                setSellerBillingAddress(draftOrderFetched?.DoSellerAddresses[0]);
              }
              setCouponCode(draftOrderFetched?.DoPaymentDetail?.appliedCouponCode);
              setProductDetails((prevProductDetails) => {
                if(prevProductDetails){
                  return prevProductDetails?.map((productDetail) => {
                    productDetail?.appliedDiscount && delete productDetail?.appliedDiscount;
                    return {
                      ...productDetail,
                    };
                  });
                }else{
                  return null;
                }
              });
              if(draftOrderFetched?.DoPaymentDetail?.appliedCouponCode){
                setLineItemDiscountApplicable(false);
              }
              setUpdateDraftOrderId([draftOrderFetched.draftOrderName]);
              const lineItems = draftOrderFetched.DoLineItems;
              let lineItemToBeCalculated : LineItemToBeCalculated[] = [];
              let productIds = [];
              
              const taxArray: TaxArrayType[] = [];
              for (let lineItem of lineItems) {
                productIds.push(lineItem.productId);
                if (lineItem.discountType && lineItem.discountValue) {
                  lineItemToBeCalculated.push({
                    productId: lineItem.productId,
                    quantity: +lineItem.quantity,
                    appliedDiscount: {
                      value: +lineItem.discountValue,
                      valueType: lineItem.discountType,
                      title: lineItem.discountTitle,
                    },
                    districtForPrices: lineItem?.districtForPrices,
                  });
                } else {
                  lineItemToBeCalculated.push({
                    productId: lineItem.productId,
                    quantity: +lineItem.quantity,
                    districtForPrices: lineItem?.districtForPrices,
                  });
                }
              }
  
              setUpdateDiscountPercent(productIds);
              setDiscountPercent(productIds);
              let overDiscount = null;
              if (draftOrderFetched?.DoPaymentDetail?.overallDiscountType 
                && draftOrderFetched?.DoPaymentDetail?.overallDiscountValue
                ) {
                overDiscount = {
                  title: draftOrderFetched?.DoPaymentDetail?.overallDiscountTitle || "",
                  value: +draftOrderFetched?.DoPaymentDetail?.overallDiscountValue,
                  valueType: draftOrderFetched?.DoPaymentDetail?.overallDiscountType,
                };
              }
  
              let shippingExist = draftOrderFetched?.shippingCost > 0 && draftOrderFetched?.shippingTitle;
  
              let shippingLine;
              if (shippingExist) {
                setShippingPriceType({ fieldName: "custom" });
                setPreviousTarget(["custom"]);
                setShippingAmount(true);
                shippingLine = {
                  price: draftOrderFetched?.shippingCost,                  
                  title: draftOrderFetched?.shippingTitle || "",
                  taxRate: 18,
                  taxAmount: draftOrderFetched?.shippingCost * 0.18,
                };
              } else {
                setShippingPriceType({ fieldName: "freeShipping" });
                setPreviousTarget(["freeShipping"]);
                setShippingAmount(false);
              }
  
              const data = {
                productDetails: lineItemToBeCalculated,
                shippingLine: shippingLine || undefined,
                discount:
                  overDiscount && Object.keys(overDiscount).length !== 0
                    ? overDiscount
                    : undefined,
              };
  
              if (!overDiscount) {
                setOverAllDiscount(null);
              } else {
                setOverAllDiscount(overDiscount);
              }
  
              if (shippingLine) {
                setShippingPrice(shippingLine);
              }
  
              if (draftOrderFetched?.invoiceType) {
                if (draftOrderFetched?.invoiceType === "bill") {
                  (document.getElementById("bill") as HTMLInputElement).checked = true;
                } else {
                  (document.getElementById("challan") as HTMLInputElement).checked = true;
                }
                setInvoiceType(draftOrderFetched?.invoiceType);
              }
              setSeller(draftOrderFetched?.DoSellerDetails?.[0] as Seller); // only sellerGstNumber is absent from Seller type
              setLoadingSalesOrderCalculationService(true) 
              if(data?.productDetails){
                axiosInstance
                  .post<CalculationServiceResponse>(salesOrderCalculationServiceEndpoint, { ...data })
                  .then((res) => {
                    const calculatedSoResponse = res?.data?.product;
                    if (calculatedSoResponse) {
                      const calculatedResponse = calculatedSoResponse;
                      if (calculatedResponse?.TaxLines) {
                        setTaxLines(
                          calculatedResponse?.TaxLines?.structuredTaxLines
                        );
                        setTotalTaxAmount(
                          calculatedResponse?.TaxLines?.totalTaxAmount
                        );
                      }
                      for (let ele of res.data.product.lineItems) {
                        if (taxArray.length === 0) {
                          taxArray.push({
                            hsnCode: ele?.hsnCode as unknown as HsnCode,
                            tax: ele?.totalTax,
                            groupName: ele?.basicCollectionDataBrandData?.groupName,
                            gst: ele?.priceDataAffiliateData?.gst,
                          });
                        } else {
                          const index = taxArray.findIndex(
                            (variantIndex) => `${variantIndex.hsnCode}` === `${ele.hsnCode}`
                          );
                          if (index >= 0) {
                            taxArray[index].tax += ele?.totalTax;
                          } else {
                            taxArray.push({
                              hsnCode: ele?.hsnCode as unknown as HsnCode,
                              tax: ele?.totalTax,
                              groupName:
                                ele?.basicCollectionDataBrandData?.groupName,
                              gst: ele?.priceDataAffiliateData?.gst,
                            });
                          }
                        }
                      }
                      const calculatedLineItems = lineItemToBeCalculated?.map((lineItem:LineItemToBeCalculated): ProductDetail => {
                        const foundCalculatedLineItem = calculatedSoResponse?.lineItems?.find(
                          (calculatedLineItem: CalculatedLineItem) => calculatedLineItem.product_id === lineItem.productId
                        );
  
                        return {
                          productId: lineItem?.productId,
                          quantity: lineItem.quantity,
                          districtForPrices: lineItem?.districtForPrices,
                          PCS: foundCalculatedLineItem?.PCS || 0,
                          rateInclGST: foundCalculatedLineItem?.rateInclGST || 0,
                          bulkPackingValue1: foundCalculatedLineItem?.bulkPackingValue1 || 0,
                          bulkPackingValue2: foundCalculatedLineItem?.bulkPackingValue2 || 0,
                        }
                      });
  
                      setProductId(calculatedLineItems);
                      setProductDetails(calculatedLineItems);
                      setProduct(calculatedSoResponse);
                      setTimeout(() => {
                        handleEnableSelect();
                      }, 1500);
                      setUpdateToggle(false);
  
                      if (draftOrderFetched?.DoCustomerDetails?.[0]) {
                        axiosInstance
                          .get(`/user/admin/${draftOrderFetched?.DoCustomerDetails?.[0]?.pgCustomerId}`)
                          .then((respo) => {
                            const fetchedCustomerDetails = respo?.data?.data;
                            if (fetchedCustomerDetails !== undefined) {
                              const shippingAddress =
                                draftOrderFetched?.DoCustomerAddresses?.find(
                                  (address) => address.addressType === "Shipping"
                                );
                              const billingAddress =
                                draftOrderFetched?.DoCustomerAddresses?.find(
                                  (address) => address.addressType === "Billing"
                                );
                              setCustomerDetails(fetchedCustomerDetails);
                              if (shippingAddress) {
                                setSelectedShippingAddress(shippingAddress);
                              }
                              if (billingAddress) {
                                setSelectedBillingAddress(billingAddress);
                              }
  
                              setSelectedTags(draftOrderFetched?.tags);
                              axiosInstance
                                .get<CustomerAddressesFetchResponse>(
                                  `/user/admin/${draftOrderFetched?.DoCustomerDetails?.[0]?.pgCustomerId}/address`
                                )
                                .then((resp) => {
                                  const fetchedAddresses = resp.data.data;
                                  if (fetchedAddresses !== undefined) {
                                    const gstNumber = draftOrderFetched?.DoCustomerDetails?.[0]?.gstNumber?.[0];
                                    if(gstNumber){
                                      const $gstNo =
                                        document.getElementById("gstNo");
                                      if ($gstNo !== null) {
                                        setGstNumber(
                                          gstNumber
                                        );
                                        ($gstNo as HTMLInputElement).value =
                                          gstNumber;
                                      }
                                    }
                                    setAddressArray(fetchedAddresses);
                                  }
                                });
                            }
                          })
                          .catch((err) => {
                            setLoading(false);
                          });
                      }
                    }
                    setLoading(false);
                  })
                  .catch((err) => {
                    setLoading(false);
                  })
                  .finally(()=>{
                    let calculateSalesOrderRoutePendingCount:(string|null) = localStorage.getItem(salesOrderCalculationServiceEndpoint);
                    calculateSalesOrderRoutePendingCount = calculateSalesOrderRoutePendingCount ? calculateSalesOrderRoutePendingCount : `0`;
                    if (+calculateSalesOrderRoutePendingCount === 0) {
                      setLoadingSalesOrderCalculationService(false)
                    }
                  });
              } 
            })
            .catch((err) => {
              setLoading(false);
            })
        }, 500)
        return () => clearTimeout(timeoutId)
      }
    }
  }, [Id, updateToggle]);

  useEffect(() => {
    console.log(draftOrder, "draftOrder")
    if (draftOrder?.draftOrderName) {
      setLoading(true);
      axiosInstance
        .post(`/orders/admin/draftOrderComplete/${draftOrder.draftOrderName}`, {
          paymentPending: true,
        })
        .then((res) => {
          setLoading(false);
          setIsCreateOrderPage(false);
          navigate(`/ordersNew/${res.data.orderName}`);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [draftOrder, navigate]);

  const calculateSalesOrder = async (data: CalculationServiceRequest) => {
    setLoadingSalesOrderCalculationService(true);
    const salesOrderCalculationServiceEndpoint = `/product/fetchProductDetails/bulk`;
    if(data?.productDetails){
      return axiosInstance
        .post<CalculationServiceResponse>(salesOrderCalculationServiceEndpoint, { ...data })
        .then(async (res) => {
          if (res?.data?.product) {
            const calculatedResponse = res?.data?.product;
            if (calculatedResponse?.TaxLines) {
              setTaxLines(calculatedResponse?.TaxLines?.structuredTaxLines);
              setTotalTaxAmount(calculatedResponse?.TaxLines?.totalTaxAmount);
            }
            if (updateDiscountPercent !== undefined) {
              if (updateDiscountPercent?.length !== 0) {
                setDiscountPercent(updateDiscountPercent);
              } else {
                if (editDiscountPercent?.length !== 0) {
                  setDiscountPercent([]);
                }
              }
            }
            await checkNewlyAddedProduct(res?.data?.product?.lineItems);
            const taxArray = [];
            for (let ele of res.data.product.lineItems) {
              if (taxArray.length === 0) {
                taxArray.push({
                  hsnCode: ele?.hsnCode,
                  tax: ele?.totalTax,
                  groupName: ele?.basicCollectionDataBrandData?.groupName,
                  gst: ele?.priceDataAffiliateData?.gst,
                });
              } else {
                const index = taxArray.findIndex(
                  (variantIndex) => variantIndex.hsnCode === ele.hsnCode
                );
                if (index >= 0) {
                  taxArray[index].tax += ele?.totalTax;
                } else {
                  taxArray.push({
                    hsnCode: ele?.hsnCode,
                    tax: ele?.totalTax,
                    groupName: ele?.basicCollectionDataBrandData?.groupName,
                    gst: ele?.priceDataAffiliateData?.gst,
                  });
                }
              }
            }
  
            setProduct((prev) => {
              return res?.data?.product;
            });
          }
          return res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          let calculateSalesOrderRoutePendingCount = localStorage.getItem(
            salesOrderCalculationServiceEndpoint
          );
          calculateSalesOrderRoutePendingCount =
            calculateSalesOrderRoutePendingCount
              ? calculateSalesOrderRoutePendingCount
              : `0`;
          if (calculateSalesOrderRoutePendingCount && +calculateSalesOrderRoutePendingCount === 0) {
            setLoadingSalesOrderCalculationService(false);
          }
        });
    }
  };

  const afterCouponCodeApplyHook = async (responseData: OverallDiscount) => {
    setOverAllDiscount({
      ...responseData,
      value: +(responseData?.value as number) || 0,
    } as OverallDiscount);
    setProductDetails((prevProductDetails:ProductDetails) => {
      if(prevProductDetails){
        return prevProductDetails?.map((productDetail) => {
          productDetail?.appliedDiscount && delete productDetail?.appliedDiscount;
          return {
            ...productDetail,
          };
        });
      }else{
        return null
      }
    });
    setLineItemDiscountApplicable(false);
  }

  const applyCouponCode = async ({
      selectedProducts,
      overAllSubTotal,
      couponCode,
    }: {
      selectedProducts: ProductDetails;
      overAllSubTotal: number;
      couponCode: string;
    }) => {
    if (!couponCode) {
      alert("Please enter coupon code");
      return;
    }

    console.log(selectedProducts);

    await axiosInstance
      .post("/orders/admin/applyCoupon", {
        couponCode: `${couponCode}`,
        lineItems: selectedProducts?.map((product) => {
            return {
                ...product,
                productId: product?.productId,
            }
        }),
        user: customerDetails,
        subTotal: overAllSubTotal,
      })
      .then(async (res) => {
        setOverAllDiscount({
          ...res?.data?.data,
          value: +res?.data?.data?.value || 0,
        });
        return res;
      })
      .catch((err) => {
        setCouponCode(null);
        setOverAllDiscount(null);
        setLineItemDiscountApplicable(true);
        setSnackBar({
          display: true,
          message: err?.response?.data?.message || "Something went wrong",
          type: "error",
        });
      });
  }

  // handles applied coupon code overall discount
  useEffect(() => {
    const calculateSalesOrderTimeoutId = setTimeout(() => {
      const data = {
        productDetails: productDetails,
        discount: overAllDiscount ? overAllDiscount : undefined,
        shippingLine:
          shippingPrice ? shippingPrice : undefined,
      };
      calculateSalesOrder(data);
    }, 500);
    return () => clearTimeout(calculateSalesOrderTimeoutId);
  }, [overAllDiscount]);


  // mostly calls when product add or remove 
  useEffect(() => {
    if (addToggleBtn) {
      if (productDetails?.length !== 0) {
        const calculateSalesOrderTimeoutId = setTimeout(() => {
          const data = {
            productDetails: productDetails,
            discount: overAllDiscount || undefined,
            shippingLine:
              shippingPrice ? shippingPrice : undefined,
          };
          if(couponCode){
            setProductDetails((prevProductDetails) => {
              if(prevProductDetails){
                return prevProductDetails.map((product) => {
                  product?.appliedDiscount && delete product?.appliedDiscount;
                  return {
                    ...product,
                  }
                });
              }else{
                return null;
              }
            });
            calculateSalesOrder({
              productDetails: productDetails?.map((product) => {
                product?.appliedDiscount && delete product?.appliedDiscount;
                return {
                  ...product,
                }
              }) || null,
              discount: undefined,
            })
              .then((res) => {
                applyCouponCode({
                  selectedProducts: productDetails,
                  overAllSubTotal: res?.data?.product?.SubTotal || 0,
                  couponCode: couponCode,
                })
                  .then(() => {
                    calculateSalesOrder(data)
                    .then(() => {
                      setAddToggle(false);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }else{
            calculateSalesOrder(data)
            .then(() => {
              setAddToggle(false);
            })
            .catch((err) => {
              console.log(err);
            });
          }
        }, 500);
        return () => clearTimeout(calculateSalesOrderTimeoutId);
      }
    }
  }, [
    addToggleBtn,
    showProduct,
    overAllDiscount,
    productDetails,
    shippingPrice,
    updateDiscountPercent,
    editDiscountPercent,
  ]);

  // handles applied coupon code overall discount on product quantity change
  useEffect(() => { 
    if(couponCode){
      const data = {
        productDetails: productDetails,
        discount: overAllDiscount ? overAllDiscount : undefined,
        shippingLine:
          shippingPrice ? shippingPrice : undefined,
      };
      calculateSalesOrder({
        productDetails: productDetails,
        shippingLine:
          shippingPrice ? shippingPrice : undefined,
      })
      .then((res) => {
        applyCouponCode({
          selectedProducts: productDetails,
          overAllSubTotal: res?.data?.product?.undiscountedSubtotal || 0,
          couponCode: couponCode,
        })
          .then(() => {
            calculateSalesOrder(data)
            .then(() => {
              setAddToggle(false);
            })
            .catch((err) => {
              console.log(err);
            });
          })
          .catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [productId])
  
  useEffect(() => {
    if (draftOrderCreate && showProduct?.lineItems && showProduct?.lineItems?.length > 0) {
      handleCreateDraftOrder();
    }
  }, [showProduct, draftOrderCreate]);

  const checkNewlyAddedProduct = async (updatedLineItems: string | any[]) => {
    const prevLineItems = showProduct?.lineItems || [];
    if( prevLineItems?.length > 0 && (updatedLineItems?.length >= prevLineItems?.length)){
      for( let updatedLineItem of updatedLineItems ){
        const {product_id : updatedProductId } = updatedLineItem;
        // Find This updatedProductId is new Id or OldOne
        const isNewLineItem = prevLineItems.every(prevLineItem => prevLineItem?.product_id !== updatedProductId);
        isNewLineItem && setNewAddedProductIds(prev => [...prev, updatedProductId])
      }
    }
    return;
  }

  useEffect(() => {
    handleSalesList();
    axiosInstance.get<CustomerRolesResponse>("/user/admin/customerRoles").then((res) => {
      setCustomerRoleDB([...res.data.data]);
    });
  }, []);

  const countPlaces = (num:number) => {
    var sepMatch = String(23.32)?.match(/\D/);
    var sep = sepMatch ? sepMatch[0] : '';
    var b = String(num).split(sep);
    return b[1] ? b[1].length : 0;
  };

  // OnQty Blur Handled
  const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown" && +(event.target as HTMLInputElement).value <= 0) {
      event.preventDefault()
    } else {
      if (event.keyCode === 13) {
        event.preventDefault();
        (event.target as HTMLInputElement).blur();
      } else {
        preventNegativeValues(event)
      }
    }
  };

  const removeOverAllDiscount = () => {
    setOverAllDiscount(null);
    setCouponCode(null);
    setLineItemDiscountApplicable(true);
    const data = {
      productDetails: productDetails,
      shippingLine: shippingPrice ? shippingPrice : undefined,
    };
    calculateSalesOrder(data);
  };

  const disableBackNavigation = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  };

  useEffect(() => {
    if (showProduct !== null) {
      setIsCreateOrderPage(true);
      disableBackNavigation();
    }
    return () => {
      window.onpopstate = null; // Cleanup the event listener when the component unmounts
    };
  }, [showProduct]);

  useEffect(() => {
    if(newAddedProductIds.length !==0) {
      if(showProduct){
        for(let product of showProduct.lineItems){
          // console.log("productId : ",product.product_id, "condition : ",( newAddedProductIds.includes(product?.product_id)))
          if( newAddedProductIds.includes(product?.product_id) ){
            updateProductRoleDiscount(product)
          }
        }
      }
      setNewAddedProductIds([]);
      setToggle(true)
      if(productDetails){
        setProductDetails([...productDetails]);
      }
      setProductId(productDetails);
      setAddToggle(true);
      setProduct(showProduct);
    }
  },[newAddedProductIds])

  useEffect(() => {
    //ContactPoput component set state showCustomerDetail with Object so ,convert in array
    if(customerDetails){
      const result = customerDetails?.customer_role==="special_retailer"
                      ? CheckRoleBasedPermission(adminUser, services?.specialRetailer, "update")
                      : true;
    
      setSpecialRetailerPermission(result);
      result ? handleEnableSelect() : handleDisableSelect();
      if(customerDetails?.customer_role==='special_retailer'){
        setSelectedRoleForDiscount(customerDetails?.customer_role)
      }
    }
    else {
      setSpecialRetailerPermission(true);
      setSelectedRoleForDiscount("DEFAULT");
      handleEnableSelect()
    }

  },[customerDetails])

  const notInitialRender = useRef(false)
  useEffect(()=>{
    //useRef Helpful for stop run functions on Initial Render
    // Check if user is special retailer then reset the lineItems discount 
    if(
      customerDetails?.customer_role === "special_retailer"
    ){
      if(notInitialRender.current){
        handleRole();
        specialRetailerPermission ? handleEnableSelect() : handleDisableSelect();
      }
      else notInitialRender.current = true
    }
  },[customerDetails])

  useEffect(()=>{
    handleRole();  
  },[selectedRoleForDiscount])

  const preventNegativeValues = (e:React.KeyboardEvent<HTMLInputElement>) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const preventValueWithoutDecimal = (e:React.KeyboardEvent<HTMLElement>) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const getPaymentGateways = async () => {
    axiosInstance.get('/pos/paymentGatewaysPortal').then(res => {
      setPaymentGateways(res.data.data.paymentGateways);
    }).catch(err => {});
  }

  useEffect(() => {
    getPaymentGateways()
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const [paymentGateway, setPaymentGateway] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null)

  const getExclGstAmount = (mrp:string, gst:string) => {
    return (+mrp/(1 + (+gst/100)))?.toFixed(4);
  }

  return (
    <Fragment>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation='border' variant='light' />
        </div>
      ) : null}
      <div className={Classes.container} id='color'>
        {Id && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            id='hideHeader'
          >
            <div className='d-flex align-items-center' style={{ gap: "1rem" }}>
              <div>
                <button
                  type='button'
                  className='btn btn-light'
                  onClick={() => {
                    if (isCreateOrderPage) {
                      const confirmNavigation = window.confirm(
                        "Do you want to quit this page?"
                      );
                      if (confirmNavigation) {
                        setIsCreateOrderPage(false);
                        navigate(`/draftOrdersNew`);
                      }
                    }
                  }}
                >
                  <BiArrowBack />
                </button>
              </div>

              <div>
                <h6 className={Classes.draftOrderDetail}>{draftOrderId}</h6>
                <h6 className={Classes.draftOrderDetail}>
                  {orderTime && createdAt(orderTime)}
                </h6>
              </div>
            </div>
          </div>
        )}

        {sendPIDraftOrder && (
          <div style={{ display: "none" }} id='orderNamePrint'>
            <div>
              <p style={{ textAlign: "center" }}>
                {/* Proforma Invoice */}
                Order Summary
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <h6 className={Classes.commonMargin}>Order Name:-</h6>
              <p>{sendPIDraftOrder?.draftOrderName}</p>
            </div>
          </div>
        )}

        <div
          style={{ display:"flex", justifyContent:"end", gap:"0.5rem", alignItems:"center", flexWrap:'wrap' }}
          id='invoiceTypes'
        >
          <div className={Classes.billBox} id='billBox'>
            <input
              type='radio'
              name='choose'
              id='bill'
              value='bill'
              onChange={handleInvoiceType}
            />
            <p style={{ marginLeft: 5 }}>Send on Bill</p>
          </div>

          <div
            className={Classes.billBox}
            style={{ marginLeft: 15 }}
            id='challanBox'
          >
            <input
              type='radio'
              name='choose'
              id='challan'
              value='challan'
              onChange={handleInvoiceType}
            />
            <p style={{ marginLeft: 5 }}>Send on Challan</p>
          </div>
          <Autocomplete
            options={districtOptions || []}
            value={selectedDistrict}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectedDistrict(newValue);
              }
            }}
            // style={{ width: '20%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select District for Price"
                size="small"
              />
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
          />
        </div>

        <div
          className={Classes.mainContainer}
          style={{ marginTop: "30px" }}
          id='createOrderContainer'
        >
          <div className={Classes.containBoxes} id='createOrderProducts'>
            <div className={[Classes.Box, "box"].join(" ")}>
              <div className={Classes.containerBox} id='createOrderHeader'>
                <div className='d-flex justify-content-between mb-3' style={{flexWrap:'wrap'}}>
                  <h6 style={{ margin: "auto 0px" }}>Products</h6>
                  <BulkProductAddForCreateOrders
                   setProductDetails={setProductDetails} 
                   setAddToggle={setAddToggle} 
                   createInstantDraftOrder={true} 
                   setDraftOrderCreate={setDraftOrderCreate} 
                   type={'salesOrder'} 
                  />
                </div>
                <div>
                  <div
                    id='outline'
                    onClick={handleOutline}
                    className={Classes.searchContainer}
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        className={Classes.searchIcon}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <input
                        type='text'
                        id='search'
                        autoComplete='off'
                        className={Classes.searchField}
                        placeholder='Search products here'
                        value={props.text}
                        onChange={(e) => getModal(e.target.value)}
                      />
                    </div>
                    <SmartProductSearch
                      locationCode={selectedDistrict}
                      productsAddedToCartRef={productsAddedToCartRef}
                      setProductDetails={setProductDetails}
                      setAddToggle={setAddToggle}
                    />
                  </div>
                </div>
              </div>

              {showProduct !== null ? (
                <div className={Classes.variantContainer}>
                  <div className={Classes.Thead}>
                    <div
                      className={[
                        "createOrderProductHeading",
                        Classes.variantProductHeader,
                      ].join(" ")}
                    >
                      Product
                    </div>

                    <div
                      className={[
                        "discountCreateOrder",
                        Classes.variantDiscountHeader,
                      ].join(" ")}
                    >
                      <p>Discount</p>
                      <p id='inclGst'>(Incl. GST)</p>
                    </div>

                    <div className={["qtyHeader",Classes.variantQuantityHeader].join(" ")}>
                      Quantity
                    </div>

                    <div className={["subtotal",Classes.variantSubTotalHeader].join(" ")}>
                      <p style={{ paddingLeft: 5 }}>Subtotal</p>
                      <p>(Excl. GST)</p>
                    </div>

                    <div className={["total", Classes.variantTotalHeader].join(" ")}>
                      <p style={{ paddingLeft: 10 }}>Total</p>
                      <p>(Incl. GST)</p>
                    </div>
                  </div>

                  <div className={Classes.ShowResponsive}></div>

                  <div>
                    {showProduct?.lineItems.map((item, index) => (
                      <div
                        className={[
                          Classes.containProduct,
                          "variantProduct",
                        ].join(" ")}
                        id={item?.product_id}
                        key={item?.product_id}
                      >
                        <div
                          className={[
                            Classes.productDetail,
                            "productVariant",
                          ].join(" ")}
                          id={item?.product_id}
                        >
                          <div style={{ display: 'flex', gap: 10 }}>
                            <SerialNumber serialNumber={index + 1} />
                            <div>
                              <img
                                src={
                                  item?.images
                                    ? item?.images
                                    : process.env.REACT_APP_IMAGE_COMMING_SOON
                                }
                                alt='icon'
                                className={Classes.variantImage}
                              />
                            </div>
                          </div>

                          <div className={Classes.variantDetail}>
                            <div>
                              <p style={{ color: "#0d6efd" }}>
                                {item?.basicCollectionDataBrandData?.productName}
                              </p>
                            </div>
                            <div style={{ color: "#6d7175" }}>
                              <div className={Classes.commonDisplay}>
                                <p>
                                  {
                                    item
                                      ?.variationDataProductInformationProductDetail
                                      ?.variable1Value
                                  }
                                </p>

                                {item
                                  ?.variationDataProductInformationProductDetail
                                  ?.variable2Value && (
                                    <div className={Classes.commonDisplay}>
                                      <p style={{ margin: "0px 5px" }}>/</p>
                                      <p>
                                        {
                                          item
                                            ?.variationDataProductInformationProductDetail
                                            ?.variable2Value
                                        }
                                      </p>
                                    </div>
                                  )}

                                {item
                                  ?.variationDataProductInformationProductDetail
                                  ?.variable3Value && (
                                    <div className={Classes.commonDisplay}>
                                      <p style={{ margin: "0px 5px" }}>/</p>
                                      <p>
                                        {
                                          item
                                            ?.variationDataProductInformationProductDetail
                                            ?.variable3Value
                                        }
                                      </p>
                                    </div>
                                  )}
                              </div>

                              <p>
                                {item?.basicCollectionDataBrandData?.sku}
                              </p>

                              <div className={[Classes.GstRate, "gstRate"].join(" ")}>
                                <p>GST Rate:- </p>
                                <p style={{ marginLeft: 5 }}>
                                  {item?.priceDataAffiliateData?.gst}%
                                </p>
                              </div>
                            </div>
                            <div
                              style={{ display: "flex" }}
                              className={[
                                Classes.rateFontSize,
                                "hidePricePrint",
                              ].join(" ")}
                            >
                              <p
                                style={{
                                  color: "rgb(109, 113, 117)",
                                  marginRight: 9,
                                }}
                              >
                                Rate Excl. GST
                              </p>
                              <div
                                style={{ display: "flex", color: "#0d6efd" }}
                              >
                                <p>&#8377;</p>
                                <p
                                  style={{ padding: "0px 5px" }}
                                  //here if slected customer is special-retailer then we disable all editable fields. Only special-retailer-Editable Permission's admin can edit
                                  contentEditable={ lineItemDiscountApplicable && specialRetailerPermission }
                                  suppressContentEditableWarning={true}
                                  onKeyDown={(e) => { lineItemDiscountApplicable && handleEdit(e) }}
                                  onBlur={(e) =>
                                    lineItemDiscountApplicable &&
                                    handleBlur(
                                      e,
                                      item?.product_id,
                                      item?.priceDataAffiliateData?.gst as GstPercentage,
                                      getExclGstAmount(item?.priceDataAffiliateData?.mrp, item?.priceDataAffiliateData?.gst)// we can set price upto Excl gst MRP
                                      // item?.priceDataAffiliateData?.listingPrice
                                    )
                                  }
                                >
                                  {roundOfUptoXDecimal(item?.priceDataAffiliateData?.listingPrice)}
                                </p>
                                <div style={{ display: "none" }}>
                                  <p className={Classes.variantMrp}>
                                    &#8377;{item?.priceDataAffiliateData?.mrp}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p className={Classes.variantMrp}>
                                  &#8377;{item?.priceDataAffiliateData?.mrp}
                                </p>
                              </div>
                            </div>

                            <div
                              style={{ display: "none" }}
                              className='showPricePrint'
                            >
                              <div style={{ display: "flex", marginRight: 8 }}>
                                <p style={{ marginRight: "5px" }}>
                                  Rate Excl. GST
                                </p>
                                <div
                                  style={{ display: "flex", color: "#0d6efd" }}
                                >
                                  <p>&#8377;</p>
                                  <p>
                                    {item?.priceDataAffiliateData?.listingPrice}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <div
                                  className={Classes.variantMrp}
                                  style={{ display: "flex" }}
                                >
                                  <p>&#8377;</p>
                                  <p>{item?.priceDataAffiliateData?.mrp}</p>
                                </div>
                              </div>
                            </div>

                            <div className={Classes.MrpLessThanRestrict}>
                              <p className={Classes.MrpRestrictMessage}>
                                Price must be less than{" "}
                                {
                                  getExclGstAmount(item?.priceDataAffiliateData?.mrp, item?.priceDataAffiliateData?.gst)// we can set price upto Excl gst MRP
                                }
                              </p>
                            </div>

                            <div id='manuallyDiscountPercent'>
                              <div className={Classes.commonDisplay}>
                                <p className={Classes.variantDiscountInclGst}>
                                  Discount Incl. GST
                                </p>
                                <div>
                                  <input
                                    type='number'
                                    id={`discountPercentage-${index}`}
                                    disabled={!lineItemDiscountApplicable || !specialRetailerPermission}
                                    onChange={(e) => lineItemDiscountApplicable && handleEditPercentage(e)}
                                    onBlur={(e) =>
                                      lineItemDiscountApplicable && handleBlurPercentage(
                                        e,
                                        item?.product_id,
                                        item?.priceDataAffiliateData?.mrp,
                                        item?.priceDataAffiliateData?.gst,
                                      )
                                    }
                                    className={
                                      Classes.variantDiscountInclGstInput
                                    }
                                    onKeyDown={preventValueWithoutDecimal}
                                  />
                                </div>

                                <div
                                  className={[
                                    Classes.hideResponsiveRow,
                                    "hideResponsiveRow",
                                  ].join(" ")}
                                >
                                  <div
                                    id='discountIncWidPrint'
                                    className={[
                                      item?.priceDataAffiliateData
                                        ?.appliedDiscountTitle
                                        ? Classes.marginTop
                                        : "",
                                      Classes.variantDiscountTitle,
                                    ].join(" ")}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div>
                                        <p>
                                          {handleDiscount(
                                           {
                                            mrp: item?.priceDataAffiliateData?.mrp,
                                            discountedPrice: `${item?.priceDataAffiliateData
                                              ?.listingPrice}`,
                                            gst: item?.priceDataAffiliateData?.gst
                                           }
                                          )}
                                        </p>
                                      </div>
                                      <p>%</p>
                                    </div>

                                    {item?.priceDataAffiliateData
                                      ?.appliedDiscountTitle && (
                                        <div
                                          id='discountType'
                                          className={Classes.appliedDiscountType}
                                        >
                                          {
                                            item?.priceDataAffiliateData
                                              ?.appliedDiscountTitle
                                          }
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>

                              <div
                                id='discountPercentRestrict'
                                className={Classes.DiscountMsg}
                              >
                                <p className={Classes.DiscountRestrictMsg}>
                                  Discount must be smaller than 100%
                                </p>
                              </div>

                              <div
                                id='discountPercentRestrict'
                                className={Classes.DiscountMsg}
                              >
                                <p className={Classes.DiscountRestrictMsg}>
                                  Discount must be smaller than 100%
                                </p>
                              </div>

                              <div
                                className={[
                                  Classes.MrpLessThanRestrict,
                                  "index",
                                  "discountContent",
                                ].join(" ")}
                              >
                                <div
                                  className={Classes.MrpRestrictMessage}
                                  style={{ display: "flex", marginTop: 5 }}
                                >
                                  <p>Discount must be greater than</p>
                                  <p style={{ marginLeft: 5 }}>
                                    {showLimitDiscount(
                                      {
                                        mrp: item?.priceDataAffiliateData?.mrp,
                                        originalUnitPrice: getExclGstAmount(
                                          item?.priceDataAffiliateData?.mrp, 
                                          item?.priceDataAffiliateData?.gst
                                        ),// we can set price upto Excl gst MRP
                                        gst: item?.priceDataAffiliateData?.gst
                                      }
                                    )}
                                  </p>
                                  <p>%</p>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{ display: "flex" }}
                              className={[
                                Classes.rateFontSize,
                                // 'hidePricePrint'
                              ].join(" ")}
                            >
                              <p
                                style={{
                                  color: "rgb(109, 113, 117)",
                                  marginRight: 9,
                                }}
                              >
                                Rate Incl. GST
                              </p>
                              <div
                                style={{ display: "flex", color: "#0d6efd" }}
                              >
                                <p>&#8377;</p>
                                <p
                                  style={{ padding: "0px 5px" }}
                                  contentEditable={lineItemDiscountApplicable && specialRetailerPermission}
                                  suppressContentEditableWarning={true}
                                  onKeyDown={(e) => lineItemDiscountApplicable && handleEdit(e)}
                                  onBlur={(e) =>
                                    lineItemDiscountApplicable && handleBlurRateInclGst(
                                      e,
                                      item?.product_id,
                                      getExclGstAmount(item?.priceDataAffiliateData?.mrp, item?.priceDataAffiliateData?.gst),// we can set price upto Excl gst MRP
                                      item?.priceDataAffiliateData?.gst as GstPercentage,
                                      item?.rateInclGST
                                    )
                                  }
                                >
                                  {roundOfUptoXDecimal(item?.rateInclGST)}
                                </p>
                                <div style={{ display: "none" }}>
                                  <p className={Classes.variantMrp}>
                                    &#8377;{item?.priceDataAffiliateData?.mrp}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p className={Classes.variantMrp}>
                                  &#8377;{item?.priceDataAffiliateData?.mrp}
                                </p>
                              </div>
                            </div>

                            <div style={{ display: "flex" }} id='PCS'>
                              <p style={{ marginTop:15,fontSize:13,width:60 }}>
                                {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                              </p>

                              <div
                                className={[
                                  Classes.bulkPackagingQtyBox,
                                  "variantQty",
                                ].join(" ")}
                                style={{ marginTop: "5px", width: "82px" }}
                              >
                                <div className='variantQty'>
                                  <input
                                    type='number'
                                    defaultValue={item?.PCS}
                                    className={[
                                      Classes.Input,
                                      Classes.bulkPackagingQtyInput,
                                    ].join(" ")}
                                    style={{ paddingLeft: 8 }}
                                    name='quantity'
                                    key={item?.PCS}
                                    onBlur={(e) =>
                                      handleBlurQty(
                                        e.target.value,
                                        item?.product_id,
                                        item?.basicCollectionDataBrandData
                                          ?.bulkPackingValue1 || 0,
                                        item?.basicCollectionDataBrandData
                                          ?.bulkPackingValue2 || 0,
                                        `${index}-PCS`
                                      )
                                    }
                                    // onKeyUp={handleKeyUp}
                                    onKeyDown={handleKeyDown}
                                  />
                                </div>

                                <div>
                                  <div
                                    className={Classes.bulkPackagingIncQty}
                                    onClick={(e) => incQty(item?.product_id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faSortUp}
                                      style={{
                                        fontSize: 12,
                                        position: "relative",
                                        top: "-21%",
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={Classes.bulkPackagingDecQty}
                                    onClick={(e) => decQty(item?.product_id)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      style={{
                                        position: "relative",
                                        top: "-53%",
                                        fontSize: 12,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={Classes.QtyNegativeMsg}
                              style={{ color: "#fff", marginTop: 5 }}
                              id={`${index}-PCS`}
                            >
                              <p>{item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'} can't be Negative</p>
                            </div>

                            {item?.basicCollectionDataBrandData?.bulkPackingType1 && (
                                <div
                                  style={{ display: "flex" }}
                                  id='bulkPacking1'
                                >
                                  <div style={{ marginTop: 12, width: 60 }}>
                                    <p>
                                      {item?.basicCollectionDataBrandData?.bulkPackingType1}
                                    </p>
                                  </div>

                                  <div
                                    className={[
                                      Classes.bulkPackagingQtyBox,
                                      "variantQty",
                                    ].join(" ")}
                                    style={{ marginTop: "5px", width: "82px" }}
                                  >
                                    <div className='variantQty'>
                                      <input
                                        type='number'
                                        defaultValue={item?.bulkPackingValue1 || 0}
                                        className={[
                                          Classes.Input,
                                          Classes.bulkPackagingQtyInput,
                                        ].join(" ")}
                                        style={{ paddingLeft: 8 }}
                                        name='quantity'
                                        key={item?.bulkPackingValue1 || 0}
                                        onBlur={(e) =>
                                          handleBlurBulkPackagingQty1(
                                            e.target.value,
                                            item?.product_id,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue1 || 0,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue2 || 0,
                                            `${index}-bulkPacking1`
                                          )
                                        }
                                        onKeyDown={handleKeyDown}
                                      />
                                    </div>

                                    <div>
                                      <div
                                        className={Classes.bulkPackagingIncQty}
                                        onClick={(e) =>
                                          incQtyBulkPackaging(
                                            item?.product_id,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue1 || 0,
                                            "bulkPackingValue1"
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faSortUp}
                                          style={{
                                            fontSize: 12,
                                            position: "relative",
                                            top: "-21%",
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={Classes.bulkPackagingDecQty}
                                        onClick={(e) =>
                                          decQtyBulkPackaging(
                                            item?.product_id,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue1 || 0,
                                            "bulkPackingValue1"
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faSortDown}
                                          style={{
                                            position: "relative",
                                            top: "-53%",
                                            fontSize: 12,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ marginTop: 12 }}>
                                    <p className={Classes.bulkPackagingValue}>
                                      {item?.basicCollectionDataBrandData?.bulkPackingValue1}{" "}
                                      {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                    </p>
                                  </div>
                                </div>
                              )}

                            <div
                              className={Classes.QtyNegativeMsg}
                              style={{ color: "#fff", marginTop: 5 }}
                              id={`${index}-bulkPacking1`}
                            >
                              <p>
                                {item?.basicCollectionDataBrandData?.bulkPackingType1}{" "} can't be Negative
                              </p>
                            </div>

                            {item?.basicCollectionDataBrandData?.bulkPackingType2 && (
                                <div
                                  style={{ display: "flex" }}
                                  id='bulkPacking2'
                                >
                                  <div className={Classes.bulkPackagingType} style={{width: 60}}>
                                    <p>
                                      {item?.basicCollectionDataBrandData?.bulkPackingType2}
                                    </p>
                                  </div>
                                  <div
                                    className={[
                                      Classes.bulkPackagingQtyBox,
                                      "variantQty",
                                    ].join(" ")}
                                    style={{ marginTop: "5px", width: "82px" }}
                                  >
                                    <div className='variantQty'>
                                      <input
                                        type='number'
                                        defaultValue={item?.bulkPackingValue2 || 0}
                                        className={[
                                          Classes.Input,
                                          Classes.bulkPackagingQtyInput,
                                        ].join(" ")}
                                        style={{ paddingLeft: 8 }}
                                        name='quantity'
                                        key={item?.bulkPackingValue2 || 0}
                                        onBlur={(e) =>
                                          handleBlurBulkPackagingQty2(
                                            e.target.value,
                                            item?.product_id,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue2 || 0,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue1 || 0,
                                            `${index}-bulkPacking2`
                                          )
                                        }
                                        onKeyDown={handleKeyDown}
                                      />
                                    </div>

                                    <div>
                                      <div
                                        className={Classes.bulkPackagingIncQty}
                                        onClick={(e) =>
                                          incQtyBulkPackaging(
                                            item?.product_id,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue2 ?? 0,
                                            "bulkPackingValue2"
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faSortUp}
                                          style={{
                                            fontSize: 12,
                                            position: "relative",
                                            top: "-21%",
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={Classes.bulkPackagingDecQty}
                                        onClick={(e) =>
                                          decQtyBulkPackaging(
                                            item?.product_id,
                                            item?.basicCollectionDataBrandData
                                              ?.bulkPackingValue2 ?? 0,
                                            "bulkPackingValue2"
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faSortDown}
                                          style={{
                                            position: "relative",
                                            top: "-53%",
                                            fontSize: 12,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div style={{ marginTop: 12 }}>
                                    <p className={Classes.bulkPackagingValue}>
                                      {item?.basicCollectionDataBrandData?.bulkPackingValue2}{" "}
                                      {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                                    </p>
                                  </div>
                                </div>
                              )}

                            <div
                              className={Classes.QtyNegativeMsg}
                              style={{ color: "#fff", marginTop: 5 }}
                              id={`${index}-bulkPacking2`}
                            >
                              <p>
                                {item?.basicCollectionDataBrandData?.bulkPackingType2}{" "} can't be Negative
                              </p>
                            </div>
                            {item?.districtForPrices && <CapsuleChip capsulePart1={"District For Prices"} capsulePart2={item?.districtForPrices} colorChip={"#243750"} />}
                            {item?.parentProductSku && <CapsuleChip capsulePart1={`Virtual Bundle : ${item?.parentProductSku}`} capsulePart2={`${item?.basicCollectionDataBrandData?.sku} X ${item?.childQtyPerBundle} Quantity`} chipColor={"#ff6200"} />}
                            {item?.isParentProduct === true && <ChildProductsList capsulePart1={"Bundle Product"} capsulePart2={`${item?.childProducts?.length} x SKUs`} colorChip={"#ff6200"} childProducts={item?.childProducts}/>}
                            <div
                              className={Classes.SubTotal}
                              style={{ marginTop: 20 }}
                            >
                              <div className='subtotal' id='discTotalPrint'>
                                <p className={Classes.responsiveTotal}>
                                  Subtotal
                                </p>
                                <p>&#8377;{item?.productTotal}</p>
                              </div>

                              <div
                                className={["total", Classes.variantTotal].join(" ")}
                                id='discTotalPrint'
                              >
                                <p className={Classes.responsiveTotal}>Total</p>
                                <p>
                                  &#8377;
                                  {roundOfUptoXDecimal(
                                    item?.productTotal *
                                    (1 + +item?.priceDataAffiliateData?.gst / 100)
                                  )}
                                </p>
                              </div>
                            </div>

                            {/* <div className={Classes.notExistProductErrorMsg}>
															{!item?.shopifyVariantId && (
																<p>
																	&#x2022; This Product not Exist on Shopify.
																</p>
															)}
														</div> */}

                            <div className={Classes.QuantityProductErrorMsg}>
                              <p>Quantity Cannot be less than 1</p>
                            </div>
                          </div>
                        </div>

                        <div
                          className={[
                            Classes.hideDiscountResponsive,
                            Classes.variantDiscountBox,
                            "createOrderPercent",
                          ].join(" ")}
                          id='discountIncWidPrint'
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <p>
                                {
                                  item?.discountPercentage?.toFixed(2)
                                }
                              </p>
                            </div>
                            <p>%</p>
                          </div>

                          {item?.priceDataAffiliateData
                            ?.appliedDiscountTitle && (
                              <div
                                id='discountType'
                                className={Classes.appliedDiscountType}
                              >
                                {item?.priceDataAffiliateData?.appliedDiscountTitle}
                              </div>
                            )}

                          {item?.priceDataAffiliateData
                            ?.appliedDiscountTitle && (
                              <div
                                id='exclGSTPrint'
                                className={[
                                  "exclGst",
                                  Classes.responsiveExclGst,
                                ].join(" ")}
                              >
                                <p>(Incl. GST)</p>
                              </div>
                            )}
                        </div>

                        <div
                          className={[
                            "createOrderQty",
                            Classes.createOrderQuantity,
                          ].join(" ")}
                          style={{ width: "14%" }}
                        >
                          <p>{item?.Qty}</p>
                          <p className={Classes.QtyPCSHighlight}>
                            {item?.basicCollectionDataBrandData?.unit ? item?.basicCollectionDataBrandData?.unit : 'PCS'}
                          </p>
                        </div>

                        <div
                          className={[
                            Classes.hideTotalResponsive,
                            Classes.variantProductTotal,
                            "total",
                          ].join(" ")}
                          id='discTotalPrint'
                        >
                          &#8377;{roundOfUptoXDecimal(item?.productTotal)}
                        </div>

                        <div
                          className={[
                            Classes.hideTotalResponsive,
                            Classes.variantProductTotal,
                            "total",
                          ].join(" ")}
                          id='discTotalPrint'
                        >
                          &#8377;
                          {roundOfUptoXDecimal(
                            item?.productTotal *
                            ( 1 + Number(item?.priceDataAffiliateData?.gst)/100)
                          )}
                        </div>

                        <div className='removeIcon'>
                          <FontAwesomeIcon
                            icon={faXmark}
                            className={Classes.removeProductInContainer}
                            onClick={() => updateArr(item?.product_id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>

            <div
              className={[Classes.Box, "box"].join(" ")}
              id='createOrderPaymentBox'
            >
              <div className={Classes.containerBox}>
                <div className='d-flex justify-content-between mb-3'>
                  <h6 style={{ margin: "auto 0px" }}>Payment</h6>
                </div>

                <div className={Classes.fontSize}>
                  <div
                    className={[
                      "removeSomeMarginPrint",
                      Classes.commonDisplayContent,
                    ].join(" ")}
                    style={{ marginBottom: "1rem" }}
                  >
                    <p>Subtotal</p>
                    {showProduct !== null ? (
                      <p>&#8377;{roundOfUptoXDecimal(showProduct?.SubTotal)}</p>
                    ) : (
                      <p>&#8377;0.00</p>
                    )}
                  </div>

                  <div className={Classes.commonTextWidth}>
                    <div
                      className={[
                        Classes.textMutedContent,
                        "removeSomeMarginPrint",
                      ].join(" ")}
                      id='noDiscountAvailPrint'
                    >
                      {showProduct !== null ? (
                        <div style={{ width: "30%" }}>
                          {showProduct?.overAllDiscounts ? (
                            <div>
                              <p
                                className={[
                                  "removeEditDiscountPrint",
                                  Classes.editDiscount,
                                ].join(" ")}
                                style={{ width: "100%" }}
                                onClick={showDiscountPopup}
                              >
                                Edit Discount
                              </p>
                              <p
                                className={[
                                  "showEditDiscountPrint",
                                  Classes.discount,
                                ].join(" ")}
                              >
                                Discount
                              </p>
                            </div>
                          ) : (
                            <p
                              className={[
                                "discountRemPrint",
                                Classes.editDiscount,
                              ].join(" ")}
                              style={{ width: "100%" }}
                              onClick={showDiscountPopup}
                            >
                              Add Discount
                            </p>
                          )}
                        </div>
                      ) : (
                        <p>Add Discount</p>
                      )}

                      {showProduct?.overAllDiscounts ? (
                        <p className='removerReason'>
                          {showProduct?.overAllDiscounts?.title}
                        </p>
                      ) : (
                        <p className='discountRemPrint'>--</p>
                      )}

                      {showProduct?.overAllDiscounts ? (
                        <>
                          <div style={{display: "flex", gap: "16px"}}>  
                            <p className={Classes.overAllDiscount}>
                              {roundOfUptoXDecimal(showProduct?.overAllDiscounts?.overallDiscountPercentage)}%
                            </p>
                            <p className={Classes.overAllDiscount} style={{width: "100%"}}>
                              -&#8377;{roundOfUptoXDecimal(showProduct?.overAllDiscounts?.overallDiscountAmount)}
                            </p>
                          </div>
                          <div className='removeIcon'>
                            <button
                              style={{
                                border: 0,
                                borderRadius: 5,
                                boxShadow: "0px 0px 2px",
                                marginLeft: 5,
                              }}
                              onClick={removeOverAllDiscount}
                            >
                              Remove
                            </button>
                          </div>
                        </>
                      ) : (
                        <p
                          className='discountRemPrint'
                          style={{ textAlign: "end" }}
                        >
                          &#8377;0.00
                        </p>
                      )}
                    </div>
                    <Permission service='applyCoupon' permission={"create"}>
                      <AddCoupon 
                        overAllSubTotal={showProduct?.SubTotal || 0}
                        selectedProducts={productDetails}
                        customer={customerDetails}
                        afterCouponCodeApplyHook={afterCouponCodeApplyHook}
                        couponCode={couponCode}
                        setCouponCode={setCouponCode}
                        removeOverAllDiscount={removeOverAllDiscount}
                      />
                    </Permission>
                    <div
                      className={[
                        Classes.shippingBox,
                        "removeSomeMarginPrint",
                      ].join(" ")}
                      id='removeShippingPrint'
                    >
                      {showProduct !== null ? (
                        <div>
                          {showProduct?.shippingLine ? (
                            <div className={Classes.shippingContent}>
                              <p
                                className={Classes.editShipping}
                                onClick={shippingPopup}
                              >
                                Edit Shipping
                              </p>

                              <div
                                style={{
                                  display: "flex",
                                  width: "30%",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <p
                                  style={{
                                    width: "fit-content",
                                    color: "black",
                                  }}
                                >
                                  +&#8377;{showProduct?.shippingLine?.price}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className={Classes.shippingContent}>
                              <p
                                className={Classes.editDiscount}
                                onClick={shippingPopup}
                              >
                                Add Shipping
                              </p>
                              <p>--</p>
                              <p style={{ textAlign: "end" }}>&#8377;0.00</p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className={Classes.shippingContent}>
                          <p>Add Shipping</p>
                          <p>--</p>
                          <p style={{ textAlign: "end" }}>&#8377;0.00</p>
                        </div>
                      )}
                    </div>

                    <div
                      className={[
                        Classes.textMutedContent,
                        "removeSomeMarginPrint",
                      ].join(" ")}
                    >
                      <p>Tax</p>
                      {showProduct !== null ? (
                        <p
                          className={[
                            "removeShowTaxPrint",
                            Classes.taxRates,
                          ].join(" ")}
                          onClick={handleTaxRates}
                        >
                          Show tax rates
                        </p>
                      ) : (
                        <p>Not calculated</p>
                      )}

                      {showProduct !== null ? (
                        <p className={Classes.taxAmount}>
                          &#8377;{totalTaxAmount?.toFixed(2)}
                        </p>
                      ) : (
                        <p style={{ textAlign: "end" }}>&#8377;0.00</p>
                      )}
                    </div>
                  </div>
                  <div className={Classes.Total}>
                    <p>Total</p>
                    <div>
                      {showProduct !== null ? (
                        <>
                          {showProduct?.totalPrice ? (
                            <p>&#8377;{roundOfUptoXDecimal(showProduct?.totalPrice)}</p>
                          ) : (
                            <p>&#8377;{roundOfUptoXDecimal(showProduct?.Total)}</p>
                          )}
                        </>
                      ) : (
                        <p>&#8377;0.00</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={Classes.containerFooter}
                id='removeCreateOrderPrint' style={{flexWrap:'wrap'}}
              >
                {showProduct !== null ? (
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", flexWrap:'wrap', gap:2 }}>
                      {!updateExistOrder && (
                        <div>
                          <button
                            id='draftOrderBtn'
                            className={[
                              loadingSalesOrderCalculationService 
                              ? Classes.disabledDraftOrderButton 
                              : Classes.draftOrderButton,
                              Classes.Btn
                            ].join(" ")}
                            style={{
                              ...(loadingSalesOrderCalculationService
                                ? {
                                  backgroundColor: "#6c757d"
                                } : {
                                  backgroundColor: "#6c943eeb"
                                })
                            }}
                            onClick={(e) => handleCreateDraftOrder(e)}
                            disabled={loadingSalesOrderCalculationService || false}
                          >
                            {
                              loadingSalesOrderCalculationService
                                ? "Loading ..."
                                : "Save as Draft Order"
                            }
                          </button>
                        </div>
                      )}

                      {customerDetails &&
                        (selectedShippingAddress !== null) && (
                          <>
                            {!updateExistOrder ? (
                              <div className={Classes.buttonBox}>
                                <div>
                                  <button
                                    id='submitBtn'
                                    className={[Classes.draftOrderButton,Classes.Btn].join(" ")}
                                    style={{ backgroundColor: "#6c943eeb" }}
                                    onClick={(e) => handleSendPI(e)}
                                  >
                                    Send PI
                                  </button>
                                </div>
                                <div>
                                  <button
                                    id='createOrderBtn'
                                    className={
                                      [
                                        loadingSalesOrderCalculationService ? Classes.disabledDraftOrderButton : Classes.draftOrderButton,
                                        Classes.Btn
                                      ].join(" ")
                                    }
                                    style={{
                                      ...(loadingSalesOrderCalculationService
                                        ? {
                                          backgroundColor: "#6c757d"
                                        } : {
                                          backgroundColor: "#008060"
                                        })
                                    }}
                                    onClick={handleShowPopup}
                                    disabled={loadingSalesOrderCalculationService || false}
                                  >
                                    {
                                      loadingSalesOrderCalculationService
                                        ? "Loading ..."
                                        : "Create Order"
                                    }
                                  </button>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}

                      {updateExistOrder && (
                        <div className={Classes.buttonBox} style={{flexWrap:'wrap'}}>
                          <div style={{ margin: "0px 20px" }}>
                            <button
                              className={[Classes.Btn,Classes.createOrderSendPIButton].join(" ")}
                              id='submitBtn'
                              onClick={(e) => handleSendPI(e)}
                            >
                              Send PI
                            </button>
                          </div>

                          <div>
                            <button
                              onClick={(e) => handleUpdateOrder(e)}
                              id='createOrderBtn'
                              className={
                                loadingSalesOrderCalculationService
                                ? Classes.disabledDraftOrderButton
                                : Classes.updateOrderButton
                              }
                              disabled={loadingSalesOrderCalculationService || false}
                            >
                              {
                                loadingSalesOrderCalculationService
                                  ? "Loading ..."
                                  : "Update Order"
                              }
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div style={{ display: "flex", gap: "1rem", marginTop: '1rem', flexWrap:'wrap' }}>
                      <SelectCompany open={open} setOpen={setOpen} func={sendPaymentLink} />
                      <select 
                        name="paymentGateway" 
                        className={Classes.select} 
                        value={paymentGateway || ""}
                        onChange={(e) => { setPaymentGateway(e.target.value) }}
                        style={{ maxWidth: 180 }}
                      >
                        <option value="">Select Payment Gateway</option>
                        {paymentGateways?.map(p => {
                          return <option key={p.id + p.payment_gateway} value={p.id}>{p.payment_gateway}</option>
                        })}
                      </select>

                      <input 
                        placeholder='Amount' 
                        type='number' 
                        min={1} 
                        value={amount || 0} 
                        onChange={(e) => {
                          const v = e.target.value;
                          if (!(v && (isNaN(Number(v)) || +v < 0))) {
                            setAmount(isNaN(Number(v)) ? 0 : +v)
                          } 
                        }} 
                      />
                    <div>
                    <button
                      className={[
                        loadingSalesOrderCalculationService 
                        ? Classes.disabledDraftOrderButton 
                        : Classes.draftOrderButton,
                        Classes.Btn
                      ].join(" ")}
                      style={{
                        ...(loadingSalesOrderCalculationService || !paymentGateway
                          ? {
                            backgroundColor: "#6c757d"
                          } : {
                            backgroundColor: "#6c943eeb"
                          })
                      }}
                      onClick={(e) =>{
                        setEvent(e);
                        setOpen(true)
                        //  handleCreateDraftOrder(e, paymentGateway, amount)
                      }}
                      disabled={!paymentGateway || !amount || loadingSalesOrderCalculationService || false}
                    >
                      {
                        loadingSalesOrderCalculationService
                          ? "Loading ..."
                          : "Send Payment Link"
                      }
                    </button>
                  </div>
                  </div>
                  </div>
                ) : (
                  <p>Add a product to calculate total view payment options</p>
                )}
              </div>
            </div>

            {showProduct !== null && (
              <div id='TaxRatesPrint' style={{ display: "none" }}>
                <TaxRatesPrint
                  showProduct={showProduct}
                  showCustomerDetail={customerDetails}
                  addressArray={addressArray}
                  selectedShippingAddress={selectedShippingAddress}
                  taxRates={taxLines}
                />
              </div>
            )}
            <div>
              <ConfirmPopup
                showConfirmPopup={showConfirmPopup}
                hideConfirmPopup={hideConfirmPopup}
              />
            </div>
          </div>

          <div
            className={Classes.asideContainer}
            id='createOrderAsideContainer'
          >
            <Aside
              estimateUserId={estimateUserId}
              showCustomerDetail={customerDetails}
              setCustomerDetails={setCustomerDetails}
              selectedShippingAddress={selectedShippingAddress}
              setSelectedShippingAddress={setSelectedShippingAddress}
              selectedBillingAddress={selectedBillingAddress}
              setSelectedBillingAddress={setSelectedBillingAddress}
              gstNumber={gstNumber}
              setGstNumber={setGstNumber}
              showSelectedTags={showSelectedTags}
              setSelectedTags={setSelectedTags}
              fetchCustomerRoleDB={fetchCustomerRoleDB}
              addressArray={addressArray}
              setAddressArray={setAddressArray}
              updateExistOrder={updateExistOrder}
              setDeliveryRemark={setDeliveryRemark}
              paymentRemark={paymentRemark}
              setPaymentRemark={setPaymentRemark}
              setSellerBillingAddress={setSellerBillingAddress}
              setSeller={setSeller}
              seller={seller}
              sellerBillingAddress={sellerBillingAddress}
              setAffiliateUser={setAffiliateUser}
              affiliateUser={affiliateUser}
              setSalesUser={setSalesUser}
              salesUser={salesUser}
              setPreSalesUser={setPreSalesUser}
              preSalesUser={preSalesUser}
              salesList={salesList}
              handleSalesList={handleSalesList}
              selectedRoleForDiscount={selectedRoleForDiscount}
              setSelectedRoleForDiscount={setSelectedRoleForDiscount}
            />
          </div>
        </div>
      </div>

      <div className={shippingModal ? Classes.UpdateSalesDialog : undefined}>
        <ShippingPopUp
          shippingModal={shippingModal}
          setShippingModal={setShippingModal}
          hideShippingPopup={hideShippingPopup}
          productDetails={productDetails}
          setProduct={setProduct}
          setTaxLines={setTaxLines}
          setTotalTaxAmount={setTotalTaxAmount}
          shippingPrice={shippingPrice}
          previousTarget={previousTarget}
          shippingAmount={shippingAmount}
          setShippingAmount={setShippingAmount}
          setPreviousTarget={setPreviousTarget}
          shippingPriceType={shippingPriceType}
          setShippingPriceType={setShippingPriceType}
          setShippingPrice={setShippingPrice}
          overAllDiscount={overAllDiscount}
          setLoadingSalesOrderCalculationServiceState={setLoadingSalesOrderCalculationServiceState}
        />
      </div>

      <div className={discountModal ? Classes.UpdateSalesDialog : undefined}>
        <DiscountPopup
          discountModal={discountModal}
          setDiscountModal={setDiscountModal}
          setTaxLines={setTaxLines}
          setTotalTaxAmount={setTotalTaxAmount}
          showDiscountPopup={showDiscountPopup}
          hideDiscountPopup={hideDiscountPopup}
          productDetails={productDetails}
          setProduct={setProduct}
          overAllDiscount={overAllDiscount}
          shippingPrice={shippingPrice}
          showDiscountType={showDiscountType}
          setDiscountType={setDiscountType}
          setShippingPrice={setShippingPrice}
          setOverAllDiscount={setOverAllDiscount}
          setLoadingSalesOrderCalculationServiceState={setLoadingSalesOrderCalculationServiceState}
          overallSubtotal={showProduct?.SubTotal}
        />
      </div>

      <div className={showTaxRate ? Classes.UpdateSalesDialog : undefined}>
        <TaxRatesPopup
          showTaxRate={showTaxRate}
          totalTaxAmount={totalTaxAmount}
          setTaxRates={setTaxRates}
          handleTaxRates={handleTaxRates}
          hideTaxPopup={hideTaxPopup}
          showProduct={showProduct}
          showCustomerDetail={customerDetails}
          addressArray={addressArray}
          taxRates={taxLines}
          selectedShippingAddress={selectedShippingAddress}
        />
      </div>

      <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
        <Modal
          show={showModal}
          onHide={hideModal}
          AddHide={addHideModal}
          styles={Classes}
          search={searchProduct}
          setSearch={setSearchProduct}
          productArr={productId}
          setProductArr={setProductId}
          variant={variant}
          setVariant={setVariant}
          toggle={toggle}
          setToggle={setToggle}
          productNew={productNew}
          setProductNew={setProductNew}
          productIdArr={productIdArr}
          setProductIdArr={setProductIdArr}
          district={selectedDistrict?.value}
        />
      </div>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingForDraftOrder}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
      <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
    </Fragment>
  );
};

export default CreateOrder;