import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { setReturns } from '../../../store/returnsSlice';
import Chip from '@mui/material/Chip';
import Table from "../../../components/table/table";
import { Ban } from 'lucide-react';
import { Tooltip } from '@mui/material';

const StatusRenderCell = (row) => {
    return <Chip style={{ backgroundColor: '#ffea88' }} label={row?.status} />
}

let columns = [
    {
        field: 'returnNumber',
        headerName: 'Return No.',
        flex: 1.4,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'orderName',
        headerName: 'Order No.',
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
            params?.row?.customerName ? <div>
                <Tooltip title={params?.row?.blacklisted ? "Blacklisted customer": ''} placement="top" style={{display: 'flex', alignItems: 'center'}}>
                    {params?.row?.blacklisted ? <Ban style={{color: 'red', marginRight: '8px'}} size='12px'/>: ''}
                    <p style={{color: params?.row?.createdVia === 'POS'? "#ff6200" : '#000'}}>
                        {params?.row?.customerName}
                    </p>
                </Tooltip>
            </div>
            : null
        )
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 0.7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'returnStatus',
        headerName: 'Status',
        flex: 1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => ( <StatusRenderCell {...params?.row} />),
        customRenderCell: StatusRenderCell
    },
    {
        field: 'totalAmount',
        headerName: 'Total Amount',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'reason',
        headerName: 'Reason',
        flex: 0.7,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'locationCode',
        headerName: 'Location Code',
        flex: 1,
        headerClassName: 'super-app-theme--header'
    },
    {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 1.7,
        headerClassName: 'super-app-theme--header'
    }
];

const ReturnsNew = (props) => {
    const returns = useSelector((state) => state.returns);

    return (
        <Fragment>
            <Table
				columns={columns}
				dataPosition={"fetchedReturns"}
				endpoint={`/pgOrder/admin/returnsNew`}
				rowId={'returnNumber'}
				checkBoxSelection={false}
				version="version-1"
				service={'returns'}
				tabName={'Returns'}
				navigateOnRowClickEndpoint={`/returnsNew`}
				setData={setReturns}
				data={returns}
				mobileResponsive={true}
				exportEndpoint={'/exports/fetchReturnOrders'}
				exportFileName={`Return Orders - ${new Date()}`}
                clickable={true}
			/>
        </Fragment>
    );
};

export default ReturnsNew;