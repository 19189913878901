import { Fragment, useState, forwardRef } from 'react';
import { Autocomplete, MenuItem } from '@mui/material';
import Alert from 'react-bootstrap/Alert';
import { TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import axiosInstance from '../../../utility/axios-instance';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateInventoryView = (props) => {
    const [skus,setSkus] = useState([]);
    const [isSubmitClicked, setSubmitClicked] = useState(false);
    const auth = useSelector((state) => state?.auth);

    const { binType, fromBin, availableQty, setSku, setType, setSelectedFromBin, selectedFromBin, handleSubmit,
        qtyError, setQtyError, toBins, setToType, setSelectedToBin, suggestedBins,
        selectedToBin, type, toType, sku, open, handleClose, locationCodes, selectedLocationCode, setSelectedLocationCode } = props;


    const handleMoveQty = (e) => {
        let moveQty = e.target.value;
        if (moveQty < 0) {
            e.target.value = '';
            alert('Enter valid Quantity')
        }
        if (moveQty > availableQty) {
            setQtyError(true)
        }
        else {
            setQtyError(false)
        }
    }

    const onCLose = () => {
        // reset form
        document.getElementById('form').reset();
        handleClose();
    }

    const fetchData = (text) => {
        const filters = JSON.stringify([{"field":"isVirtualProduct","value":"false"}])
        axiosInstance
            .get(`/product/variant/sku?text=${text? text: ''}&filters=${filters}`)
            .then((res) => {
                setSkus(res.data.data)
            }).catch((err) => {
                console.log(err,'error')
            })
    }

    useEffect(() => {
        fetchData();
    },[])
    
    return (
        <Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
            >
                <DialogTitle>{"Move Inventory"}</DialogTitle>
                <DialogContent>
                    <div style={{ width: '' }} >
                        {qtyError ? (
                            <Alert variant="danger">
                                <Alert.Heading>Error!</Alert.Heading>
                                <p>{`Enter less Quantity to move than ${availableQty}`}</p>
                            </Alert>
                        ) : null}

                        <form onSubmit={(e) => {
                            handleSubmit(e)
                            if (!isSubmitClicked) {
                                setSubmitClicked(true);
                                setTimeout(() => {
                                    setSubmitClicked(false);
                                }, 1000);
                            }
                        }
                            } id="form">
                            {/* Main Form */}
                            <div >
                                <div style={{ margin: '20px 10px' }}>
                                    <Autocomplete
                                        disablePortal
                                        required
                                        id="locationCode"
                                        options={auth?.user?.locations || []}
                                        sx={{ width: '30%' }}
                                        onChange={(event, newValue) => {
                                            setSelectedLocationCode(newValue);
                                        }}
                                        value={selectedLocationCode}
                                        isOptionEqualToValue={(option, value) => {
                                            return option === value
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Location Code" defaultValue="" />}
                                    />
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 10px' }}>
                                    <Autocomplete
                                        disablePortal
                                        freeSolo
                                        id="sku"
                                        name="sku"
                                        options={skus}
                                        sx={{ width: '30%' }}
                                        onBlur={(event) => {setSku(event.target.value)}}
                                        value={sku}
                                        renderInput={(params) => <TextField {...params} label="SKU" required
                                            onChange={(event, newValue) => {
                                                fetchData(event?.target?.value)
                                            }}
                                        />}
                                    />

                                    <TextField
                                        id="fromType"
                                        name='type'
                                        select
                                        onChange={(e) => setType(e.target.value)}
                                        value={type}
                                        label="From Type"
                                        helperText="Select Type of Bin"
                                        sx={{ width: '30%' }}
                                    >
                                        {Object.entries(binType).map(([k, v]) => (
                                            <MenuItem key={k} value={v}>{v}</MenuItem>
                                        ))}
                                    </TextField>

                                    <Autocomplete
                                        disablePortal
                                        id="fromBin"
                                        name='fromBin'
                                        disabled={type === '' || sku === '' ? true : false}
                                        onChange={(e, newVal) => setSelectedFromBin(newVal)}
                                        value={selectedFromBin === '' ? null : selectedFromBin}
                                        getOptionLabel={(option) => option.code}
                                        label="From Bin"
                                        helperText="Select Bin"
                                        sx={{ width: '30%' }}
                                        options={fromBin.filter((bin) => bin.availableQty > 0)}
                                        renderInput={(params) => <TextField {...params} label="From Bin" defaultValue="" />}
                                    />

                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 10px' }}>
                                    <TextField
                                        id="toType"
                                        name='toType'
                                        select
                                        onChange={(e) => setToType(e.target.value)}
                                        value={toType}
                                        label="To Type"
                                        helperText="Select Type of Bin"
                                        sx={{ width: '30%' }}
                                    >
                                        {Object.entries(binType).map(([k, v]) => (
                                            <MenuItem key={k} value={v}>{v}</MenuItem>
                                        ))}
                                    </TextField>
                                    <Autocomplete
                                        disablePortal
                                        id="toBin"
                                        options={toBins}
                                        disabled={toType === '' ? true : false}
                                        sx={{ width: '30%' }}
                                        onChange={(event, newValue) => {
                                            setSelectedToBin(newValue.label);
                                        }}
                                        value={selectedToBin}
                                        renderInput={(params) => <TextField {...params} label="To Bin" defaultValue="" />}
                                    />
                                    <TextField
                                        required
                                        id='moveQty'
                                        type='number'
                                        disabled={selectedFromBin === '' ? true : false}
                                        label="Move Quantity"
                                        placeholder='Enter Quantity'
                                        onChange={(e) => handleMoveQty(e)}
                                        sx={{ width: '30%' }}
                                    />
                                </div>
                            </div>

                            <DialogActions>
                                <Button type="submit" id='moveInventory' variant="contained" color="primary" disabled={isSubmitClicked} >Move</Button>
                                <Button onClick={onCLose}>Close</Button>
                            </DialogActions>
                        </form>
                    </div>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
}

export default CreateInventoryView;