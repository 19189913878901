import { Fragment } from "react";
import Classes from "../estimate/css/estimates.module.css";
import { useSelector } from "react-redux";
import { setAffiliateCommissions } from "../../../store/affiliateCommissions";
import Table from "../../../components/table/table";

const Commissions2 = (props) => {
  const affiliateCommissions = useSelector((state) => state.affiliateCommissions);
  const columns = [
    {
      field: "orderName",
      headerName: "OrderName",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.affiliateData?.displayName}</div>
      )
    },
    {
      field: "phone",
      headerName: "PHONE",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.affiliateData?.phone}</div>
      )
    },
    {
      field: "email",
      headerName: "EMAIL",
      flex: 1.5,
      headerClassName: "super-app-theme--header",
      sortable: true,
      renderCell: (params) => (
        <div>{params?.row?.affiliateData?.email}</div>
      )
    },
    {
      field: "commission",
      headerName: "COMMISSION",
      flex: 0.8,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "CREATED AT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      sortable: true,
    },
  ];
  // DEPRECATED (use AffiliateDashboard directory where you will see commissions)
  return (
    <Fragment>
        <Table
          columns={columns}
          dataPosition={"commissions"}
          endpoint={"/others/commissions"}// This route is deprecated
          rowId={'_id'}
          checkBoxSelection={false}
          version="version-1"
          navigateOnRowClickEndpoint={`/estimates`}
          service={'commissions'}
          tabName={'Commissions'}
          setData={setAffiliateCommissions}
          data={affiliateCommissions}
          mobileResponsive={true}
          exportEndpoint={'/exports/commissions'}
          exportFileName={`Commissions - ${new Date()}`}
          searchKey={'text'}
          clickable={true}
        />
    </Fragment>
  );
};

export default Commissions2;
